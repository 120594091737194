import { Component, OnInit, ViewChild } from "@angular/core";

import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { environment } from "src/environments/environment";
import { RicercaStruttureOrganizzativeComponent } from "src/app/component/ricerca-strutture-organizzative/ricerca-strutture-organizzative.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-strutture-organizzative",
  templateUrl: "./strutture-organizzative.component.html",
  styleUrls: ["./strutture-organizzative.component.css"],
})
export class StruttureOrganizzativeComponent implements OnInit {
  ricercaOrgList: any[] | undefined = undefined;

  @ViewChild(RicercaStruttureOrganizzativeComponent)
  rsoc!: RicercaStruttureOrganizzativeComponent;

  constructor(
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  searchOrg(criteri: any) {
    this.strutturaOrganizzativaService
      .search(criteri)
      .then((result) => {
        this.ricercaOrgList = result?.items;
        this.rsoc.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaOrgList = [];
        }
      });
  }

  exportOrg(criteri: any) {
    this.strutturaOrganizzativaService.export(criteri);
  }  
}
