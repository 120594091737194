<ng-container>
  <it-icon class="link-pointer" name="search" (click)="showModal()" itTooltip="Cerca Struttura Organizzativa" tooltipPlacement="bottom"></it-icon>
</ng-container>

<it-modal #searchModal="itModal" scrollable="true" closeButton="true">
  <ng-container modalTitle>{{ title }}</ng-container>

  <form [formGroup]="form">
    <!-- <p>{{ description }}</p> -->
    <it-input label="Filtro" formControlName="filtro"></it-input>

    <it-table hover="true" compact="true" class="search-modal-table">
      <ng-container tbody>
        <tr
          *ngFor="let item of itemsList"
          (click)="selezionaRiga(item)"
          class="link-pointer mb-1"
          [ngClass]="{ 'row-selected': item.value == form.value.selection }"
        >
          <ng-container *ngIf="item.text != ''">
            <td class="py-3">{{ item.text }}</td>
          </ng-container>
        </tr>
      </ng-container>
    </it-table>
  </form>

  <ng-container footer>
    <button
      itButton="secondary"
      size="sm"
      type="button"
      data-bs-dismiss="modal"
    >
      Annulla
    </button>
    <button
      itButton="primary"
      size="sm"
      type="button"
      data-bs-dismiss="modal"
      [disabled]="form.value.selection == ''"
      (click)="confirm()"
    >
      Seleziona
    </button>
  </ng-container>
</it-modal>
