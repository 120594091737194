<it-modal #confirmModal="itModal" closeButton="true">
  <ng-container modalTitle>{{ title }}</ng-container>

  <p>{{ description }}</p>

  <ng-container footer>
    <button
      itButton="secondary"
      size="sm"
      type="button"
      data-bs-dismiss="modal"
    >
      {{ cancelButtonText }}
    </button>
    <button
      itButton="primary"
      size="sm"
      type="button"
      data-bs-dismiss="modal"
      (click)="confirm()"
    >
      {{ confirmButtonText }}
    </button>
  </ng-container>
</it-modal>
