<app-container>
  <app-dati-asset
    [(form)]="form"
    [pageStatus]="'EDIT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Asset'"
    (onSubmit)="onSave()"
    (onDeleteAttributo)="onDeleteAttributo($event)"
  >
  </app-dati-asset>
  
  <app-modal-confirm
    #confirmModal
    title="Modifica Asset"
    description="Vuoi confermare l'aggiornamento dell'asset?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>

  <app-modal-confirm
    #confirmModalDeleteAttributo
    title="Eliminazione Attributo"
    description="Vuoi confermare l'eliminazione dell'attributo selezionato?"
    (onConfirm)="deleteAttributo()"
  >
  </app-modal-confirm>    
</app-container>
