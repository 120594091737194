import { Component, OnInit, ViewChild } from "@angular/core";

import { RicercaAttributiAssetComponent } from "src/app/component/ricerca-attributi-asset/ricerca-attributi-asset.component";
import { RicercaAttributiAssetService } from "src/app/service/ricerca-attributi-asset-service";
import { UserBaseComponent } from "../user-base-component";
import { AssetService } from "src/app/service/asset-service";
import { UserService } from "src/app/service/user-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-ricerca-attributi-asset-page",
  templateUrl: "./ricerca-attributi-asset-page.component.html",
  styleUrls: ["./ricerca-attributi-asset-page.component.css"],
})
export class RicercaAttributiAssetPageComponent
  extends UserBaseComponent
  implements OnInit
{
  ricercaAttributiList: any[] | undefined = undefined;

  @ViewChild(RicercaAttributiAssetComponent)
  rac!: RicercaAttributiAssetComponent;

  constructor(
    private ricercaAttributiAssetService: RicercaAttributiAssetService,
    assetService: AssetService,
    userService: UserService,
    router: Router
  ) {
    super(assetService, userService, router);
  }

  ngOnInit(): void {}

  searchAttributi(criteri: any) {
    this.ricercaAttributiAssetService
      .search(criteri)
      .then((result) => {
        this.ricercaAttributiList = result?.items.map((item) => ({
          ...item,
        }));
        this.rac.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaAttributiList = [];
        }
      });
  }
}
