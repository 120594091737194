import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { SelectControlOption } from "design-angular-kit/public_api";
import { QueryService } from "src/app/service/query-service";
import { TipoAttributoService } from "src/app/service/tipo-attributo-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dati-attributo",
  templateUrl: "./dati-attributo.component.html",
  styleUrls: ["./dati-attributo.component.css"],
})
export class DatiAttributoComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  showValidazioneValore: boolean = false;
  showValoriPredefiniti: boolean = false;

  tipoAttributoSelectOptions: SelectControlOption[] = [];
  querySelectOptions: SelectControlOption[];

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  valoreLiberoSub: Subscription;
  valoreDaListaSub: Subscription;
  valoreDaQuerySub: Subscription;

  constructor(
    private queryService: QueryService,
    private tipoAttributoService: TipoAttributoService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  get listaValori() {
    return this.form.controls["listaValori"] as FormArray;
  }

  aggiungiValore() {
    this.listaValori.push(
      this.fb.group({
        valore: [""],
      })
    );
  }

  rimuoviValore(valoreIndex: number) {
    this.listaValori.removeAt(valoreIndex);
  }

  ngOnInit(): void {
    if (this.pageStatus === "NEW") {
    }

    this.tipoAttributoService.getAll().then((result) => {
      if (result) {
        result?.forEach((element) => {
          this.tipoAttributoSelectOptions.push({
            value: element.id,
            text: element.descrizione,
          });
        });
      }
    });

    this.queryService
      .getQueries()
      .then((result) => {
        if (result?.queries) {
          this.querySelectOptions = [];
          result?.queries.forEach((query) => {
            this.querySelectOptions.push({
              value: query,
            });
          });
        }
      })
      .catch((error) => {
        this.querySelectOptions = null;
      });

    let valoreLiberoControl = this.form.get("valoreLibero");
    let valoreDaListaControl = this.form.get("valoreDaLista");
    let valoreDaQueryControl = this.form.get("valoreDaQuery");
    this.valoreLiberoSub = valoreLiberoControl.valueChanges.subscribe(() => {
      this.showValoriPredefiniti = valoreLiberoControl.value;
      if (valoreLiberoControl.value) {
        valoreDaListaControl.setValue(false);
        valoreDaQueryControl.setValue(false);
      }
    });
    this.valoreDaListaSub = valoreDaListaControl.valueChanges.subscribe(() => {
      this.showValoriPredefiniti = valoreDaListaControl.value;
      if (valoreDaListaControl.value) {
        valoreLiberoControl.setValue(false);
        valoreDaQueryControl.setValue(false);
      }
    });
    this.valoreDaQuerySub = valoreDaQueryControl.valueChanges.subscribe(() => {
      if (valoreDaQueryControl.value) {
        valoreLiberoControl.setValue(false);
        valoreDaListaControl.setValue(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.valoreLiberoSub.unsubscribe();
    this.valoreDaListaSub.unsubscribe();
    this.valoreDaQuerySub.unsubscribe();
  }

  tornaDatiAsset(): void {
    this.router.navigate(["/modificaAsset", this.form.value.idAsset]);
  }

  save() {
    this.onSubmit.emit();
  }
}
