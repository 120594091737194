<a class="link-pointer" *ngIf="showChangeAsset" routerLink="/selezionaAsset">
  <span class="nav-text">
    <strong>Asset:</strong>
    {{ descrizioneAsset }}
    <ng-container *ngIf="showOrg">
      <nobr>({{ descrizioneOrg }})</nobr>
    </ng-container>
  </span>
  <it-icon name="exchange-circle" color="primary" size="sm" itTooltip="Seleziona Asset/Struttura Organizzativa" tooltipPlacement="bottom"></it-icon>
</a>
