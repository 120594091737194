import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { RuoliService } from "../../service/ruoli-service";
import {
  PROFILO_AMMINISTRATORE_REGIONALE,
  PROFILO_AMMINISTRATORE_ASSET,
  PROFILO_AMMINISTRATORE_STRUTTURA,
} from "src/app/tipologiche/profili";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subscription } from "rxjs";
import { NomeValidator } from "../../directive/nome.validator";
import { CodiceFiscaleValidator } from "../../directive/codice-fiscale.validator";

@Component({
  selector: "app-inserimento-nuovo-utente",
  templateUrl: "./inserimento-nuovo-utente.component.html",
  styleUrls: ["./inserimento-nuovo-utente.component.css"],
})
export class InserimentoNuovoUtenteComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  userSub: Subscription;
  isAdminOrg: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.formCommons = fc;
  }

  get listaAsset() {
    return this.form.controls["listaAsset"] as FormArray;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      codiceFiscale: ["", [Validators.required, CodiceFiscaleValidator()]],
      cognome: ["", [Validators.required, NomeValidator()]],
      nome: ["", [Validators.required, NomeValidator()]],
      isAmministratoreRegionale: false,
      isAmministratoreStruttura: false,
      isAmministratoreAsset: false,
      listaAsset: new FormArray([]),
    });
    this.listaAsset.push(
      this.fb.group({
        asset: ["", Validators.required],
        org: null,
        listaOrg: [],
      })
    );
    this.userSub = this.userService.user.subscribe((usr) => {
      if (usr?.isAdminOrg) {
        this.form.patchValue({
          isAmministratoreRegionale: false,
          isAmministratoreStruttura: false,
          isAmministratoreAsset: true,
        });
      }
    });
  }
  ngOnDestroy() {
    this.userSub && this.userSub.unsubscribe();
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.value.isAmministratoreRegionale) {
      this.listaAsset.clear();
    }
    if (this.form.invalid) {
      return;
    }
    if (
      (this.form.value.isAmministratoreAsset ||
        this.form.value.isAmministratoreStruttura) &&
      (this.form.value.listaAsset?.length ?? 0) == 0
    ) {
      this.alertService.addErrorMessage("Inserire almeno un asset");
      return;
    }

    this.confirmModal.show();
  }

  save() {
    const body = {
      utenteDto: {
        codiceFiscale: this.form.value.codiceFiscale,
        cognome: this.form.value.cognome,
        nome: this.form.value.nome,
      },
      profilo: this.form.value.isAmministratoreRegionale
        ? PROFILO_AMMINISTRATORE_REGIONALE
        : this.form.value.isAmministratoreStruttura
        ? PROFILO_AMMINISTRATORE_STRUTTURA
        : this.form.value.isAmministratoreAsset
        ? PROFILO_AMMINISTRATORE_ASSET
        : "",
      asset: this.form.value.isAmministratoreRegionale
        ? null
        : Array.from(
            this.form.value.listaAsset.reduce((assetMap, value) => {
              if (assetMap.has(value.asset)) {
                if (!assetMap.get(value.asset).split(",").includes(value.org))
                  assetMap.set(
                    value.asset,
                    `${assetMap.get(value.asset)},${value.org}`
                  );
              } else {
                assetMap.set(value.asset, value.org ?? "");
              }
              return assetMap;
            }, new Map())
          ).map((a) => `${a[0]};${a[1] ?? ""}`),
    };
    this.userService.save(body).then(() => {
      // this.form.reset();
      this.alertService.addDefaultSuccessMessage();
    });
  }
}
