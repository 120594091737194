<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
  <div
    class="accordion accordion-background-active"
    id="accordionRicercaUtenti"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Criteri di ricerca
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group col-md-4">
              <it-input label="Codice Fiscale" formControlName="codiceFiscale">
              </it-input>
            </div>
            <div class="form-group col-md-4">
              <it-input label="Cognome" formControlName="cognome"></it-input>
            </div>
            <div class="form-group col-md-4">
              <it-input label="Nome" formControlName="nome"></it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <it-select
                label="Attributo"
                formControlName="attributo"
                [options]="attributiSelectOptions"
              >
              </it-select>
            </div>
            <div class="form-group col-md-4">
              <it-input label="Valore" formControlName="valore"></it-input>
            </div>
            <div class="form-group col-md-4">
              <it-checkbox
                label="Utenti attivi"
                toggle="true"
                formControlName="soloUtentiValidi"
              ></it-checkbox>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <button type="reset" itButton="light">Svuota Campi</button>
              &nbsp;
              <button type="submit" itButton="primary" [disabled]="!form.valid">
                Ricerca
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                itButton="secondary"
                (click)="nuovoUtente()"
              >
                Nuovo Utente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="ricercaUtentiList">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Risultati ricerca
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <div
            id="risultatiRicercaUtenti"
            class="mt-4"
            *ngIf="ricercaUtentiList"
          >
            <!-- [ngbCollapse]="!ricercaUtentiList"> -->
            <div class="table-responsive-md">
              <it-table hover="true">
                <caption>
                  Lista degli utenti
                </caption>
                <ng-container
                  thead
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length > 0"
                >
                  <tr>
                    <th>
                      <it-checkbox
                        class="select-all"
                        [checked]="isSelectAllChecked(ricercaUtentiList)"
                        (change)="onSelectAllUtenti($event)"
                      ></it-checkbox>
                    </th>
                    <th
                      scope="col"
                      sortable="codiceFiscale"
                      (sort)="onSort($event)"
                    >
                      Codice Fiscale
                    </th>
                    <th scope="col" sortable="cognome" (sort)="onSort($event)">
                      Cognome
                    </th>
                    <th scope="col" sortable="nome" (sort)="onSort($event)">
                      Nome
                    </th>
                    <th scope="col">Attributi</th>
                    <th scope="col"></th>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length === 0"
                >
                  <tr>
                    <td colspan="4">
                      <h6 class="mt-2">Nessun utente trovato</h6>
                    </td>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length !== 0"
                >
                  <tr *ngFor="let utente of ricercaUtentiList">
                    <td>
                      <it-checkbox
                        [value]="utente.codiceFiscale"
                        [checked]="utente.selected"
                        (change)="onSelectUtente($event, utente.codiceFiscale)"
                      ></it-checkbox>
                    </td>
                    <td>
                      {{ utente.codiceFiscale | uppercase }}
                    </td>
                    <td>
                      {{ utente.cognome | uppercase }}
                    </td>
                    <td>
                      {{ utente.nome | uppercase }}
                    </td>
                    <td style="white-space: pre">
                      <span innerHTML="{{ utente.attributi }}"></span>
                    </td>
                    <td style="text-align: right">
                      <button
                        id="modificaUtenteButton"
                        itTooltip="Modifica utente"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="modificaUtente(utente)"
                      >
                        <it-icon name="pencil"></it-icon>
                      </button>
                      <button
                        id="eliminaUtenteButton"
                        itTooltip="Elimina utente"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="eliminaUtente(utente)"
                      >
                        <it-icon name="delete"></it-icon>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </it-table>
            </div>
            <div
              id="paginazione"
              *ngIf="ricercaUtentiList && ricercaUtentiList.length !== 0"
            >
              <div class="row">
                <div class="input-group col-md-4">
                  <label for="numero-voci">Numero utenti:</label>
                  <div class="ml-3">&nbsp;{{ numeroVoci }}</div>
                </div>
                <div
                  class="input-group col-md-4"
                  *ngIf="selectedUtenti && selectedUtenti.length > 0"
                >
                  <label for="numero-voci">Utenti selezionati:</label>
                  <div class="ml-3">
                    &nbsp;{{ selectedUtenti.length }} di
                    {{ ricercaUtentiList.length }}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <it-pagination
                      [currentPage]="page"
                      [pageNumbers]="numberOfPages"
                      alignment="center"
                      (pageEvent)="getPage($event)"
                    ></it-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-between"
            *ngIf="ricercaUtentiList && ricercaUtentiList.length !== 0"
          >
            <div class="col">
              <button
                type="button"
                itButton="secondary"
                (click)="nuoviAttributiUtenti()"
              >
                Nuovi Attributi Utenti
              </button>
            </div>
            <div class="col-auto">
              <button
                class="mx-2"
                type="button"
                itButton="secondary"
                (click)="esportaUtenti()"
                [disabled]="!form.valid"
              >
                Esporta Utenti
              </button>
              <button
                class="mx-2"
                type="button"
                itButton="outline-secondary"
                (click)="importaUtenti()"
              >
                Importa Utenti
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
