import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { FormCommons } from "src/app/utility/commons";
import { Subscription } from "rxjs";
import { UploadFileListItem } from "design-angular-kit";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-import-strutture-organizzative-page",
  templateUrl: "./import-strutture-organizzative-page.component.html",
  styleUrls: ["./import-strutture-organizzative-page.component.css"],
})
export class ImportStruttureOrganizzativePageComponent
  implements OnInit
{
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  userSubscription: Subscription;
  uploadedFileList = new Array<UploadFileListItem>();

  constructor(
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    userService: UserService,
    private router: Router
  ) {
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      file: null,
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  tornaRicercaStrutture(): void {
    this.router.navigate(["/struttureOrg"], {
      state: {
        searchOnLoad: true,
      },
    });
  }
}
