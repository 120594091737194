import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppInjector } from "src/app/helpers/app-injector";
import { FormCommons } from "src/app/utility/commons";
import {
  SortableHeaderDirective,
  SortEvent,
} from "src/app/directive/sortable-header.directive";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import { Subscription } from "rxjs";
import { UserService } from "src/app/service/user-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-ricerca-strutture-organizzative",
  templateUrl: "./ricerca-strutture-organizzative.component.html",
  styleUrls: ["./ricerca-strutture-organizzative.component.css"],
})
export class RicercaStruttureOrganizzativeComponent
  extends SearchBaseComponent
  implements OnInit
{
  formCommons = AppInjector.get(FormCommons);
  form: UntypedFormGroup;

  pageStatus: string = "SEARCH";
  formTitle: string = "";
  shouldValidate: boolean;

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  ricercaOrgCriteri: any;
  @Input()
  ricercaOrgList: any[] = undefined;

  userSubscription: Subscription;

  @Output()
  onSearch = new EventEmitter<any>();

  @Output()
  onDownload = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      descrizione: null,
    });
    if (history.state.searchOnLoad) {
      this.submit();
    }
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaOrgCriteri = {
      ...this.ricercaOrgCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaOrgCriteri);
  }

  getPage(page: any) {
    this.ricercaOrgCriteri = {
      ...this.ricercaOrgCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaOrgCriteri);
  }

  nuovaOrg() {
    this.router.navigate(["/inserimentoOrg"]);
  }

  eliminaOrg(org) {
    // this.router.navigate(["/modificaOrg", org.id]);
  }

  importaOrg() {
    this.router.navigate(["/importOrg"]);
  }

  modificaOrg(org) {
    this.router.navigate(["/modificaOrg", org.id]);
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaOrgCriteri = {
      criteri: {
        descrizione: this.form.controls.descrizione.value ?? "",
      },
      sortInfo: this.setSortInfo("descrizione", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaOrgCriteri);
  }

  esportaOrg() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaOrgCriteri = {
      criteri: {
        descrizione: this.form.controls.descrizione.value ?? "",
      },
      sortInfo: this.setSortInfo("descrizione", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.invalid) {
      return;
    }
    this.onDownload.emit(this.ricercaOrgCriteri);
  }
}
