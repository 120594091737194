<h3 class="mb-4">{{ formTitle }}</h3>

<div class="accordion accordion-background-active" id="accordionDatiUtente">
  <form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati attributo
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0 col-md-3">
              <it-input label="Attributo" formControlName="chiave"></it-input>
            </div>
            <div class="form-group col-md-3 form-group-b-0">
              <ng-container
                *ngIf="
                  form.value.listaValori && form.value.listaValori.length > 0
                "
              >
                <it-select
                  formControlName="valore"
                  [options]="form.value.listaValori"
                >
                  <ng-container error>
                    <div *ngIf="form.controls.valore.hasError('required')">
                      Il campo è obbligatorio
                    </div>
                  </ng-container>
                </it-select>
              </ng-container>
              <ng-container
                *ngIf="
                  (!form.value.listaValori ||
                    form.value.listaValori.length == 0) &&
                  !form.value.isDate
                "
              >
                <it-input formControlName="valore">
                  <ng-container error>
                    <div *ngIf="form.controls.valore.hasError('required')">
                      Il campo è obbligatorio
                    </div>
                    <div *ngIf="form.controls.valore.hasError('regexp')">
                      Valore non valido
                    </div>
                  </ng-container>
                </it-input>
              </ng-container>
              <ng-container
                *ngIf="
                  (!form.value.listaValori ||
                    form.value.listaValori.length == 0) &&
                  form.value.isDate
                "
              >
                <it-input type="date" formControlName="valore">
                  <ng-container error>
                    <div *ngIf="form.controls.valore.hasError('required')">
                      Il campo è obbligatorio
                    </div>
                  </ng-container>
                </it-input>
              </ng-container>
            </div>
            <div class="form-group form-group-b-0 col-md-3">
              <it-input
                type="date"
                label="Data Inizio"
                formControlName="dataInizio"
              >
              </it-input>
            </div>
            <div class="form-group form-group-b-0 col-md-3">
              <it-input
                type="date"
                label="Data Fine"
                formControlName="dataInizio"
              ></it-input>
            </div>
          </div>
          <div class="row justify-content-evenly mt-4">
            <div class="col">&nbsp;</div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item" *ngIf="showListaUtenti">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Utenti
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <div
            id="risultatiRicercaUtenti"
            class="mt-4"
            *ngIf="ricercaUtentiList"
          >
            <!-- [ngbCollapse]="!ricercaUtentiList"> -->
            <div class="table-responsive-md">
              <it-table hover="true">
                <ng-container
                  thead
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length > 0"
                >
                  <tr>
                    <th
                      scope="col"
                      sortable="codiceFiscale"
                      (sort)="onSort($event)"
                    >
                      Codice Fiscale
                    </th>
                    <th scope="col" sortable="cognome" (sort)="onSort($event)">
                      Cognome
                    </th>
                    <th scope="col" sortable="nome" (sort)="onSort($event)">
                      Nome
                    </th>
                    <th scope="col"></th>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length === 0"
                >
                  <tr>
                    <td colspan="3">
                      <h6 class="mt-2">Nessun utente trovato</h6>
                    </td>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaUtentiList && ricercaUtentiList.length !== 0"
                >
                  <ng-container *ngFor="let utente of ricercaUtentiList">
                    <tr
                      [ngClass]="{
                        'attribute-list-item-not-valid': !isUtenteAttivo(utente)
                      }"
                    >
                      <td>
                        {{ utente.codiceFiscale | uppercase }}
                      </td>
                      <td>
                        {{ utente.cognome | uppercase }}
                      </td>
                      <td>
                        {{ utente.nome | uppercase }}
                      </td>
                      <td style="text-align: right">
                        <button
                          id="eliminaUtenteButton"
                          itTooltip="Elimina utente"
                          tooltipPlacement="bottom"
                          class="btn py-0"
                          type="button"
                          (click)="removeUtente(utente)"
                          *ngIf="isUtenteAttivo(utente)"
                        >
                          <it-icon name="delete"></it-icon>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </it-table>
            </div>
            <div
              id="paginazione"
              *ngIf="ricercaUtentiList && ricercaUtentiList.length !== 0"
            >
              <div class="row">
                <div class="input-group col-md-4">
                  <label for="numero-voci">Numero utenti:</label>
                  <div class="ml-3">&nbsp;{{ numeroVoci }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <it-pagination
                      [currentPage]="page"
                      [pageNumbers]="numberOfPages"
                      alignment="center"
                      (pageEvent)="getPage($event)"
                    ></it-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">&nbsp;</div>
            <div class="col-auto">
              <button
                type="button"
                itButton="secondary"
                (click)="nuovoUtente()"
              >
                Nuovo Utente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="formUtente" autocomplete="off" (ngSubmit)="saveUtente()">
    <div class="accordion-item" *ngIf="!showListaUtenti">
      <h2 class="accordion-header" id="heading1c">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1c"
          aria-expanded="true"
          aria-controls="collapse1c"
        >
          Nuovo Utente
        </button>
      </h2>
      <div
        id="collapse1c"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1c"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0">
              <it-input
                label="Codice Fiscale *"
                formControlName="codiceFiscale"
                #codiceFiscale
              >
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="codiceFiscale.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="codiceFiscale.hasError('minlength')">
                    Codice Fiscale non valido
                  </div>
                  <div *ngIf="codiceFiscale.hasError('maxlength')">
                    Codice Fiscale non valido
                  </div>
                  <div *ngIf="codiceFiscale.hasError('pattern')">
                    Non corrisponde al pattern [ab]+
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Cognome *" formControlName="cognome" #cognome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="cognome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                </ng-container>
              </it-input>
            </div>
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Nome *" formControlName="nome" #nome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="nome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
          <div class="row justify-content-evenly mt-4">
            <div class="col">&nbsp;</div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-secondary mx-2"
                (click)="annullaNuovoUtente()"
              >
                Annulla
              </button>
              <button type="submit" class="btn btn-primary mx-2">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="row justify-content-evenly mt-4">
  <div class="col">
    <button
      type="button"
      itButton="outline-secondary"
      (click)="tornaRicercaAttributi()"
    >
      <it-icon name="arrow-left" class="me-2"></it-icon>
      Ricerca Attributi
    </button>
  </div>
</div>
