import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./page/home/home.component";
import { UtenteNonAbilitatoComponent } from "./page/utente-non-abilitato/utente-non-abilitato.component";
import { SessioneScadutaComponent } from "./page/sessione-scaduta/sessione-scaduta.component";
import { LoginPageComponent } from "./page/login-page/login-page.component";
import { AuthGuard } from "./helpers/auth-guard";
import { AdminGuard } from "./helpers/admin-guard";
import { UserGuard } from "./helpers/user-guard";
import { ResetpwGuard } from "./helpers/resetpw-guard";
import { InserimentoNuovoUtenteComponent } from "./page/inserimento-nuovo-utente/inserimento-nuovo-utente.component";
import { ModificaUtenteComponent } from "./page/modifica-utente/modifica-utente.component";
import { RicercaUtentiAamComponent } from "./page/ricerca-utenti-aam/ricerca-utenti-aam.component";
import { GestioneAssetComponent } from "./page/gestione-asset/gestione-asset.component";
import { InserimentoNuovoAssetComponent } from "./page/inserimento-nuovo-asset/inserimento-nuovo-asset.component";
import { ModificaAssetComponent } from "./page/modifica-asset/modifica-asset.component";
import { InserimentoNuovoAttributoComponent } from "./page/inserimento-nuovo-attributo/inserimento-nuovo-attributo.component";
import { ModificaAttributoComponent } from "./page/modifica-attributo/modifica-attributo.component";
import { RicercaUtentiAssetPageComponent } from "./page/ricerca-utenti-asset-page/ricerca-utenti-asset-page.component";
import { InserimentoNuovoUtenteAssetComponent } from "./page/inserimento-nuovo-utente-asset/inserimento-nuovo-utente-asset.component";
import { ModificaUtenteAssetComponent } from "./page/modifica-utente-asset/modifica-utente-asset.component";
import { RicercaAttributiAssetPageComponent } from "./page/ricerca-attributi-asset-page/ricerca-attributi-asset-page.component";
import { ImportUtentiAssetPageComponent } from "./page/import-utenti-asset-page/import-utenti-asset-page.component";
import { ModificaAttributoAssetComponent } from "./page/modifica-attributo-asset/modifica-attributo-asset.component";
import { InserimentoNuoviAttributiUtentiAssetComponent } from "./page/inserimento-nuovi-attributi-utenti-asset/inserimento-nuovi-attributi-utenti-asset.component";
import { SelezionaAssetPageComponent } from "./page/seleziona-asset-page/seleziona-asset-page.component";
import { StruttureOrganizzativeComponent } from "./page/strutture-organizzative/strutture-organizzative.component";
import { InserimentoNuovaStrutturaOrganizzativaComponent } from "./page/inserimento-nuova-struttura-organizzativa/inserimento-nuova-struttura-organizzativa.component";
import { ModificaStrutturaOrganizzativaComponent } from "./page/modifica-struttura-organizzativa/modifica-struttura-organizzativa.component";
import { ImportStruttureOrganizzativePageComponent } from "./page/import-strutture-organizzative-page/import-strutture-organizzative-page.component";
import { ImportUtentiAamPageComponent } from "./page/import-utenti-aam-page/import-utenti-aam-page.component";
import { NuovoUtenteAamComponent } from "./page/nuovo-utente-aam/nuovo-utente-aam.component";
import { NuovoUtenteAssetComponent } from "./page/nuovo-utente-asset/nuovo-utente-asset.component";
import { LogoutPageComponent } from "./page/logout-page/logout-page.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "home", component: HomeComponent },
        { path: "sessionExpired", component: SessioneScadutaComponent },
        { path: "errorPage", component: UtenteNonAbilitatoComponent },
        { path: "login", component: LoginPageComponent },
        { path: "logout", component: LogoutPageComponent },
        { path: "utenteNonAbilitato", component: UtenteNonAbilitatoComponent },
        { path: "ricercaUtentiAam", component: RicercaUtentiAamComponent },
        {
          path: "nuovoUtenteAam",
          component: NuovoUtenteAamComponent,
        },
        {
          path: "inserimentoUtente",
          component: InserimentoNuovoUtenteComponent,
        },
        { path: "modificaUtente/:id", component: ModificaUtenteComponent },
        { path: "importUtentiAam", component: ImportUtentiAamPageComponent },
        { path: "gestioneAsset", component: GestioneAssetComponent },
        { path: "inserimentoAsset", component: InserimentoNuovoAssetComponent },
        { path: "modificaAsset/:id", component: ModificaAssetComponent },
        {
          path: "nuovoAttributo/:id",
          component: InserimentoNuovoAttributoComponent,
        },
        {
          path: "modificaAttributo/:id",
          component: ModificaAttributoComponent,
        },
        {
          path: "ricercaUtentiAsset",
          component: RicercaUtentiAssetPageComponent,
        },
        {
          path: "nuovoUtenteAsset",
          component: NuovoUtenteAssetComponent,
        },
        {
          path: "inserimentoUtenteAsset",
          component: InserimentoNuovoUtenteAssetComponent,
        },
        {
          path: "modificaUtenteAsset/:id",
          component: ModificaUtenteAssetComponent,
        },
        {
          path: "ricercaAttributiAsset",
          component: RicercaAttributiAssetPageComponent,
        },
        {
          path: "importUtentiAsset",
          component: ImportUtentiAssetPageComponent,
        },
        {
          path: "modificaAttributoAsset/:id",
          component: ModificaAttributoAssetComponent,
        },
        {
          path: "inserimentoAttributiUtentiAsset",
          component: InserimentoNuoviAttributiUtentiAssetComponent,
        },
        { path: "selezionaAsset", component: SelezionaAssetPageComponent },
        { path: "struttureOrg", component: StruttureOrganizzativeComponent },
        {
          path: "inserimentoOrg",
          component: InserimentoNuovaStrutturaOrganizzativaComponent,
        },
        {
          path: "modificaOrg/:id",
          component: ModificaStrutturaOrganizzativaComponent,
        },
        {
          path: "importOrg",
          component: ImportStruttureOrganizzativePageComponent,
        },
        { path: "**", redirectTo: "/login", pathMatch: "full" },
      ],
      {}
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
