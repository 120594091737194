<app-container>
  <h3 class="mb-4">Ricerca Utenti AAM</h3>
  <app-ricerca-utenti
    [ricercaUtentiList]="ricercaUtentiList"
    [search]="searchSubject.asObservable()"
    (onSearch)="searchUtenti($event)"
    (onDownload)="exportUtenti($event)"
    (onDelete)="onDeleteUtente($event)"
  >
  </app-ricerca-utenti>

  <app-modal-confirm
    #confirmModalDeleteUtente
    title="Eliminazione Utente"
    description="Vuoi confermare l'eliminazione dell'utente selezionato?"
    (onConfirm)="deleteUtente()"
  >
  </app-modal-confirm>  
</app-container>
