import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function RegExpValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const regexp = control.parent?.get("regexp")?.value ?? "";

    if (!value || regexp == "") {
      return null;
    }
    return !new RegExp(regexp).test(value) ? { regexp: true } : null;
  };
}
