export const ERRORI = [
  {
    codice: "UTENTE-0003",
    descrizione: (parametri) =>
      `riga ${
        parametri.find((p) => p.chiave == "indiceRiga")?.valore
      } - Codice Fiscale non valido ${
        parametri.find((p) => p.chiave == "codiceFiscale")?.valore
      }`,
  },
  {
    codice: "UTENTE-0007",
    descrizione: (parametri) =>
      `riga ${
        parametri.find((p) => p.chiave == "indiceRiga")?.valore
      } - Profilo utente obbligatorio`,
  },
  {
    codice: "UTENTE-0008",
    descrizione: (parametri) =>
      `riga ${
        parametri.find((p) => p.chiave == "indiceRiga")?.valore
      } - Utente non autorizzato a creare il profilo ${
        parametri.find((p) => p.chiave == "profilo")?.valore
      }`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0002",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "valoreAttributo")?.valore
      } - Valore non valido`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0003",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "valoreAttributo")?.valore
      } - Valore non specificato`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0007",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "valoreAttributo")?.valore
      } - Data non valida`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0008",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "chiaveAttributo")?.valore
      } - Chiave attributo non specificata`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0009",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "chiaveAttributo")?.valore
      } - Attributo non presente`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0010",
    descrizione: (parametri) => `Data inizio successiva a data fine`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0013",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "nomeAsset")?.valore ?? "Asset"
      } - Struttura organizzativa obbligatoria`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0014",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "nomeAsset")?.valore ?? "Asset"
      } - Struttura organizzativa non valorizzabile`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0015",
    descrizione: (parametri) =>
      `${
        parametri.find((p) => p.chiave == "valore")?.valore
      } - Valore non presente tra i predefiniti`,
  },
  {
    codice: "VALORE-ATTRIBUTO-0016",
    descrizione: (parametri) =>
      `utente: ${
        parametri.find((p) => p.chiave == "cfUtente")?.valore
      } - Attributo obbligatorio (${
        parametri.find((p) => p.chiave == "attributo")?.valore
      })`,
  },
  {
    codice: "IMPORT_EXCEL-0001",
    descrizione: (parametri) => `Nessun dato presente nel file Excel`,
  },
  {
    codice: "IMPORT_EXCEL-0002",
    descrizione: (parametri) => `Errore durante l'elaborazione`,
  },
  {
    codice: "STRUTTURA-ORGANIZZATIVA-0001",
    descrizione: (parametri) =>
      `Struttura organizzativa "${
        parametri.find((p) => p.chiave == "descStruttura")?.valore
      }" già censita`,
  },
];

export const descrizioneErrore = (codice: string, parametri: any[]): string =>
  ERRORI.find((errore) => errore.codice == codice)?.descrizione(parametri);
