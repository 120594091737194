import { Component, OnInit, ViewChild } from "@angular/core";
import { RicercaAssetService } from "../../service/ricerca-asset-service";

import { RicercaAssetComponent } from "src/app/component/ricerca-asset/ricerca-asset.component";
import { AlertService } from "src/app/utility/alert-service";

@Component({
  selector: "app-gestione-asset",
  templateUrl: "./gestione-asset.component.html",
  styleUrls: ["./gestione-asset.component.css"],
})
export class GestioneAssetComponent implements OnInit {
  ricercaAssetList: any[] | undefined = undefined;

  @ViewChild(RicercaAssetComponent) rac!: RicercaAssetComponent;

  constructor(private ricercaUtentiAamService: RicercaAssetService) {}

  ngOnInit(): void {}

  searchAsset(criteri: any) {
    this.ricercaUtentiAamService
      .search(criteri)
      .then((result) => {
        this.ricercaAssetList = result?.items;
        this.rac.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaAssetList = [];
        }
      });
  }
}
