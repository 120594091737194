import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

export class DateUtils {
  public static isDateTimeInTheLastXMinutes(
    date: string,
    minutes: number
  ): boolean {
    if (!date) return false;
    const startDate = new Date();
    startDate.setMinutes(startDate.getMinutes() - minutes);

    const dateTimeParts = date.split(" ");
    const dateParts = dateTimeParts[0].split("/");
    const timeParts = dateTimeParts[1].split(":");
    const endDate = new Date(
      +dateParts[2],
      +dateParts[1] - 1,
      +dateParts[0],
      +timeParts[0],
      +timeParts[1],
      +timeParts[2]
    );
    return startDate < endDate;
  }

  public static fromBeDate(value: string, separator: string = "-"): string {
    if (!value) {
      return null;
    }
    let completeDate = value.split(" ");
    let date = completeDate[0].split(separator);
    return `${date[2]}-${date[1]}-${date[0]}`;
  }
  public static fromBeDateToShortDate(value: string): string {
    if (!value) {
      return null;
    }
    return moment(this.fromBeDate(value)).format("D-M-YY");
  }

  public static isBeDateValid(dataInizio: string, dataFine: string) {
    const inizio = dataInizio ? new Date(this.fromBeDate(dataInizio)) : null;
    inizio?.setHours(0);
    const fine = dataFine ? new Date(this.fromBeDate(dataFine)) : null;
    fine?.setHours(0);
    const today = new Date(new Date().toDateString());
    if (!inizio && !fine) return true;
    if (inizio && !fine) return inizio <= today;
    if (!inizio && fine) return fine >= today;
    if (inizio && fine) return inizio <= today && fine >= today;
    return true;
  }

  public static yesterday(): Date {
    var result = new Date(new Date().toDateString());
    result.setDate(result.getDate() - 1);
    return result;
  }
  public static yesterdayFeDate(): string {
    return moment(this.yesterday()).format("YYYY-MM-DD");
  }

  public static yesterdayBeDate(): string {
    return moment(this.yesterday()).format("DD-MM-YYYY");
  }

  public static toBeDate(value: string, separator: string = "-"): string {
    if (!value) {
      return null;
    }
    const date = value.split("-");
    return `${date[2]}${separator}${date[1]}${separator}${date[0]}`;
  }

  public static convertToFeDate(value: string): NgbDateStruct | string {
    if (!value) {
      return "";
    }
    let completeDate = value.split(" ");
    let date = completeDate[0].split("-");
    return {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
  }

  public static convertToBEOnlyDateString(date: NgbDateStruct | null): string {
    if (!date) {
      return "";
    }
    return `${DateUtils.getDay(date)}-${DateUtils.getMonth(
      date
    )}-${DateUtils.getYear(date)} 00:00:00`;
  }

  public static convertToBEDateString(date: NgbDateStruct | null): string {
    if (!date) {
      return "";
    }
    return `${DateUtils.getDay(date)}-${DateUtils.getMonth(
      date
    )}-${DateUtils.getYear(date)}`;
  }

  public static getDay(date: NgbDateStruct | null): string {
    if (!date) {
      return "00";
    }
    return `${date.day < 10 ? "0" + date.day : date.day}`;
  }

  public static getMonth(date: NgbDateStruct | null): string {
    if (!date) {
      return "00";
    }
    return `${date.month < 10 ? "0" + date.month : date.month}`;
  }

  public static getYear(date: NgbDateStruct | null): string {
    if (!date) {
      return "0000";
    }
    if (date.year < 10) {
      return `${"200" + date.year}`;
    } else if (date.year < 100) {
      return `${"20" + date.year}`;
    } else if (date.year < 1000) {
      return `${"2" + date.year}`;
    }
    return `${date.year}`;
  }
}
