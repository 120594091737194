import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { FormCommons } from "src/app/utility/commons";
import { ImportExcelService } from "../../service/import-excel-service";
import { Subscription } from "rxjs";
import { UploadFileListItem } from "design-angular-kit";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-import-utenti-aam-page",
  templateUrl: "./import-utenti-aam-page.component.html",
  styleUrls: ["./import-utenti-aam-page.component.css"],
})
export class ImportUtentiAamPageComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  userSubscription: Subscription;
  uploadedFileList = new Array<UploadFileListItem>();
  ricercaUtentiCriteri: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    userService: UserService,
    private router: Router,
    private importExcelService: ImportExcelService
  ) {
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      file: null,
    });
    if (history.state.ricercaUtentiCriteri) {
      this.ricercaUtentiCriteri = history.state.ricercaUtentiCriteri;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  functionImport = this.importExcelService.importUtentiAam;

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  tornaRicercaUtenti(): void {
    this.router.navigate(["/ricercaUtentiAam"], {
      state: {
        searchOnLoad: true,
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }
}
