import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  map,
  of,
  skipWhile,
  tap,
} from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { UploadFileListItem } from "design-angular-kit/public_api";
import { AssetService } from "src/app/service/asset-service";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AlertService } from "src/app/utility/alert-service";
import { ImportExcelService } from "src/app/service/import-excel-service";
import { NgxSpinnerService } from "ngx-spinner";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import {
  SortEvent,
  SortableHeaderDirective,
} from "src/app/directive/sortable-header.directive";
import { descrizioneErrore } from "src/app/tipologiche/errori";
import { DateUtils } from "src/app/utility/date-utils";
import { RicercaAttributiAssetService } from "src/app/service/ricerca-attributi-asset-service";

@Component({
  selector: "app-import-file-excel",
  templateUrl: "./import-file-excel.component.html",
  styleUrls: ["./import-file-excel.component.css"],
})
export class ImportFileExcelComponent
  extends SearchBaseComponent
  implements OnInit, OnDestroy
{
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  uploadedFileList = new Array<UploadFileListItem>();
  criteriRicercaImport: any;
  showListaErroriElaborazione: boolean = false;
  erroriElaborazione: any[];
  erroriElaborazioneCurrentPage: any[];
  pageErrore: number = 0;
  numberOfPagesErrori: number = 0;
  numeroVociErrori: string;

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }
  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  textPulsanteRicerca: string;

  @Input()
  pageStatus: string;

  @Input()
  importType: string;

  @Input()
  formTitle: string = "";

  @Input()
  ricercaImportList: any[];

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onBackToRicerca = new EventEmitter<void>();

  @Output()
  onSearchImport = new EventEmitter<any>();

  userSub: Subscription;
  importSub: Subscription;
  idAsset: number;
  idOrg: number;

  constructor(
    private assetService: AssetService,
    private userService: UserService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private importExcelService: ImportExcelService,
    private ricercaAttributiAssetService: RicercaAttributiAssetService
  ) {
    super();
  }

  onUpdateFileList(files: FileList): void {
    const newFiles: Array<UploadFileListItem> = Array.from(files).map(
      (file, index) => ({
        id: index + this.uploadedFileList.length, // I set an id, useful when deleting
        file, // The uploaded file
        removable: true, // set new file as removable
      })
    );

    this.uploadedFileList = [...newFiles];
  }

  onDeleteFileList(item: UploadFileListItem): void {
    this.uploadedFileList = new Array<UploadFileListItem>();
  }

  ngOnInit(): void {
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);
      if (this.importType === "UTENTI") {
        this.criteriRicercaImport = {
          utenteCreazione: usr.codiceFiscale,
          idAsset: this.idAsset,
          idStrutturaOrganizzativa: this.idOrg,
          sortInfo: this.setSortInfo("dataRichiesta", "DESC"),
          pageInfo: this.initializePageInfo(),
        };
        this.onSearchImport.emit(this.criteriRicercaImport);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.importSub && this.importSub.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.criteriRicercaImport = {
      ...this.criteriRicercaImport,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearchImport.emit(this.criteriRicercaImport);
  }

  getPage(page: any) {
    this.criteriRicercaImport = {
      ...this.criteriRicercaImport,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearchImport.emit(this.criteriRicercaImport);
  }

  getPageErrori(page: number) {
    this.erroriElaborazioneCurrentPage = this.erroriElaborazione.slice(
      page === 0 ? 0 : page * this.pageSize,
      page === 0 ? this.pageSize : page * this.pageSize + this.pageSize
    );
    this.pageErrore = page;
    this.numeroVociErrori = `${this.erroriElaborazioneCurrentPage.length} di ${this.erroriElaborazione.length}`;
  }

  visualizzaErrori(row) {
    this.showListaErroriElaborazione = true;
    this.erroriElaborazione = row.erroriElaborazione.map((e) => ({
      numeroRiga: e.parametri?.find((p) => p.chiave === "indiceRiga")?.valore,
      descrizione: descrizioneErrore(e.codMsg, e.parametri),
    }));
    this.getPageErrori(0);
    this.numberOfPagesErrori = Math.ceil(
      this.erroriElaborazione.length / this.pageSize
    );
  }

  nascondiErrori() {
    this.showListaErroriElaborazione = false;
    this.erroriElaborazione = null;
    this.pageErrore = 0;
    this.numberOfPagesErrori = 0;
  }

  aggiornaListaImport(): void {
    this.onSearchImport.emit(this.criteriRicercaImport);
  }

  isElaborazioneInCorso(row): boolean {
    return (
      !row.dataCompletamento &&
      DateUtils.isDateTimeInTheLastXMinutes(row.dataRichiesta, 60)
    );
  }

  downloadImport(row) {
    this.importExcelService.downloadImport(
      row.id,
      `import-utenti-${row.dataRichiesta.replaceAll(/[\/: ]/g, "")}.xlsx`
    );
  }

  tornaRicerca(): void {
    this.onBackToRicerca.emit();
  }

  save() {
    const formData = new FormData();

    const body = JSON.stringify({
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
    });
    const blob = new Blob([body], {
      type: "application/json",
    });

    const fileToUpload = this.uploadedFileList[0];
    fileToUpload.progress = 1;
    fileToUpload.error = undefined;
    fileToUpload.removable = false;

    formData.append("body", blob);
    formData.append("file", fileToUpload.file);

    // const import$ = this.importExcelService.importUtenti(formData).pipe(
    const fnImport$ =
      this.importType === "UTENTI"
        ? this.importExcelService.importUtenti(formData)
        : this.importType === "STRUTTURE-ORGANIZZATIVE"
        ? this.importExcelService.importStruttureOrganizzative(formData)
        : this.importType === "UTENTI AAM"
        ? this.importExcelService.importUtentiAam(formData)
        : null;

    const import$ = fnImport$.pipe(
      tap((event) => {
        // Update the progress request status
        if (event.type === HttpEventType.UploadProgress) {
          fileToUpload.progress = Math.round(
            (event.loaded / (event.total || 1)) * 100
          );
        }
      }),
      skipWhile((event) => event.type !== HttpEventType.Response), // skip while the request not is complete
      map((event) => {
        const responseBody = (event as HttpResponse<any>).body;
        if (responseBody.diagnostici && responseBody.diagnostici.length > 0) {
          throw Error();
        }
      }), // request is complete with body
      tap((uploadedFile) => {
        fileToUpload.progress = 100;
      }),
      catchError((error) => {
        fileToUpload.progress = undefined;
        fileToUpload.removable = true;
        fileToUpload.error = true;
        fileToUpload.tooltip = error.message; // Show error message as tooltip
        if (this.importType === "UTENTI") {
          this.alertService.addDefaultErrorMessage();
        }
        return of(null); // File upload failed
      }),
      finalize(() => {
        if (
          !fileToUpload.error &&
          fileToUpload.progress &&
          fileToUpload.progress < 100
        ) {
          // There are no errors but the file upload is not complete
          fileToUpload.progress = undefined;
          fileToUpload.removable = true;
          fileToUpload.error = true;
          // fileToUpload.tooltip = "Operazione non riuscita"; // Show error message as tooltip
          this.alertService.addErrorMessage(
            "Operazione non riuscita",
            fileToUpload.tooltip
          );
          this.spinner.hide();
        } else if (!fileToUpload.error && fileToUpload.progress == 100) {
          if (this.criteriRicercaImport) {
            this.onSearchImport.emit(this.criteriRicercaImport);
          } else {
            this.spinner.hide();
            this.alertService.addDefaultSuccessMessage();
          }
        } else {
          this.spinner.hide();
        }
      })
    );

    this.spinner.show();
    this.importSub = import$.subscribe();
  }
  esportaTemplate() {
    if (this.importType === "UTENTI")
      this.ricercaAttributiAssetService.exportTemplateUtenti();
  }
}
