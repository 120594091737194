export const environment = {
  production: false,
  version: '1.0.0',
  issuer: "https://trial.auth.regione.toscana.it/auth-trial/realms/rt",
  authUrl:
    "https://trial.auth.regione.toscana.it/auth-trial/realms/rt/protocol/openid-connect/auth",
  tokenUrl:
    "https://trial.auth.regione.toscana.it/auth-trial/realms/rt/protocol/openid-connect/token",
  logoutUrl:
    "https://trial.auth.regione.toscana.it/auth-trial/realms/rt/protocol/openid-connect/logout",
  redirect_uri: "https://autorizza-test.sanita.toscana.it",
  client_id: "r_toscan__aam",
  itemsPerPage: 10,
  tokenSessionTime: 20,
  refresh_tokenTime: 10,
  access_tokenTime: 4,
  context: "https://apistage.regione.toscana.it/sanita/C06/aam-be/v1",
  apiPath: "/api",
  apiVersion: "v1",
  contextRct: "https://apistage.regione.toscana.it/sanita/C06/aam-rct/v1",
  apiPathRct: "/api",
  apiVersionRct: "v1",
};
