<app-container>
  <app-dati-attributo
    [(form)]="form"
    [pageStatus]="'EDIT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Attributo'"
    (onSubmit)="onSave()"
  >
  </app-dati-attributo>

  <app-modal-confirm
    #confirmModal
    title="Modifica Attributo"
    description="Vuoi confermare l'aggiornamento dell'attributo?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
