import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppInjector } from "src/app/helpers/app-injector";
import { FormCommons } from "src/app/utility/commons";
import {
  SortableHeaderDirective,
  SortEvent,
} from "src/app/directive/sortable-header.directive";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import { Subscription } from "rxjs";
import { UserService } from "src/app/service/user-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-ricerca-asset",
  templateUrl: "./ricerca-asset.component.html",
  styleUrls: ["./ricerca-asset.component.css"],
})
export class RicercaAssetComponent
  extends SearchBaseComponent
  implements OnInit
{
  formCommons = AppInjector.get(FormCommons);
  form: UntypedFormGroup;

  pageStatus: string = "SEARCH";
  formTitle: string = "";
  shouldValidate: boolean;

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  ricercaAssetCriteri: any;
  @Input()
  ricercaAssetList: any[] = undefined;

  userSubscription: Subscription;

  @Output()
  onSearch = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nome: null,
    });
    if (history.state.searchOnLoad) {
      if (history.state.ricercaAssetCriteri) {
        this.ricercaAssetCriteri = history.state.ricercaAssetCriteri;
        this.form.patchValue({
          nome: this.ricercaAssetCriteri.nome,
        });
        this.onSearch.emit(this.ricercaAssetCriteri);
      } else {
        this.submit();
      }
    }
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaAssetCriteri = {
      ...this.ricercaAssetCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaAssetCriteri);
  }

  getPage(page: any) {
    this.ricercaAssetCriteri = {
      ...this.ricercaAssetCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaAssetCriteri);
  }

  nuovoAsset() {
    this.router.navigate(["/inserimentoAsset"], {
      state: {
        ricercaAssetCriteri: this.ricercaAssetCriteri,
      },
    });
  }

  modificaAsset(asset) {
    this.router.navigate(["/modificaAsset", asset.id], {
      state: {
        ricercaAssetCriteri: this.ricercaAssetCriteri,
      },
    });
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaAssetCriteri = {
      criteri: {
        nome: this.form.controls.nome.value ?? "",
      },
      sortInfo: this.setSortInfo("nome", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaAssetCriteri);
  }
}
