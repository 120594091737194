import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ItModalComponent } from "design-angular-kit";
import { Subscription } from "rxjs";

@Component({
  selector: "app-modal-search",
  templateUrl: "./modal-search.component.html",
  styleUrls: ["./modal-search.component.css"],
})
export class ModalSearchComponent implements OnInit {
  @ViewChild("searchModal")
  searchModal: ItModalComponent;

  listSizeLimit = 10;

  form: UntypedFormGroup;
  initialItemsList: any[];
  filtroSub: Subscription;

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  itemsList: any[];

  @Input()
  cancelButtonText: string = "Annulla";

  @Input()
  confirmButtonText: string = "Conferma";

  @Output()
  onConfirm = new EventEmitter<any>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      selection: [""],
      filtro: [""],
    });

    let filtroControl = this.form.get("filtro");
    this.filtroSub = filtroControl.valueChanges.subscribe(() => {
      if (!this.initialItemsList || this.initialItemsList.length === 0)
        this.initialItemsList = this.itemsList ? [...this.itemsList] : [];
      if (filtroControl.value) {
        this.itemsList = [
          ...this.initialItemsList.filter((item) =>
            item.text.toUpperCase().includes(filtroControl.value.toUpperCase())
          ),
        ];
        if (
          this.form.value.selection &&
          !this.itemsList.some(
            (i) => i.value !== "" && i.value == this.form.value.selection
          )
        ) {
          this.form.patchValue({
            selection: "",
          });
        }
      } else {
        this.itemsList = [...this.initialItemsList];
      }
    });
  }

  confirm() {
    if (this.form.valid) this.onConfirm.emit(this.form.value.selection);
  }

  showModal() {
    this.form.patchValue({ selection: "", filtro: "" });
    this.searchModal.show();
  }

  selezionaRiga(item) {
    this.form.patchValue({
      selection: item.value,
    });
  }
}
