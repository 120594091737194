import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { EMPTY, throwError } from "rxjs";
import { UtilityService } from "./../../utility/utility.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  timerSession;
  tokenRefreshCurrentAttempt = 0;
  tokenRefreshMaxAttempts = 4;
  sessionExpired: boolean;
  refreshTokenFlag: boolean;
  isRefreshingToken = false;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private utility: UtilityService
  ) {}

  public getToken(code) {
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const body = `grant_type=authorization_code&code=${code}&responseType=code&client_id=${environment.client_id}&scope=profile&redirect_uri=${environment.redirect_uri}`;
    return this.httpClient
      .post(environment.tokenUrl, body, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  public getRefreshToken() {
    const refresh_token = sessionStorage.getItem("refresh_token");
    if (!refresh_token) return EMPTY;
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    this.isRefreshingToken = true;
    const body = `grant_type=refresh_token&client_id=${environment.client_id}&refresh_token=${refresh_token}`;
    return this.httpClient
      .post(environment.tokenUrl, body, { headers: headers })
      .pipe(
        tap(() => (this.isRefreshingToken = false)),
        catchError(this.handleError)
      );
  }

  public getAuthCode() {
    let url = `${environment.authUrl}?response_type=code&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}&scope=profile`;
    window.location.href = url;
  }

  public logoutUrl() {
    return `${environment.logoutUrl}?redirect_uri=${environment.redirect_uri}`;
  }

  public initAuthProcess() {
    let code = this.getParameterByName("code", window.location.href);
    if (code != null) {
      if (sessionStorage.getItem("access_token") === null) {
        this.getToken(code).subscribe((data) => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes);
          this.router.navigate(["/home"]);
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
          this.setTimerToken();
        });
      }
    }
    // this.fakeLogin();
  }

  fakeLogin() {
    const jwt = `{
      "access_token": "eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly9zYW1wbGVzLmF1dGgwLmNvbS8ifQ..KNxZCVnDDjldhvys.TjJ6ZfA75McE8-_bfJU1YRCIN99ylFJwk4gy1-tcI-DqfM_IWXot3Xj00Qrr0auTJUT0qtEQr4eYqSDyELYFBRh0xMR_7BKHEsonxaR1VjZZZfmDxS5u2DiJzc0h7iGjrmLVNs0jUKL9zVbQvbEshk9mCpyWVSUxPb7nVtTOmcDwUbzfY6klHGJUkNfCr4Wx96RLxe-f6Tzy0PNay6MTAgDIafgyxMKnPdLa-uTyexzcrrHtT9mMZwVRgC1nB93DSKbr5nXl.Q8ELCTyfqOfCRD-MiYmh1w",
      "refresh_token": "eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIiwiaXNzIjoiaHR0cHM6Ly9zYW1wbGVzLmF1dGgwLmNvbS8ifQ..KNxZCVnDDjldhvys.TjJ6ZfA75McE8-_bfJU1YRCIN99ylFJwk4gy1-tcI-DqfM_IWXot3Xj00Qrr0auTJUT0qtEQr4eYqSDyELYFBRh0xMR_7BKHEsonxaR1VjZZZfmDxS5u2DiJzc0h7iGjrmLVNs0jUKL9zVbQvbEshk9mCpyWVSUxPb7nVtTOmcDwUbzfY6klHGJUkNfCr4Wx96RLxe-f6Tzy0PNay6MTAgDIafgyxMKnPdLa-uTyexzcrrHtT9mMZwVRgC1nB93DSKbr5nXl.Q8ELCTyfqOfCRD-MiYmh1w",
      "scope": "profile rtrole",
      "expires_in": 86400,
      "token_type": "Bearer"
    }`;

    this.utility.storeToken(JSON.parse(jwt));
    this.router.navigate(["/home"]);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.setTimerToken();
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  public decodeToken(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return jsonPayload;
  }

  public isTokenExpired(token) {
    var stringa = JSON.parse(this.decodeToken(token));
    var expiryDate = new Date(stringa.exp * 1000);
    var currentDate = new Date();
    return currentDate > expiryDate;
  }

  public setTimerToken() {
    clearInterval(this.timerSession);
    moment.locale("it");
    let self = this;
    let minutiSessione = environment.tokenSessionTime; // - environment.refresh_tokenTime;
    let minutiToken = environment.access_tokenTime;
    this.tokenRefreshMaxAttempts = Math.floor(minutiSessione / minutiToken);

    this.tokenRefreshCurrentAttempt = 0;
    this.timerSession = setInterval(function () {
      self.tokenRefreshCurrentAttempt += 1;

      self.getRefreshToken().subscribe((data) => {
        let jsonRes = JSON.parse(JSON.stringify(data));
        self.utility.storeToken(jsonRes);
      });

      if (self.tokenRefreshCurrentAttempt == self.tokenRefreshMaxAttempts) {
        clearInterval(self.timerSession);
      }
    }, this.convertToMillisecond(minutiToken));
  }

  public restartTokenRefreshCurrentAttempt() {
    if (this.tokenRefreshCurrentAttempt == this.tokenRefreshMaxAttempts) {
      setTimeout(() => {
        this.getRefreshToken().subscribe((data) => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes);
        });
        this.setTimerToken();
      }, 2000);
    }
    this.tokenRefreshCurrentAttempt = 0;
  }

  public handleToken() {
    if (sessionStorage.getItem("access_token")) {
      let access_token = sessionStorage.getItem("access_token");
      let refresh_token = sessionStorage.getItem("refresh_token");

      if (this.isTokenExpired(refresh_token) && !this.sessionExpired) {
        this.sessionExpired = true;
        this.router.navigate(["/sessionExpired"]);
      } else if (
        this.isTokenExpired(access_token) &&
        !this.isTokenExpired(refresh_token) &&
        !this.refreshTokenFlag
      ) {
        this.getRefreshToken().subscribe((data) => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes);
          this.refreshTokenFlag = false;
        });
        this.refreshTokenFlag = true;
      } else {
        this.setTimerToken();
      }
    }
  }

  convertToMillisecond(minutes) {
    var milliseconds = minutes * 60000;
    return milliseconds;
  }
}
