import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RicercaUtentiAamService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/utente-aam`);
  }

  search(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput,
      // page: jsonInput.pageInfo.page,
      // size: jsonInput.pageInfo.maxResults,
      pageable: {
        page: jsonInput.pageInfo?.page ?? 0,
        size: jsonInput.pageInfo?.maxResults ?? environment.itemsPerPage,
      },
      pageInfo: null,      
    };
    if (jsonInput.sortInfo) {
      jsonParams = {
        ...jsonParams,
        // sort: jsonInput.sortInfo.sortProperty,
        // sortDirection: jsonInput.sortInfo.sortDirection,
        sortable: {
          sortList: [
            {
              sortProperty: jsonInput.sortInfo.sortProperty,
              sortDirection: jsonInput.sortInfo.sortDirection,
            },
          ],
        },
        sortInfo: null,        
      };
    }
    this.spinner.show();

    return super.post('ricerca', jsonParams).then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  export(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput,
    };
    this.spinner.show();

    return super.downloadFilePost('export', jsonParams, 'export-utenti-aam.xlsx').then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }    
}
