import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { AlertService } from "../utility/alert-service";
import { AppInjector } from "../helpers/app-injector";
import { UserService } from "./user-service";
import { pipe } from "rxjs";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class StrutturaOrganizzativaService extends BaseService {
  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(
      http,
      `${environment.apiPath}/${environment.apiVersion}/struttura-organizzativa`
    );
  }

  getAll(showSpinner = false): Promise<any> {
    if (showSpinner) this.spinner.show();
    return super.get("elenco").then((result) => {
      if (showSpinner) this.spinner.hide();
      return result;
    });
  }

  getOrg(idOrg: number): Promise<any> {
    this.spinner.show();
    return super.get(`${idOrg}`).then((result) => {
      this.spinner.hide();
      return result;
    });
  }

  insert(body: any): Promise<any> {
    this.spinner.show();
    return super.post("", body).finally(() => {
      this.spinner.hide();
    });
  }

  update(idOrg: number, body: any, hideSpinnerOnSuccess = true): Promise<any> {
    this.spinner.show();
    return super.put(`${idOrg}`, body).finally(() => {
      if (hideSpinnerOnSuccess) this.spinner.hide();
    });
  }

  search(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput.criteri,
      page: jsonInput.pageInfo.page,
      size: jsonInput.pageInfo.maxResults,
    };
    if (jsonInput.sortInfo) {
      jsonParams = {
        ...jsonParams,
        sort: jsonInput.sortInfo.sortProperty,
        sortDirection: jsonInput.sortInfo.sortDirection,
      };
    }
    this.spinner.show();

    return super
      .get("ricerca", { params: jsonParams })
      .then((result) => {
        this.spinner.hide();
        return result;
      })
      .catch((error) => {
        this.spinner.hide();
        throw error;
      });
  }


  export(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput,
    };
    this.spinner.show();

    return super.downloadFile('export', 'export-strutture-organizzative.xlsx').then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }    
}
