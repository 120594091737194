<app-container>
  <app-dati-struttura-organizzativa
    [(form)]="form"
    [pageStatus]="'NEW'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Nuova Struttura Organizzativa'"
    (onSubmit)="onSave()"
  >
  </app-dati-struttura-organizzativa>

  <app-modal-confirm
    #confirmModal
    title="Nuova Struttura Organizzativa"
    description="Vuoi confermare la creazione della struttura organizzativa?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
