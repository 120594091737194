<app-container>
  <app-dati-utente-asset
    [(form)]="form"
    [pageStatus]="'MULTIPLE-INSERT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Aggiungi Attributi Utenti'"
    [formDescription]="
      'Definizione nuovi attributi o aggiornamento attributi esistenti per gli utenti selezionati (#numeroUtenti)'
    "
    (onSubmit)="onSave()"
  >
  </app-dati-utente-asset>

  <app-modal-confirm
    #confirmModal
    title="Aggiungi Attributi Utenti"
    description="Vuoi confermare l'inserimento degli attributi per gli utenti selezionati?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
