import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppInjector } from "src/app/helpers/app-injector";
import { FormCommons } from "src/app/utility/commons";
import {
  SortableHeaderDirective,
  SortEvent,
} from "src/app/directive/sortable-header.directive";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import { Subscription } from "rxjs";
import { UserService } from "src/app/service/user-service";
import { SelectControlOption } from "design-angular-kit/public_api";
import { Router } from "@angular/router";
import { AssetService } from "src/app/service/asset-service";
import { RicercaUtentiAssetService } from "src/app/service/ricerca-utenti-asset-service";

@Component({
  selector: "app-ricerca-utenti-asset",
  templateUrl: "./ricerca-utenti-asset.component.html",
  styleUrls: ["./ricerca-utenti-asset.component.css"],
})
export class RicercaUtentiAssetComponent
  extends SearchBaseComponent
  implements OnInit
{
  formCommons = AppInjector.get(FormCommons);
  form: UntypedFormGroup;

  pageStatus: string = "SEARCH";
  formTitle: string = "";
  shouldValidate: boolean;

  attributiSelectOptions: Array<SelectControlOption> = [
    { value: "", text: "" },
  ];

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  aziende: any[] = [];
  ricercaUtentiCriteri: any;
  userSub: Subscription;
  assetSub: Subscription;
  idAsset: number;
  idOrg: number;

  @Input()
  ricercaUtentiList: any[] = undefined;
  @Input()
  selectedUtenti: any[] = [];

  @Output()
  onSearch = new EventEmitter<any>();

  @Output()
  onSelect = new EventEmitter<any>();

  @Output()
  onSelectAll = new EventEmitter<any>();

  @Output()
  onDownload = new EventEmitter<any>();

  @Output()
  onDeleteUtente = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private assetService: AssetService,
    private userService: UserService,
    private ricercaUtentiAssetService: RicercaUtentiAssetService
  ) {
    super();
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      codiceFiscale: null,
      cognome: null,
      nome: null,
      attributo: [""],
      valore: null,
      soloUtentiValidi: [false],
    });
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);

      this.assetSub = this.assetService.currentAsset$.subscribe((asset) => {
        asset?.attributi
          ?.sort((a, b) => {
            const ca = a.chiave.toUpperCase();
            const cb = b.chiave.toUpperCase();
            if (ca < cb) return -1;
            if (ca > cb) return 1;
            return 0;
          })
          .forEach((element) => {
            this.attributiSelectOptions.push({
              value: element.chiave,
              text: element.chiave,
            });
          });
      });
      if (history.state.searchOnLoad) {
        let criteriRicerca = {
          ...history.state.ricercaUtentiCriteri,
          selectedUtenti: history.state.selectedUtenti ?? [],
        };
        criteriRicerca = {
          ...criteriRicerca,
          idAsset: this.idAsset,
          idStrutturaOrganizzativa: this.idOrg,
          soloUtentiValidi: criteriRicerca.soloUtentiValidi ?? false,
          sortInfo:
            criteriRicerca.sortInfo ?? this.setSortInfo("codiceFiscale", "ASC"),
          pageInfo: criteriRicerca.pageInfo ?? this.initializePageInfo(),
        };
        this.form.patchValue(criteriRicerca);
        if (
          criteriRicerca.chiaveValoreAttributoList &&
          criteriRicerca.chiaveValoreAttributoList.length > 0
        ) {
          this.form.patchValue({
            attributo: criteriRicerca.chiaveValoreAttributoList[0].chiave,
            valore: criteriRicerca.chiaveValoreAttributoList[0].valore,
          });
        }
        this.onSearch.emit(criteriRicerca);
        this.ricercaUtentiCriteri = criteriRicerca;
      }
    });
  }

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.assetSub && this.assetSub.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  getPage(page: any) {
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  onSelectUtente($event: any, codiceFiscale: string) {
    this.onSelect.emit({
      checked: $event.srcElement.checked,
      value: codiceFiscale,
    });
  }

  onSelectAllUtenti($event: any, codiceFiscale: string) {
    this.onSelectAll.emit({
      checked: $event.srcElement.checked,
    });
  }
  isSelectAllChecked(ricercaUtentiList) {
    return ricercaUtentiList.every((u) =>
      this.selectedUtenti.includes(u.codiceFiscale)
    );
  }

  importaUtenti() {
    this.router.navigate(["/importUtentiAsset"], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
        selectedUtenti: this.selectedUtenti,
      },
    });
  }

  nuovoUtente() {
    this.router.navigate(["/inserimentoUtenteAsset"], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
        selectedUtenti: this.selectedUtenti,
      },
    });
  }

  modificaUtente(utente) {
    this.router.navigate(["/modificaUtenteAsset", utente.idUtente], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
        selectedUtenti: this.selectedUtenti,
      },
    });
  }

  nuoviAttributiUtenti() {
    this.router.navigate(["/inserimentoAttributiUtentiAsset"], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
        selectedUtenti: this.selectedUtenti,
        numeroUtentiTotali: this.ricercaUtentiList?.length ?? 0,
      },
    });
  }

  eliminaUtente(utente: any) {
    this.onDeleteUtente.emit({
      ...utente,
      idAsset: this.idAsset,
      idOrg: this.idOrg,
    });
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaUtentiCriteri = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: !this.idOrg ? null : this.idOrg,
      sortInfo: this.setSortInfo("codiceFiscale", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.value.codiceFiscale)
      this.ricercaUtentiCriteri.codiceFiscaleList = [
        this.form.value.codiceFiscale,
      ];
    if (this.form.value.cognome)
      this.ricercaUtentiCriteri.cognome = this.form.value.cognome;
    if (this.form.value.nome)
      this.ricercaUtentiCriteri.nome = this.form.value.nome;
    if (this.form.value.attributo || this.form.value.valore) {
      this.ricercaUtentiCriteri.chiaveAttributo = this.form.value.attributo
        ? this.form.value.attributo
        : "";
      this.ricercaUtentiCriteri.valoreAttributo = this.form.value.valore
        ? this.form.value.valore
        : "";
    }
    this.ricercaUtentiCriteri.soloUtentiValidi =
      this.form.value.soloUtentiValidi ?? false;
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  esportaUtenti() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaUtentiCriteri = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
    };
    if (this.form.value.codiceFiscale)
      this.ricercaUtentiCriteri.codiceFiscale = [this.form.value.codiceFiscale];
    if (this.form.value.cognome)
      this.ricercaUtentiCriteri.cognome = this.form.value.cognome;
    if (this.form.value.nome)
      this.ricercaUtentiCriteri.nome = this.form.value.nome;
    if (this.form.value.attributo || this.form.value.valore) {
      this.ricercaUtentiCriteri.chiaveValoreAttributoList = [
        {
          chiave: this.form.value.attributo ? this.form.value.attributo : "",
          valore: this.form.value.valore ? this.form.value.valore : "",
        },
      ];
    }
    if (this.form.value.soloUtentiValidi) {
      this.ricercaUtentiCriteri.soloUtentiValidi =
        this.form.value.soloUtentiValidi;
    }
    if (this.form.invalid) {
      return;
    }
    this.onDownload.emit(this.ricercaUtentiCriteri);
  }
}
