import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function CodiceFiscaleValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const pattern =
      /^[A-Z]{6}[0-9]{2}[A-Z]{1}[0-9]{2}[A-Z]{1}[0-9]{3}[A-Z]{1}$/gi;

    if (!value || !pattern) {
      return null;
    }
    return !new RegExp(pattern).test(value) ? { codicefiscale: true } : null;
  };
}
