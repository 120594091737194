import { Component } from "@angular/core";
import { TokenService } from "./service/token/token.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "AAM";

  constructor(private tokenService: TokenService) {}

  ngOnInit() {
    this.tokenService.initAuthProcess();
  }
}
