import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AssetService } from "src/app/service/asset-service";
import { TokenService } from "src/app/service/token/token.service";
import { UserService } from "src/app/service/user-service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  isAdmin: boolean = false;
  isAdminOrg: boolean = false;
  isUser: boolean = false;

  constructor(
    private userService: UserService,
    private assetService: AssetService,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.userService
      .getCurrentUser()
      .then((user) => {
        this.isAdmin = this.userService.isAdmin();
        this.isAdminOrg = this.userService.isAdminOrg();
        this.isUser = this.userService.isUser();

        this.assetService.setCurrentAsset(user.assetCorrente?.idAsset);
      })
      .catch((error) => {
        if ([403, 500, 504].includes(error.status)) {
          this.router.navigate(["/utenteNonAbilitato"]);
        }
        if (error.status == 401) {
          this.router.navigate(["/login"]);
        }
      });
  }
}
