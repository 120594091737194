import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "src/app/service/token/token.service";
import { UserService } from "src/app/service/user-service";
import { CookieService } from 'ng2-cookies';

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"],
})
export class LoginPageComponent implements OnInit {
  cookieModaleConstant = "cookieModale";
  modalCookie:boolean = false;

  constructor(
    private router: Router,
    private authService: TokenService,
    private userService: UserService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.userService.deleteUser();
    let cookie = this.cookieService.get(this.cookieModaleConstant);
    if(!cookie){
      this.modalCookie = true;
    }
  }

  loginArpa(): void {
    if (sessionStorage.getItem("access_token")) {
      this.router.navigate(["/home"]);
    } else {
      this.authService.getAuthCode();
    }
  }

  continueEnd(){
    this.modalCookie = !this.modalCookie;
    this.cookieService.set(this.cookieModaleConstant,'true');
  }
}
