<h3 class="mb-4">{{ formTitle }}</h3>
<div class="row mb-4">
  <div class="col">
    <p *ngIf="!showDatiUtente">
      {{ formDescription.replace("#numeroUtenti", numeroUtenti) }}
    </p>
  </div>
</div>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionDatiUtente">
    <div class="accordion-item" *ngIf="showDatiUtente">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati utente
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0">
              <it-input
                [readonly]="form.value.id ? 'true' : null"
                label="Codice Fiscale *"
                formControlName="codiceFiscale"
                #codiceFiscale
              >
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="codiceFiscale.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="codiceFiscale.hasError('codicefiscale')">
                    Codice Fiscale non valido
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Cognome *" formControlName="cognome" #cognome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="cognome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="cognome.hasError('nome')">
                    Presenti caratteri non validi
                  </div>
                </ng-container>
              </it-input>
            </div>
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Nome *" formControlName="nome" #nome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="nome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="nome.hasError('nome')">
                    Presenti caratteri non validi
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Attributi
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <ng-container formArrayName="listaAttributi">
            <div
              class="row attributi-header-row"
              *ngIf="listaAttributi && listaAttributi.controls.length > 0"
            >
              <div class="col-md-11">
                <div class="row">
                  <div class="form-group col-md-3">
                    <label>Attributo *</label>
                  </div>
                  <div class="form-group col-md-3 form-group-b-0 riga-valore">
                    <label>Valore *</label>
                  </div>
                  <div class="form-group col-md-3 form-group-b-0 riga-valore">
                    <label>Data Inizio</label>
                  </div>
                  <div class="form-group col-md-3 form-group-b-0 riga-valore">
                    <label>Data Fine</label>
                  </div>
                </div>
              </div>
            </div>

            <ng-container
              *ngFor="let attributoGroup of listaAttributi.controls; index as i"
            >
              <ng-container [formGroup]="attributoGroup">
                <div
                  class="row mt-4"
                  [ngClass]="{
                    'attribute-row-deleted': isAttributoDeleted(attributoGroup)
                  }"
                >
                  <div class="col-md-11">
                    <div class="row">
                      <div class="form-group col-md-3 riga-valore">
                        <it-select
                          formControlName="attributo"
                          [options]="attributiSelectOptions"
                        >
                          <ng-container error>
                            <div
                              *ngIf="
                                attributoGroup.controls.valore.hasError(
                                  'required'
                                )
                              "
                            >
                              Il campo è obbligatorio
                            </div>
                          </ng-container>
                        </it-select>
                      </div>
                      <div
                        class="form-group col-md-3 form-group-b-0 riga-valore"
                      >
                        <ng-container
                          *ngIf="
                            attributoGroup.value.listaValori &&
                            attributoGroup.value.listaValori.length > 0
                          "
                        >
                          <it-select
                            formControlName="valore"
                            [options]="attributoGroup.value.listaValori"
                          >
                            <ng-container error>
                              <div
                                *ngIf="
                                  attributoGroup.controls.valore.hasError(
                                    'required'
                                  )
                                "
                              >
                                Il campo è obbligatorio
                              </div>
                            </ng-container>
                          </it-select>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (!attributoGroup.value.listaValori ||
                              attributoGroup.value.listaValori.length == 0) &&
                            !attributoGroup.value.isDate
                          "
                        >
                          <it-input formControlName="valore">
                            <ng-container error>
                              <div
                                *ngIf="
                                  attributoGroup.controls.valore.hasError(
                                    'required'
                                  )
                                "
                              >
                                Il campo è obbligatorio
                              </div>
                              <div
                                *ngIf="
                                  attributoGroup.controls.valore.hasError(
                                    'regexp'
                                  )
                                "
                              >
                                Valore non valido
                              </div>
                            </ng-container>
                          </it-input>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (!attributoGroup.value.listaValori ||
                              attributoGroup.value.listaValori.length == 0) &&
                            attributoGroup.value.isDate
                          "
                        >
                          <it-input type="date" formControlName="valore">
                            <ng-container error>
                              <div
                                *ngIf="
                                  attributoGroup.controls.valore.hasError(
                                    'required'
                                  )
                                "
                              >
                                Il campo è obbligatorio
                              </div>
                            </ng-container>
                          </it-input>
                        </ng-container>
                      </div>
                      <div
                        class="form-group col-md-3 form-group-b-0 riga-valore"
                      >
                        <it-input type="date" formControlName="dataInizio">
                          <ng-container error> </ng-container>
                        </it-input>
                      </div>
                      <div
                        class="form-group col-md-3 form-group-b-0 riga-valore"
                      >
                        <it-input type="date" formControlName="dataFine">
                          <ng-container error> </ng-container>
                        </it-input>
                      </div>

                      <!-- <hr
                        *ngIf="attributoGroup.value.toBeDeleted"
                        class="cancel-line"
                      /> -->
                    </div>
                  </div>
                  <div class="form-group col-md-1">
                    <it-icon
                      *ngIf="
                        attributoGroup.value.canBeDeleted &&
                        !attributoGroup.value.toBeDeleted
                      "
                      name="delete"
                      (click)="rimuoviAttributo(i)"
                      itTooltip="Rimuovi attributo"
                      tooltipPlacement="bottom"
                      class="link-pointer"
                    ></it-icon>
                    <it-icon
                      *ngIf="
                        attributoGroup.value.canBeDeleted &&
                        attributoGroup.value.toBeDeleted
                      "
                      name="close-big"
                      (click)="annullaRimuoviAttributo(i)"
                      itTooltip="Annulla"
                      tooltipPlacement="bottom"
                      class="link-pointer"
                    ></it-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row">
              <div class="form-group col-md-6">
                <a class="link-pointer" (click)="aggiungiAttributo()"
                  >Aggiungi attributo</a
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaRicercaUtenti()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        Ricerca Utenti
      </button>
    </div>
    <div class="col-auto">
      <button type="submit" [disabled]="form.invalid" class="btn btn-primary">
        Salva
      </button>
    </div>
  </div>
</form>
