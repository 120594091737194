export const PROFILO_AMMINISTRATORE_REGIONALE: string =
  "amministratore-regionale";
export const PROFILO_AMMINISTRATORE_REGIONALE_ID: number = 1;
export const PROFILO_AMMINISTRATORE_REGIONALE_DESC: string =
  "Amministratore Regionale";

export const PROFILO_AMMINISTRATORE_STRUTTURA: string =
  "amministratore-struttura";
export const PROFILO_AMMINISTRATORE_STRUTTURA_ID: number = 2;
export const PROFILO_AMMINISTRATORE_STRUTTURA_DESC: string =
  "Amministratore Struttura";

export const PROFILO_AMMINISTRATORE_ASSET: string = "amministratore-asset";
export const PROFILO_AMMINISTRATORE_ASSET_ID: number = 3;
export const PROFILO_AMMINISTRATORE_ASSET_DESC: string = "Amministratore Asset";

export const DescrizioneRuolo = (ruolo: string): string =>
  ruolo === PROFILO_AMMINISTRATORE_REGIONALE
    ? PROFILO_AMMINISTRATORE_REGIONALE_DESC
    : ruolo === PROFILO_AMMINISTRATORE_STRUTTURA
    ? PROFILO_AMMINISTRATORE_STRUTTURA_DESC
    : ruolo === PROFILO_AMMINISTRATORE_ASSET
    ? PROFILO_AMMINISTRATORE_ASSET_DESC
    : "";

export const IdRuolo = (ruolo: string): number =>
  ruolo === PROFILO_AMMINISTRATORE_REGIONALE
    ? PROFILO_AMMINISTRATORE_REGIONALE_ID
    : ruolo === PROFILO_AMMINISTRATORE_STRUTTURA
    ? PROFILO_AMMINISTRATORE_STRUTTURA_ID
    : ruolo === PROFILO_AMMINISTRATORE_ASSET
    ? PROFILO_AMMINISTRATORE_ASSET_ID
    : null;