export const TIPO_ATTRIBUTO_TESTO_ID: number = 1;
export const TIPO_ATTRIBUTO_TESTO_DESC: string = "Testo";

export const TIPO_ATTRIBUTO_NUMERO_ID: number = 2;
export const TIPO_ATTRIBUTO_NUMERO_DESC: string = "Numero";

export const TIPO_ATTRIBUTO_DATA_ID: number = 3;
export const TIPO_ATTRIBUTO_DATA_DESC: string = "Data";

export const TIPO_ATTRIBUTO_BOOL_ID: number = 4;
export const TIPO_ATTRIBUTO_BOOL_DESC: string = "Booleano";
