import { Component, OnInit } from "@angular/core";
import { TokenService } from "src/app/service/token/token.service";
import { UserService } from "src/app/service/user-service";

@Component({
  selector: "app-logout-page",
  templateUrl: "./logout-page.component.html",
  styleUrls: ["./logout-page.component.css"],
})
export class LogoutPageComponent implements OnInit {
  constructor(
    private authService: TokenService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.deleteUser();
    window.location.href = this.authService.logoutUrl();
  }
}
