import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RicercaAssetService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/asset`);
  }

  search(jsonInput: any): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput.criteri,
      page: jsonInput.pageInfo.page,
      size: jsonInput.pageInfo.maxResults,
    };
    if (jsonInput.sortInfo) {
      jsonParams = {
        ...jsonParams,
        sort: jsonInput.sortInfo.sortProperty,
        sortDirection: jsonInput.sortInfo.sortDirection,
      };
    }
    this.spinner.show();

    return super.get('ricerca', {params: jsonParams}).then(result => {
      this.spinner.hide();
      return result;
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

}
