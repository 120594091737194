import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { SelectControlOption } from "design-angular-kit/public_api";
import { AssetService } from "src/app/service/asset-service";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import * as _ from "lodash";

@Component({
  selector: "app-dati-utente",
  templateUrl: "./dati-utente.component.html",
  styleUrls: ["./dati-utente.component.css"],
})
export class DatiUtenteComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  assetSelectOptions: SelectControlOption[] = [
    { value: "", text: "Seleziona un asset" },
  ];

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  isAdmin: boolean;
  isAdminOrg: boolean;
  listaAssetForSelect: any[];
  listaAssetUser: any[];
  listaOrg: any[];
  codiceFiscaleSub: Subscription;
  cognomeSub: Subscription;
  nomeSub: Subscription;
  userSub: Subscription;
  isAmministratoreRegionaleSub: Subscription;
  isAmministratoreStrutturaSub: Subscription;
  isAmministratoreAssetSub: Subscription;
  assetSelectSubs: any[] = [];
  listaAssetLoadedSub: Subscription;
  ricercaUtentiCriteri: any;

  constructor(
    private assetService: AssetService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  get listaAsset() {
    return this.form.controls["listaAsset"] as FormArray;
  }

  aggiungiAsset() {
    const nuovoAssetGroup = this.fb.group({
      asset: ["", Validators.required],
      org: [""],
      listaOrg: [],
    });
    this.listaAsset.push(nuovoAssetGroup);
    this.popolaListaOrgAsset(nuovoAssetGroup);
  }

  popolaListaOrgAsset(nuovoAssetGroup: any, index?: number) {
    const assetSelectControl = nuovoAssetGroup.get("asset");
    const assetSelectSub = assetSelectControl.valueChanges.subscribe(() => {
      const listaOrg = this.getListaOrgAsset(assetSelectControl.value);
      nuovoAssetGroup.patchValue({
        listaOrg,
      });
    });
    this.assetSelectSubs.push({
      index: index ?? this.listaAsset.length - 1,
      sub: assetSelectSub,
    });
  }

  rimuoviAsset(assetIndex: number) {
    this.assetSelectSubs.find((a) => a.index == assetIndex).sub.unsubscribe();
    this.listaAsset.removeAt(assetIndex);
  }

  getListaOrgAsset(idAsset): any[] {
    if (!idAsset) return [];
    if (this.isAdmin) {
      return [
        { value: "", text: "" },
        ...this.listaOrg.map((o) => ({
          value: o.id,
          text: o.descrizione,
        })),
      ];
    } else
      return [
        { value: "", text: "" },
        ...this.listaAssetUser
          .find((a) => a.asset == idAsset)
          ?.listaOrg.map((v) => ({
            value: v,
            text: this.listaOrg.find((o) => o.id == v)?.descrizione,
          }))
          .filter((v) => v.text),
      ];
  }

  ngOnInit(): void {
    if (this.pageStatus === "NEW") {
    }

    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.isAdmin = usr.isAdmin;
      this.isAdminOrg = usr.isAdminOrg;
      this.listaAssetUser = usr.listaAsset;

      this.assetService.getListaAsset().then((resultListaAsset) => {
        this.listaAssetForSelect = usr.isAdmin
          ? resultListaAsset
          : resultListaAsset.filter((element) =>
              usr.listaAsset?.map((a) => +a.asset).includes(element.id)
            );

        this.listaAssetForSelect.forEach((element) => {
          this.assetSelectOptions.push({
            value: element.id,
            text: element.nome,
          });
        });

        this.strutturaOrganizzativaService.getAll().then((resultListaOrg) => {
          this.listaOrg = _.sortBy(resultListaOrg, ["descrizione"]);

          this.listaAsset.controls.forEach((assetGroup: FormGroup, index) => {
            this.popolaListaOrgAsset(assetGroup, index);
            // reset the field value to trigger the valuechanges and populate the values list
            assetGroup.controls.asset.setValue(assetGroup.controls.asset.value);
          });
        });
      });

      if (history.state.ricercaUtentiCriteri) {
        this.ricercaUtentiCriteri = history.state.ricercaUtentiCriteri;
      }

      let codiceFiscaleControl = this.form.get("codiceFiscale");
      this.codiceFiscaleSub = codiceFiscaleControl.valueChanges.subscribe(
        () => {
          if (codiceFiscaleControl.value) {
            codiceFiscaleControl.patchValue(
              codiceFiscaleControl.value.toUpperCase(),
              {
                emitEvent: false,
              }
            );
          }
        }
      );
      let cognomeControl = this.form.get("cognome");
      this.cognomeSub = cognomeControl.valueChanges.subscribe(() => {
        if (cognomeControl.value) {
          cognomeControl.patchValue(cognomeControl.value.toUpperCase(), {
            emitEvent: false,
          });
        }
      });
      let nomeControl = this.form.get("nome");
      this.nomeSub = nomeControl.valueChanges.subscribe(() => {
        if (nomeControl.value) {
          nomeControl.patchValue(nomeControl.value.toUpperCase(), {
            emitEvent: false,
          });
        }
      });
      let isAmministratoreRegionaleControl = this.form.get(
        "isAmministratoreRegionale"
      );
      let isAmministratoreStrutturaControl = this.form.get(
        "isAmministratoreStruttura"
      );
      let isAmministratoreAssetControl = this.form.get("isAmministratoreAsset");
      this.isAmministratoreAssetSub =
        isAmministratoreAssetControl.valueChanges.subscribe(() => {
          if (isAmministratoreAssetControl.value) {
            isAmministratoreRegionaleControl.setValue(false);
            isAmministratoreStrutturaControl.setValue(false);
          }
        });
      this.isAmministratoreRegionaleSub =
        isAmministratoreRegionaleControl.valueChanges.subscribe(() => {
          if (isAmministratoreRegionaleControl.value) {
            isAmministratoreAssetControl.setValue(false);
            isAmministratoreStrutturaControl.setValue(false);
          }
        });
      this.isAmministratoreStrutturaSub =
        isAmministratoreStrutturaControl.valueChanges.subscribe(() => {
          if (isAmministratoreStrutturaControl.value) {
            isAmministratoreAssetControl.setValue(false);
            isAmministratoreRegionaleControl.setValue(false);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.codiceFiscaleSub && this.codiceFiscaleSub.unsubscribe();
    this.cognomeSub && this.cognomeSub.unsubscribe();
    this.nomeSub && this.nomeSub.unsubscribe();
    this.isAmministratoreAssetSub &&
      this.isAmministratoreAssetSub.unsubscribe();
    this.isAmministratoreRegionaleSub &&
      this.isAmministratoreRegionaleSub.unsubscribe();
    this.isAmministratoreStrutturaSub &&
      this.isAmministratoreStrutturaSub.unsubscribe();
    this.userSub && this.userSub.unsubscribe();
    this.listaAssetLoadedSub && this.listaAssetLoadedSub.unsubscribe();
    this.assetSelectSubs?.forEach((a) => {
      a.sub && a.sub.unsubscribe();
    });
  }

  tornaRicercaUtenti(): void {
    this.router.navigate(["/ricercaUtentiAam"], {
      state: {
        searchOnLoad: true,
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  save() {
    this.onSubmit.emit();
  }

  selezionaOrg(value, assetGroup) {
    assetGroup.patchValue({ org: value });
  }
}
