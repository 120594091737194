import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { UtenteAssetService } from "../../service/utente-asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subject, Subscription } from "rxjs";
import { DateUtils } from "src/app/utility/date-utils";
import { AssetService } from "src/app/service/asset-service";
import { TIPO_ATTRIBUTO_DATA_ID } from "src/app/tipologiche/tipo_attributo";
import { RegExpValidator } from "src/app/directive/regexp.validator";
import { NomeValidator } from "src/app/directive/nome.validator";
import { CodiceFiscaleValidator } from "src/app/directive/codice-fiscale.validator";

@Component({
  selector: "app-modifica-utente-asset",
  templateUrl: "./modifica-utente-asset.component.html",
  styleUrls: ["./modifica-utente-asset.component.css"],
})
export class ModificaUtenteAssetComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  idUtente: any;
  assetSub: Subscription;
  userSub: Subscription;

  listaAttributiSubject = new Subject<any>();

  get listaAttributi() {
    return this.form.controls["listaAttributi"] as FormArray;
  }

  constructor(
    private assetService: AssetService,
    private utenteAssetService: UtenteAssetService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    fc: FormCommons,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: null,
      codiceFiscale: ["", [Validators.required, CodiceFiscaleValidator()]],
      cognome: ["", [Validators.required, NomeValidator()]],
      nome: ["", [Validators.required, NomeValidator()]],
      listaAttributi: new FormArray([]),
    });

    this.route.params.subscribe((params: Params) => {
      this.idUtente = params["id"];

      this.userSub = this.userService.user.subscribe((usr) => {
        if (!usr) return;
        this.manageUser(usr);

        this.assetSub = this.assetService.currentAsset$.subscribe((asset) => {
          this.utenteAssetService
            .dettaglio(this.idUtente, this.idAsset, this.idOrg)
            .then((result) => {
              this.form.patchValue({
                id: this.idUtente,
                codiceFiscale: result?.codiceFiscale,
                cognome: result?.cognome,
                nome: result?.nome,
                idAsset: this.idAsset,
                oldCognome: result?.utenteDto?.cognome,
                oldNome: result?.utenteDto?.nome,
              });
              if (
                result?.valoriAttributoList &&
                result?.valoriAttributoList.length > 0
              ) {
                for (const valore of result?.valoriAttributoList?.map((v) => ({
                  id: v.id,
                  idAttributo: v.fkIdAttributo,
                  attributo: { value: v.fkIdAttributo, disabled: true },
                  valore: [
                    asset?.attributi?.find((a) => a.id == v.fkIdAttributo)
                      ?.fkIdTipoAttributo?.id == TIPO_ATTRIBUTO_DATA_ID
                      ? DateUtils.fromBeDate(v.valore, "/")
                      : v.valore,
                    [Validators.required, RegExpValidator()],
                    null,
                    "blur",
                  ],
                  dataInizio: v.dataInizio
                    ? DateUtils.fromBeDate(v.dataInizio)
                    : null,
                  dataFine: v.dataFine
                    ? DateUtils.fromBeDate(v.dataFine)
                    : null,
                  canBeDeleted: !(
                    asset?.attributi?.find((a) => a.id == v.fkIdAttributo)
                      ?.flagObbligatorio ?? false
                  ),
                  toBeDeleted: false,
                  isDate:
                    asset?.attributi?.find((a) => a.id == v.fkIdAttributo)
                      ?.fkIdTipoAttributo?.id == TIPO_ATTRIBUTO_DATA_ID,
                  listaValori: [],
                  regexp: null,
                }))) {
                  this.listaAttributi.push(this.fb.group(valore));
                }
                this.listaAttributiSubject.next(this.listaAttributi);
              }
            });
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.assetSub && this.assetSub.unsubscribe();
    this.listaAttributiSubject && this.listaAttributiSubject.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: !this.idOrg ? null : this.idOrg,
      listaUtenti: [
        {
          codiceFiscale: this.form.value.codiceFiscale,
          cognome: this.form.value.cognome,
          nome: this.form.value.nome,
        },
      ],
      listaValoriAttributoDaAssociare: this.form.value.listaAttributi
        .filter((a) => !a.id)
        .map((a) => ({
          fkIdAttributo: a.attributo,
          fkIdStrutturaOrganizzativa: !this.idOrg ? null : { id: this.idOrg },
          valore: a.isDate ? DateUtils.toBeDate(a.valore, "/") : a.valore,
          dataInizio: a.dataInizio ? DateUtils.toBeDate(a.dataInizio) : null,
          dataFine: a.dataFine ? DateUtils.toBeDate(a.dataFine) : null,
        })),
      valoriAttributoModifica: this.form.value.listaAttributi
        .filter((a) => a.id)
        .map((a) => ({
          id: a.id,
          fkIdAttributo: a.idAttributo,
          valore: a.isDate ? DateUtils.toBeDate(a.valore, "/") : a.valore,
          dataInizio: a.dataInizio ? DateUtils.toBeDate(a.dataInizio) : null,
          dataFine: a.dataFine ? DateUtils.toBeDate(a.dataFine) : null,
        })),
      // valoriAttributoCancellazione: this.form.value.listaAttributi
      //   .filter((a) => a.toBeDeleted)
      //   .map((a) => a.id),
    };
    this.utenteAssetService
      .associaUtentiValoriAttributo(body, true)
      .then(() => {
        if (
          this.form.value.oldCognome !== body.listaUtenti[0].cognome ||
          this.form.value.oldNome !== body.listaUtenti[0].nome
        ) {
          this.utenteAssetService.aggiornaUtente(this.form.value.id, {
            ...body.listaUtenti[0],
            id: this.form.value.id,
          });
        }

        // this.form.reset();
        this.alertService.addDefaultSuccessMessage();
      });
  }
}
