import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service'
import {AppInjector} from '../helpers/app-injector';
import {UserService} from './user-service';
import { pipe } from 'rxjs';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AttributoService extends BaseService {

  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/attributo`);
  }

  getAttributo(idAttributo: number): Promise<any> {
    this.spinner.show();
    return super.get(`${idAttributo}`).then((result) => {
      this.spinner.hide();
      return result;
    });
  }

  deleteAttributo(idAttributo: number): Promise<any> {
    this.spinner.show();
    return super.delete(`${idAttributo}`).catch(() => {
      this.spinner.hide();
    })
  }
  
  insert(body: any): Promise<any> {
    this.spinner.show();
    return super.post('',body).finally(() => {
      this.spinner.hide();
    })
  }
  
  update(idAttributo: number, body: any): Promise<any> {
    this.spinner.show();
    return super.put(`${idAttributo}`,body).finally(() => {
      this.spinner.hide();
    })
  }

}
