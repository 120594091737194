import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { AlertService } from "../utility/alert-service";
import { UtilityService } from "../utility/utility.service";
import { AppInjector } from "../helpers/app-injector";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import {
  PROFILO_AMMINISTRATORE_REGIONALE,
  PROFILO_AMMINISTRATORE_REGIONALE_DESC,
  PROFILO_AMMINISTRATORE_ASSET,
  PROFILO_AMMINISTRATORE_STRUTTURA,
  PROFILO_AMMINISTRATORE_STRUTTURA_DESC,
  PROFILO_AMMINISTRATORE_ASSET_DESC,
} from "../tipologiche/profili";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService {
  alertService = AppInjector.get(AlertService);
  utilityService = AppInjector.get(UtilityService);

  user: BehaviorSubject<any>;

  constructor(
    http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/utente-aam`);
    this.user = new BehaviorSubject<any>(this.getUserStored());
  }

  storeUser(user: any) {
    let currentStoredUser = this.getUserStored();
    user = {
      ...user.utenteDto,
      isAdmin: user.profilo === PROFILO_AMMINISTRATORE_REGIONALE,
      isAdminOrg: user.profilo === PROFILO_AMMINISTRATORE_STRUTTURA,
      isOperatore: user.profilo === PROFILO_AMMINISTRATORE_ASSET,
      listaAsset: user.asset?.map((a) => ({
        asset: a.split(";")[0],
        listaOrg: a.split(";").length > 1 ? a.split(";")[1].split(",") : [],
      })),
    };
    user.ruolo = user.isAdmin
      ? PROFILO_AMMINISTRATORE_REGIONALE_DESC
      : user.isAdminOrg
      ? PROFILO_AMMINISTRATORE_STRUTTURA_DESC
      : user.isOperatore
      ? PROFILO_AMMINISTRATORE_ASSET_DESC
      : "";
    if (
      currentStoredUser &&
      currentStoredUser.id == user.id &&
      currentStoredUser.assetCorrente
    ) {
      user.assetCorrente = currentStoredUser.assetCorrente;
    } else {
      user.assetCorrente = user.isOperatore
        ? {
            idAsset: user.listaAsset[0].asset,
            idOrg:
              user.listaAsset[0].listaOrg &&
              user.listaAsset[0].listaOrg.length > 0
                ? user.listaAsset[0].listaOrg[0]
                : null,
          }
        : {};
    }

    sessionStorage.setItem("usr", JSON.stringify(user));
    this.user.next(user);
    return user;
  }

  changeAssetCorrente(idAsset: any, idOrg: any) {
    let user = this.getUserStored();
    user.assetCorrente.idAsset = idAsset;
    user.assetCorrente.idOrg = idOrg;

    sessionStorage.setItem("usr", JSON.stringify(user));
    this.user.next(user);
  }
  getUserStored() {
    let usr = sessionStorage.getItem("usr");
    if (usr) {
      return JSON.parse(usr);
    }
    return {};
  }

  getIdUtente() {
    let usr = this.getUserStored();
    return usr.id;
  }

  getIdAnagrafica() {
    let usr = this.getUserStored();
    return usr.id_dati_anagrafici;
  }

  deleteUser() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("usr");
    this.user.next(null);
  }

  deleteUserAam(idUtente) {
    this.spinner.show();
    return super.delete(`${idUtente}`).catch(() => {
      this.spinner.hide();
    });
  }

  isAdmin() {
    let user = this.getUserStored();
    if (user && user.isAdmin) {
      return true;
    }
    return false;
  }

  isAdminOrg() {
    let user = this.getUserStored();
    if (user && user.isAdminOrg) {
      return true;
    }
    return false;
  }

  hasResetPassword() {
    let user = this.getUserStored();
    if (user && user.flag_reset === false) {
      return true;
    }
    return false;
  }

  isUser() {
    let user = this.getUserStored();
    if (user && user.isOperatore) {
      return true;
    }
    return false;
  }

  getAll(): Promise<any> {
    return super.get();
  }

  getCurrentUser(): Promise<any> {
    return super.get(`utenteCorrente`).then((result) => {
      return this.storeUser(result);
    });
  }

  get(username: string): Promise<any> {
    this.spinner.show();
    return super.get(`${username}`).then((result) => {
      this.spinner.hide();
      return result;
    });
  }

  save(body: any): Promise<any> {
    this.spinner.show();
    return super.post("", body).finally(() => {
      this.spinner.hide();
    });
  }

  changePassword(body: any): Promise<any> {
    this.spinner.show();
    return super.put("", body).then((result) => {
      this.spinner.hide();
    });
  }
}
