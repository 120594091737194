import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { ImportExcelService } from "../../service/import-excel-service";
import { Subscription } from "rxjs";
import { UploadFileListItem } from "design-angular-kit";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { UserBaseComponent } from "../user-base-component";
import { AssetService } from "src/app/service/asset-service";
import { Router } from "@angular/router";
import { ImportFileExcelComponent } from "src/app/component/import-file-excel/import-file-excel.component";
import { DateUtils } from "src/app/utility/date-utils";

@Component({
  selector: "app-import-utenti-asset-page",
  templateUrl: "./import-utenti-asset-page.component.html",
  styleUrls: ["./import-utenti-asset-page.component.css"],
})
export class ImportUtentiAssetPageComponent
  extends UserBaseComponent
  implements OnInit
{
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild(ImportFileExcelComponent) iec!: ImportFileExcelComponent;
  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  userSubscription: Subscription;
  uploadedFileList = new Array<UploadFileListItem>();
  selectedUtenti: any[] = [];
  ricercaUtentiCriteri: any[] = [];
  ricercaImportList: any[];

  constructor(
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private alertService: AlertService,
    assetService: AssetService,
    userService: UserService,
    router: Router,
    private importExcelService: ImportExcelService
  ) {
    super(assetService, userService, router);
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      file: null,
    });
    if (history.state.selectedUtenti) {
      this.selectedUtenti = history.state.selectedUtenti;
    }
    if (history.state.ricercaUtentiCriteri) {
      this.ricercaUtentiCriteri = history.state.ricercaUtentiCriteri;
    }
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  functionImport = this.importExcelService.importUtenti;

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  tornaRicercaUtenti(): void {
    this.router.navigate(["/ricercaUtentiAsset"], {
      state: {
        searchOnLoad: true,
        selectedUtenti: this.selectedUtenti,
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  ricercaImport(criteri: any, showSpinner: boolean = true): void {
    this.importExcelService
      .search(criteri, false, showSpinner)
      .then((result) => {
        this.ricercaImportList = result?.items.map((item) => ({
          ...item,
          dataRichiesta: item.dataRichiesta.replaceAll(/-/g, "/"),
          failed:
            item.dataCompletamento &&
            item.erroriElaborazione &&
            item.erroriElaborazione.length > 0,
          success: item.dataCompletamento && !item.erroriElaborazione,
          stato: item.dataCompletamento ? "Completato" : "In elaborazione",
        }));
        this.iec.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
        if (
          this.ricercaImportList.find(
            (i) =>
              !i.dataCompletamento &&
              DateUtils.isDateTimeInTheLastXMinutes(i.dataRichiesta, 30)
          )
        ) {
          setTimeout(() => this.ricercaImport(criteri, false), 15000);
        }
      });
  }
}
