import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AssetService } from "src/app/service/asset-service";
import { LoginService } from "src/app/service/login-service";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { UserService } from "src/app/service/user-service";
import {
  PROFILO_AMMINISTRATORE_REGIONALE,
  PROFILO_AMMINISTRATORE_ASSET,
} from "src/app/tipologiche/profili";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: any = undefined;
  userSubscription: Subscription;

  isAdmin: boolean = false;
  isAdminOrg: boolean = false;
  isUser: boolean = false;
  showChangeAsset: boolean = false;

  listaAsset: any[] = [];
  listaOrg: any[] = [];
  
  constructor(
    private router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private assetService: AssetService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.user.subscribe((usr) => {
      this.user = usr;
      if (!usr?.codiceFiscale) return;

      this.isAdmin = usr.isAdmin;
      this.isAdminOrg = usr.isAdminOrg;
      this.isUser = usr.isOperatore;

      this.assetService.getListaAsset().then((a) => {
        this.listaAsset = a;
      });
      this.strutturaOrganizzativaService.getAll().then((result) => {
        this.listaOrg = result;
      });

      if (this.isUser) {
        if (usr.listaAsset && usr.listaAsset.length > 1)
          this.showChangeAsset = true;
        else if (
          usr.listaAsset &&
          this.user.listaAsset.length == 1 &&
          this.user.listaAsset[0].listaOrg.length > 1
        )
          this.showChangeAsset = true;
      } else if (
        (this.isAdmin || this.isAdminOrg) &&
        usr.assetCorrente.idAsset
      ) {
        this.showChangeAsset = true;
      }
    });
  }

  descrizioneAsset(idAsset: number) {
    return this.listaAsset.find((a) => a.id == idAsset)?.nome;
  }

  descrizioneOrg(idOrg: number) {
    return this.listaOrg.find((o) => o.id == idOrg)?.descrizione;
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  goHome() {
    this.router.navigate(["/home"]);
  }

  logout() {
    this.router.navigate(["/logout"]);
  }
}
