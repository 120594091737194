import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { UtenteAssetService } from "../../service/utente-asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subscription } from "rxjs";
import { DateUtils } from "src/app/utility/date-utils";
import { RicercaUtentiAssetService } from "src/app/service/ricerca-utenti-asset-service";
import { DatiAttributoAssetComponent } from "src/app/component/dati-attributo-asset/dati-attributo-asset.component";
import { RegExpValidator } from "src/app/directive/regexp.validator";

@Component({
  selector: "app-modifica-attributo-asset",
  templateUrl: "./modifica-attributo-asset.component.html",
  styleUrls: ["./modifica-attributo-asset.component.css"],
})
export class ModificaAttributoAssetComponent implements OnInit {
  form: UntypedFormGroup;
  formUtente: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;
  ricercaUtentiList: any[] | undefined = undefined;

  @ViewChild(DatiAttributoAssetComponent) ruc!: DatiAttributoAssetComponent;

  @ViewChild("confirmModalAttributo")
  confirmModalAttributo: ModalConfirmComponent;
  @ViewChild("confirmModalUtente") confirmModalUtente: ModalConfirmComponent;
  @ViewChild("confirmModalRemoveUtente")
  confirmModalRemoveUtente: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  idAttributo: any;
  idUtenteToRemove: any;
  listaUtentiToUpdate: any[];
  userSubscription: Subscription;

  constructor(
    private utenteAssetService: UtenteAssetService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    fc: FormCommons,
    private alertService: AlertService,
    private userService: UserService,
    private ricercaUtentiAssetService: RicercaUtentiAssetService
  ) {
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  get listaUtenti() {
    return this.form.controls["listaUtenti"] as FormArray;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: null,
      chiave: [{ value: "", disabled: true }, [Validators.required]],
      valore: ["", [Validators.required, RegExpValidator()]],
      dataInizio: null,
      dataFine: null,
      listaValori: null,
      isDate: null,
      regexp: null,      
      listaUtenti: new FormArray([]),
    });

    this.formUtente = this.fb.group({
      codiceFiscale: [
        "",
        [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16),
        ],
      ],
      cognome: ["", Validators.required],
      nome: ["", Validators.required],
    });

    this.route.params.subscribe((params: Params) => {
      this.idAttributo = params["id"];

      this.userSubscription = this.userService.user.subscribe((usr) => {
        this.manageUser(usr);
      });
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSaveAttributo(utenti) {
    this.listaUtentiToUpdate = utenti;
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModalAttributo.show();
  }

  onSaveUtente() {
    this.firstAttempt = true;
    this.formUtente.markAllAsTouched();
    if (this.formUtente.invalid) {
      return;
    }
    this.confirmModalUtente.show();
  }

  onRemoveUtente(utente) {
    this.idUtenteToRemove = utente.idUtente;
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModalRemoveUtente.show();
  }

  saveAttributo() {
    const body = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
      listaUtenti: this.listaUtentiToUpdate
        ? this.listaUtentiToUpdate.map((u) => ({
            codiceFiscale: u.codiceFiscale,
            // cognome: u.cognome,
            // nome: u.nome,
          }))
        : [],
      listaValoriAttributoDaAssociare: [
        {
          fkIdAttributo: this.idAttributo,
          fkIdStrutturaOrganizzativa: !this.idOrg ? null : { id: this.idOrg },
          valore: this.form.value.valore,
          dataInizio: this.form.value.dataInizio
            ? DateUtils.toBeDate(this.form.value.dataInizio)
            : null,
          dataFine: this.form.value.dataFine
            ? DateUtils.toBeDate(this.form.value.dataFine)
            : null,
        },
      ],
    };
    this.utenteAssetService.associaUtentiValoriAttributo(body).then(() => {
      // this.form.reset();
      this.alertService.addDefaultSuccessMessage();
    });
  }

  removeUtente() {
    if (!this.idUtenteToRemove) return;
    this.utenteAssetService
      .dettaglio(this.idUtenteToRemove, this.idAsset, this.idOrg, true)
      .then((u) => {
        const body = {
          idAsset: this.idAsset,
          idStrutturaOrganizzativa: this.idOrg,
          listaUtenti: [
            {
              codiceFiscale: u.codiceFiscale,
              cognome: u.cognome,
              nome: u.nome,
            },
          ],
          valoriAttributoModifica: u.valoriAttributoList
            .filter(
              (v) =>
                v.fkIdAttributo == this.idAttributo &&
                v.valore == this.form.value.valore
            )
            .map((v) => ({
              ...v,
              dataFine: DateUtils.yesterdayBeDate(),
            })),
        };
        this.utenteAssetService.associaUtentiValoriAttributo(body).then(() => {
          const ricercaUtentiCriteri = {
            idAsset: this.idAsset,
            idOrg: this.idOrg,
            chiaveValoreAttributoList: [
              {
                chiave: this.form.controls.chiave.value,
                valore: this.form.value.valore,
              },
            ],
            pageInfo: {
              page: 0,
              maxResults: 10,
            },
          };
          this.searchUtenti(ricercaUtentiCriteri).then(() => {
            this.alertService.addDefaultSuccessMessage();
          });
        });
      });
  }

  saveUtente() {
    const body = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
      listaUtenti: [
        {
          codiceFiscale: this.formUtente.value.codiceFiscale,
          cognome: this.formUtente.value.cognome,
          nome: this.formUtente.value.nome,
        },
      ],
      listaValoriAttributoDaAssociare: [
        {
          fkIdAttributo: this.idAttributo,
          fkIdStrutturaOrganizzativa: !this.idOrg ? null : { id: this.idOrg },
          valore: this.form.value.valore,
          dataInizio: this.form.value.dataInizio
            ? DateUtils.toBeDate(this.form.value.dataInizio)
            : null,
          dataFine: this.form.value.dataFine
            ? DateUtils.toBeDate(this.form.value.dataFine)
            : null,
        },
      ],
    };
    this.utenteAssetService.associaUtentiValoriAttributo(body).then(() => {
      const ricercaUtentiCriteri = {
        idAsset: this.idAsset,
        idOrg: this.idOrg,
        soloUtentiValidi: false,
        caricaAttributi: false,
        chiaveValoreAttributoList: [
          {
            chiave: this.form.controls.chiave.value,
            valore: this.form.value.valore,
          },
        ],
        pageInfo: {
          page: 0,
          maxResults: 10,
        },
      };
      this.searchUtenti(ricercaUtentiCriteri).then(() => {
        this.formUtente.reset();
        this.alertService.addDefaultSuccessMessage();
      });
    });
  }

  searchUtenti(criteri: any): Promise<any> {
    return this.ricercaUtentiAssetService
      .search(criteri)
      .then((result) => {
        this.ricercaUtentiList = result?.items;
        this.ruc.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaUtentiList = [];
        }
      });
  }
}
