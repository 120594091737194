<app-container>
  <app-dati-struttura-organizzativa
    [(form)]="form"
    [pageStatus]="'EDIT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Struttura Organizzativa'"
    (onSubmit)="onSave()"
  >
  </app-dati-struttura-organizzativa>
  
  <app-modal-confirm
    #confirmModal
    title="Modifica Struttura Organizzativa"
    description="Vuoi confermare l'aggiornamento della struttura organizzativa?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
