import { Component, Input } from "@angular/core";

@Component({
  selector: "app-header-seleziona-asset",
  templateUrl: "./header-seleziona-asset.component.html",
  styleUrls: ["./header-seleziona-asset.component.css"],
})
export class HeaderSelezionaAssetComponent {
  @Input()
  descrizioneAsset: string;

  @Input()
  descrizioneOrg: string;

  @Input()
  showOrg: boolean;

  @Input()
  showChangeAsset: boolean;

  constructor() {}

  ngOnInit(): void {}
}
