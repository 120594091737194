<h3 class="mb-4">{{ formTitle }}</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionFileupload">
    <div class="accordion-item" style="margin-bottom: 80px">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          File Upload
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body py-4 mb-4">
          <div class="row my-4">
            <div class="col-12">
              <h6>Selezionare il file Excel da importare:</h6>
            </div>
          </div>
          <div class="row justify-content-evenly">
            <div class="col">
              <it-upload-file-list
                id="fileUpload"
                multiple="false"
                [fileList]="uploadedFileList"
                (uploadFiles)="onUpdateFileList($event)"
                (deleteItem)="onDeleteFileList($event)"
              ></it-upload-file-list>
            </div>
            <div class="col-auto">
              <button
                type="submit"
                [disabled]="(uploadedFileList?.length ?? 0) == 0"
                class="btn btn-primary"
              >
                Importa
              </button>
            </div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="esportaTemplate()"
                *ngIf="importType === 'UTENTI'"
              >
                Scarica Template
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="accordion-item"
      *ngIf="
        ricercaImportList &&
        ricercaImportList.length > 0 &&
        !showListaErroriElaborazione
      "
    >
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Storico Importazioni
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body" style="padding-bottom: 0px">
          <div
            id="risultatiRicercaImport"
            class="mt-4"
            *ngIf="ricercaImportList"
          >
            <!-- [ngbCollapse]="!ricercaImportList"> -->
            <div class="table-responsive-md">
              <it-table hover="true">
                <ng-container
                  thead
                  *ngIf="ricercaImportList && ricercaImportList.length > 0"
                >
                  <tr>
                    <th
                      scope="col"
                      sortable="dataRichiesta"
                      (sort)="onSort($event)"
                    >
                      Data Richiesta
                    </th>
                    <th scope="col">Stato</th>
                    <th scope="col">Esito</th>
                    <th scope="col"></th>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaImportList && ricercaImportList.length === 0"
                >
                  <tr>
                    <td colspan="4">
                      <h6 class="mt-2">Nessuna elaborazione trovata</h6>
                    </td>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="ricercaImportList && ricercaImportList.length !== 0"
                >
                  <tr *ngFor="let import of ricercaImportList">
                    <td>
                      {{ import.dataRichiesta }}
                    </td>
                    <td>
                      {{ import.stato }}
                    </td>
                    <td>
                      <it-icon
                        name="check-circle"
                        color="success"
                        *ngIf="import.success"
                      ></it-icon>
                      <it-icon
                        name="error"
                        color="danger"
                        *ngIf="import.failed"
                        itTooltip="Errore nell'elaborazione"
                        tooltipPlacement="bottom"
                      ></it-icon>
                    </td>
                    <td style="text-align: right">
                      <button
                        id="aggiornaListaButton"
                        itTooltip="Aggiorna"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="aggiornaListaImport()"
                        *ngIf="isElaborazioneInCorso(import)"
                      >
                        <it-spinner small="true"></it-spinner>
                        <!-- <it-icon name="refresh"></it-icon> -->
                      </button>
                      <button
                        id="visualizzaErroriButton"
                        itTooltip="Visualizza errori"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="visualizzaErrori(import)"
                        *ngIf="import.failed"
                      >
                        <it-icon name="list" color="danger"></it-icon>
                      </button>
                      <button
                        id="downloadImportButton"
                        itTooltip="Scarica File Excel"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="downloadImport(import)"
                      >
                        <it-icon name="download"></it-icon>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </it-table>
            </div>
            <div
              id="paginazione"
              *ngIf="ricercaImportList && ricercaImportList.length !== 0"
            >
              <div class="row">
                <div class="input-group col-md-4">
                  <label for="numero-voci">Numero elaborazioni:</label>
                  <div class="ml-3">&nbsp;{{ numeroVoci }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <it-pagination
                      [currentPage]="page"
                      [pageNumbers]="numberOfPages"
                      alignment="center"
                      (pageEvent)="getPage($event)"
                    ></it-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="accordion-item"
      *ngIf="
        erroriElaborazione &&
        erroriElaborazione.length > 0 &&
        showListaErroriElaborazione
      "
    >
      <h2 class="accordion-header" id="heading1c">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1c"
          aria-expanded="true"
          aria-controls="collapse1c"
        >
          Errori
        </button>
      </h2>
      <div
        id="collapse1c"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1c"
      >
        <div class="accordion-body" style="padding-bottom: 0px">
          <div class="row justify-content-evenly">
            <div class="col mt-2" style="margin-left: -20px">
              <button
                type="button"
                itButton="outline-secondary"
                (click)="nascondiErrori()"
              >
                <it-icon name="arrow-left" class="me-2"></it-icon>
                Storico Importazioni
              </button>
            </div>
          </div>
          <div id="erroriImport" class="mt-4" *ngIf="erroriElaborazione">
            <!-- [ngbCollapse]="!ricercaImportList"> -->
            <div class="table-responsive-md">
              <it-table hover="true">
                <ng-container
                  thead
                  *ngIf="erroriElaborazione && erroriElaborazione.length > 0"
                >
                  <tr>
                    <th scope="col">Riga</th>
                    <th scope="col">Errore</th>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="erroriElaborazione && erroriElaborazione.length === 0"
                >
                  <tr>
                    <td colspan="4">
                      <h6 class="mt-2">Nessun errore trovato</h6>
                    </td>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="erroriElaborazione && erroriElaborazione.length !== 0"
                >
                  <tr *ngFor="let errore of erroriElaborazioneCurrentPage">
                    <td>
                      {{ errore.numeroRiga }}
                    </td>
                    <td>
                      {{ errore.descrizione }}
                    </td>
                  </tr>
                </ng-container>
              </it-table>
            </div>
            <div
              id="paginazione"
              *ngIf="erroriElaborazione && erroriElaborazione.length !== 0"
            >
              <div class="row">
                <div class="input-group col-md-4">
                  <label for="numero-voci">Numero errori:</label>
                  <div class="ml-3">&nbsp;{{ numeroVociErrori }}</div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <it-pagination
                      [currentPage]="pageErrore"
                      [pageNumbers]="numberOfPagesErrori"
                      alignment="center"
                      (pageEvent)="getPageErrori($event)"
                    ></it-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-5">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaRicerca()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        {{ textPulsanteRicerca }}
      </button>
    </div>
  </div>
</form>

<app-modal-confirm
  #confirmModal
  [title]="formTitle"
  description="Vuoi confermare l'importazione del file selezionato?"
  (onConfirm)="save()"
>
</app-modal-confirm>
