import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { FormCommons } from "src/app/utility/commons";

@Component({
  selector: "app-seleziona-asset-page",
  templateUrl: "./seleziona-asset-page.component.html",
  styleUrls: ["./seleziona-asset-page.component.css"],
})
export class SelezionaAssetPageComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;

  constructor(private fb: UntypedFormBuilder, fc: FormCommons) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      asset: ["", Validators.required],
      org: [""],
    });
  }
}
