<h3 class="mb-4">{{ formTitle }}</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionDatiAsset">
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati Asset
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Nome *" formControlName="nome" #nome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="nome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="nome.hasError('maxLength')">
                    La lunghezza massima è di 50 caratteri
                  </div>
                </ng-container>
              </it-input>
            </div>
            <div class="form-group col-md-6 form-group-b-0">
              <it-input
                label="Prefisso attributi *"
                formControlName="prefisso"
                #prefisso
              >
                <ng-container error>
                  <div *ngIf="prefisso.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="prefisso.hasError('maxlength')">
                    La lunghezza massima è di 15 caratteri
                  </div>
                  <div *ngIf="prefisso.hasError('pattern')">
                    Digitare solo lettere e numeri senza spazi e caratteri
                    speciali o accentati
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group form-group-b-0">
              <it-textarea
                label="Descrizione"
                formControlName="descrizione"
                #descrizione
              ></it-textarea>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col"></div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item" *ngIf="form.value.id && form.value.attributi">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Attributi
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <div id="risultatiRicercaAttributi" class="mt-4">
            <!-- [ngbCollapse]="!ricercaAssetList"> -->
            <div class="table-responsive-md">
              <it-table hover="true">
                <ng-container
                  thead
                  *ngIf="
                    form.value.attributi && form.value.attributi.length !== 0
                  "
                >
                  <tr>
                    <th scope="col">Nome attributo</th>
                    <th scope="col" style="text-align: center">Obbligatorio</th>
                    <th scope="col" style="text-align: center">
                      Valori multipli
                    </th>
                    <th scope="col" style="text-align: center">Strutt. org.</th>
                    <th scope="col"></th>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="
                    form.value.attributi && form.value.attributi.length === 0
                  "
                >
                  <tr>
                    <td colspan="2">
                      <h6 class="mt-2">Nessun attributo definito</h6>
                    </td>
                  </tr>
                </ng-container>
                <ng-container
                  tbody
                  *ngIf="
                    form.value.attributi && form.value.attributi.length !== 0
                  "
                >
                  <tr *ngFor="let attributo of form.value.attributi">
                    <td>
                      {{ attributo.chiave }}
                    </td>
                    <td style="text-align: center">
                      <it-icon
                        name="check"
                        *ngIf="attributo.flagObbligatorio"
                      ></it-icon>
                    </td>
                    <td style="text-align: center">
                      <it-icon
                        name="check"
                        *ngIf="attributo.flagValoreMultiplo"
                      ></it-icon>
                    </td>
                    <td style="text-align: center">
                      <it-icon
                        name="check"
                        *ngIf="attributo.flagIncludiStruttOrg"
                      ></it-icon>
                    </td>
                    <td style="text-align: right">
                      <button
                        id="modificaAttributoButton"
                        itTooltip="Modifica attributo"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="modificaAttributo(attributo)"
                      >
                        <it-icon name="pencil"></it-icon>
                      </button>
                      <button
                        id="eliminaAttributoButton"
                        itTooltip="Elimina attributo"
                        tooltipPlacement="bottom"
                        class="btn py-0"
                        type="button"
                        (click)="eliminaAttributo(attributo)"
                      >
                        <it-icon name="delete"></it-icon>
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </it-table>
            </div>
            <div
              id="paginazione"
              *ngIf="form.value.attributi && form.value.attributi.length !== 0"
            >
              <div class="row">
                <div class="input-group col-md-4">
                  <label for="numero-voci">Numero attributi:</label>
                  <div class="ml-3">
                    &nbsp;{{ form.value.attributi.length }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col"></div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="nuovoAttributo(form.value.id, form.value.nome)"
              >
                Nuovo attributo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaRicercaAsset()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        Ricerca Asset
      </button>
    </div>
  </div>
</form>
