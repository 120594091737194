<div class="accordion accordion-background-active" id="accordionRicercaUtenti">
  <div class="accordion-item">
    <h2 class="accordion-header" id="heading1a">
      <button
        class="accordion-button py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse1a"
        aria-expanded="true"
        aria-controls="collapse1a"
      >
        Criteri di ricerca
      </button>
    </h2>
    <div
      id="collapse1a"
      class="accordion-collapse collapse show"
      role="region"
      aria-labelledby="heading1a"
    >
      <div class="accordion-body">
        <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
          <div class="row mt-4">
            <div class="form-group col-md-4">
              <it-select
                label="Attributo"
                formControlName="attributo"
                [options]="attributiSelectOptions"
              >
              </it-select>
            </div>
            <div class="form-group col-md-4">
              <it-input label="Valore" formControlName="valore"></it-input>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <button type="reset" itButton="light">Svuota Campi</button>
              &nbsp;
              <button type="submit" itButton="primary" [disabled]="!form.valid">
                Ricerca
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="ricercaAttributiList">
    <h2 class="accordion-header" id="heading1b">
      <button
        class="accordion-button py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse1b"
        aria-expanded="true"
        aria-controls="collapse1b"
      >
        Risultati ricerca
      </button>
    </h2>
    <div
      id="collapse1b"
      class="accordion-collapse collapse show"
      role="region"
      aria-labelledby="heading1b"
    >
      <div class="accordion-body">
        <div
          id="risultatiRicercaAttributi"
          class="mt-4"
          *ngIf="ricercaAttributiList"
        >
          <!-- [ngbCollapse]="!ricercaUtentiList"> -->
          <div class="table-responsive-md">
            <it-table hover="true">
              <caption>
                Lista degli attributi
              </caption>
              <ng-container
                thead
                *ngIf="ricercaAttributiList && ricercaAttributiList.length > 0"
              >
                <tr>
                  <th scope="col" sortable="chiave" (sort)="onSort($event)">
                    Attributo
                  </th>
                  <th scope="col" sortable="valore" (sort)="onSort($event)">
                    Valore
                  </th>
                  <th scope="col">Utenti</th>
                  <th scope="col"></th>
                </tr>
              </ng-container>
              <ng-container
                tbody
                *ngIf="
                  ricercaAttributiList && ricercaAttributiList.length === 0
                "
              >
                <tr>
                  <td colspan="3">
                    <h6 class="mt-2">Nessun attributo trovato</h6>
                  </td>
                </tr>
              </ng-container>
              <ng-container
                tbody
                *ngIf="
                  ricercaAttributiList && ricercaAttributiList.length !== 0
                "
              >
                <tr *ngFor="let attributo of ricercaAttributiList">
                  <td>
                    <ngb-highlight [result]="attributo.chiave"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="attributo.valore"></ngb-highlight>
                  </td>
                  <td>
                    {{ getCountUtenti(attributo) }}
                  </td>
                  <td style="text-align: right">
                    <button
                      id="modificaAttributoButton"
                      itTooltip="Modifica Attributo"
                      tooltipPlacement="bottom"
                      class="btn py-0"
                      type="button"
                      (click)="modificaAttributo(attributo)"
                    >
                      <it-icon name="pencil"></it-icon>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </it-table>
          </div>
          <div
            id="paginazione"
            *ngIf="ricercaAttributiList && ricercaAttributiList.length !== 0"
          >
            <div class="row">
              <div class="input-group col-md-4">
                <label for="numero-voci">Numero valori attributi:</label>
                <div class="ml-3">&nbsp;{{ numeroVoci }}</div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <it-pagination
                    [currentPage]="page"
                    [pageNumbers]="numberOfPages"
                    alignment="center"
                    (pageEvent)="getPage($event)"
                  ></it-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
