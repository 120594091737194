import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { AssetService } from "../../service/asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { AttributoService } from "src/app/service/attributo-service";
import { Subject } from "rxjs";

@Component({
  selector: "app-modifica-asset",
  templateUrl: "./modifica-asset.component.html",
  styleUrls: ["./modifica-asset.component.css"],
})
export class ModificaAssetComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;
  @ViewChild("confirmModalDeleteAttributo")
  confirmModalDeleteAttributo: ModalConfirmComponent;

  idAsset: any;
  ruoli: any[] = [];
  attributoToBeDeleted: any;

  constructor(
    private assetService: AssetService,
    private attributoService: AttributoService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    fc: FormCommons,
    private alertService: AlertService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [""],
      nome: ["", [Validators.required, Validators.maxLength(50)]],
      prefisso: [
        "",
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern("^[a-zA-Z0-9]*$"),
        ],
      ],
      descrizione: ["", Validators.maxLength(512)],
      attributi: [],
    });

    this.route.params.subscribe((params: Params) => {
      this.idAsset = params["id"];

      this.assetService.getAsset(this.idAsset).then((result) => {
        this.form.patchValue({
          id: result?.id,
          nome: result?.nome,
          descrizione: result?.descrizione,
          prefisso: result?.prefisso,
          attributi: result?.attributi,
        });
      });
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      id: this.form.value.id,
      nome: this.form.value.nome,
      prefisso:
        this.form.value.prefisso == ""
          ? this.form.value.nome.toLowerCase()
          : this.form.value.prefisso,
      descrizione: this.form.value.descrizione,
      asset: this.form.value.isAmministratore ? null : [this.form.value.asset],
    };
    this.assetService.update(body.id, body).then(() => {
      this.alertService.addDefaultSuccessMessage();
    });
  }

  onDeleteAttributo(attributo: any) {
    this.attributoToBeDeleted = attributo;
    this.confirmModalDeleteAttributo.show();
  }

  deleteAttributo() {
    if (!this.attributoToBeDeleted?.id) return;
    this.attributoService
      .deleteAttributo(this.attributoToBeDeleted.id)
      .then((u) => {
        this.assetService.getAsset(this.idAsset).then((result) => {
          this.form.patchValue({
            attributi: result?.attributi,
          });
        });
        this.attributoToBeDeleted = null;
      });
  }
}
