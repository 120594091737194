import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { RicercaUtentiAamService } from "../../service/ricerca-utenti-aam-service";

import { RicercaUtentiComponent } from "src/app/component/ricerca-utenti/ricerca-utenti.component";
import { DescrizioneRuolo } from "src/app/tipologiche/profili";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { UtenteAssetService } from "src/app/service/utente-asset-service";
import { UserService } from "src/app/service/user-service";
import { Subject } from "rxjs";

@Component({
  selector: "app-ricerca-utenti-aam",
  templateUrl: "./ricerca-utenti-aam.component.html",
  styleUrls: ["./ricerca-utenti-aam.component.css"],
})
export class RicercaUtentiAamComponent implements OnInit {
  ricercaUtentiList: any[] | undefined = undefined;
  utenteToBeDeleted: any;
  searchSubject: Subject<void> = new Subject<void>();

  @ViewChild(RicercaUtentiComponent) ruc!: RicercaUtentiComponent;
  @ViewChild("confirmModalDeleteUtente")
  confirmModalDeleteUtente: ModalConfirmComponent;
  constructor(
    private utenteAssetService: UtenteAssetService,
    private userService: UserService,
    private ricercaUtentiAamService: RicercaUtentiAamService
  ) {}

  ngOnInit(): void {}

  exportUtenti(criteri: any) {
    this.ricercaUtentiAamService.export(criteri);
  }
  searchUtenti(criteri: any) {
    this.ricercaUtentiAamService
      .search(criteri)
      .then((result) => {
        this.ricercaUtentiList = result?.items.map((item) => ({
          ...item,
          profilo: DescrizioneRuolo(item.profilo),
        }));
        this.ruc.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaUtentiList = [];
        }
      });
  }

  onDeleteUtente(utente: any) {
    this.utenteToBeDeleted = utente;
    this.confirmModalDeleteUtente.show();
  }

  deleteUtente() {
    if (!this.utenteToBeDeleted?.idUtente) return;
    this.userService
      .deleteUserAam(this.utenteToBeDeleted.idUtente)
      .then((u) => {
        this.searchSubject.next();
      });
  }
}
