<app-container>
  <app-dati-attributo-asset
    [(form)]="form"
    [(formUtente)]="formUtente"
    [pageStatus]="'EDIT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Attributo'"
    (onSaveAttributo)="onSaveAttributo($event)"
    (onSaveUtente)="onSaveUtente()"
    (onRemoveUtente)="onRemoveUtente($event)"
    [ricercaUtentiList]="ricercaUtentiList"
    (onSearch)="searchUtenti($event)"
  >
  </app-dati-attributo-asset>

  <app-modal-confirm
    #confirmModalAttributo
    title="Modifica Attributo"
    description="Vuoi confermare l'aggiornamento del valore dell'attributo?"
    (onConfirm)="saveAttributo()"
  >
  </app-modal-confirm>

  <app-modal-confirm
    #confirmModalUtente
    title="Modifica Attributo"
    description="Vuoi confermare l'inserimento dell'utente per l'attributo?"
    (onConfirm)="saveUtente()"
  >
  </app-modal-confirm>

  <app-modal-confirm
    #confirmModalRemoveUtente
    title="Modifica Attributo"
    description="Vuoi confermare l'eliminazione dell'attributo per l'utente selezionato?"
    (onConfirm)="removeUtente()"
  >
  </app-modal-confirm>
</app-container>
