import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { AssetService } from "../../service/asset-service";
import { UtenteAssetService } from "../../service/utente-asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subscription } from "rxjs";
import { DateUtils } from "src/app/utility/date-utils";
import { Router } from "@angular/router";
import { UserBaseComponent } from "../user-base-component";
import { TIPO_ATTRIBUTO_DATA_ID } from "src/app/tipologiche/tipo_attributo";
import { RegExpValidator } from "src/app/directive/regexp.validator";
import { NomeValidator } from "src/app/directive/nome.validator";
import { CodiceFiscaleValidator } from "src/app/directive/codice-fiscale.validator";

@Component({
  selector: "app-inserimento-nuovo-utente-asset",
  templateUrl: "./inserimento-nuovo-utente-asset.component.html",
  styleUrls: ["./inserimento-nuovo-utente-asset.component.css"],
})
export class InserimentoNuovoUtenteAssetComponent
  extends UserBaseComponent
  implements OnInit
{
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  attributiAsset: any[];

  get listaAttributi() {
    return this.form.controls["listaAttributi"] as FormArray;
  }

  constructor(
    private utenteAssetService: UtenteAssetService,
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private alertService: AlertService,
    assetService: AssetService,
    router: Router,
    userService: UserService
  ) {
    super(assetService, userService, router);
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      codiceFiscale: ["", [Validators.required, CodiceFiscaleValidator()]],
      cognome: ["", [Validators.required, NomeValidator()]],
      nome: ["", [Validators.required, NomeValidator()]],
      listaAttributi: new FormArray([]),
    });

    this.assetSub = this.assetService.currentAsset$.subscribe((asset) => {
      this.attributiAsset = asset?.attributi;

      asset?.attributi?.forEach((attributo) => {
        const valore = {
          idAttributo: attributo.id,
          attributo: { value: attributo.id, disabled: true },
          valore: ["", [Validators.required, RegExpValidator()]],
          dataInizio: null,
          dataFine: null,
          canBeDeleted: !attributo.flagObbligatorio,
          toBeDeleted: false,
          isDate: attributo.fkIdAttributo?.id == TIPO_ATTRIBUTO_DATA_ID,
          listaValori: [],
          regexp: null,
        };
        this.listaAttributi.push(this.fb.group(valore));
      });
    });
  }

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.assetSub && this.assetSub.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: !this.idOrg ? null : this.idOrg,
      listaUtenti: [
        {
          codiceFiscale: this.form.value.codiceFiscale,
          cognome: this.form.value.cognome,
          nome: this.form.value.nome,
        },
      ],
      listaValoriAttributoDaAssociare: this.form.value.listaAttributi.map(
        (a) => ({
          fkIdAttributo: a.attributo ?? a.idAttributo,
          fkIdStrutturaOrganizzativa: !this.idOrg ? null : { id: this.idOrg },
          valore: a.isDate ? DateUtils.toBeDate(a.valore, "/") : a.valore,
          dataInizio: a.dataInizio ? DateUtils.toBeDate(a.dataInizio) : null,
          dataFine: a.dataFine ? DateUtils.toBeDate(a.dataFine) : null,
        })
      ),
    };
    this.utenteAssetService
      .associaUtentiValoriAttributo(body) //, true)
      .then((result) => {
        // this.form.reset();
        if (result && result.length > 0) {
          this.router.navigate(["/modificaUtenteAsset", result[0]], {
            state: {
              showSuccessMessage: true,
              title: "Dati Utente",
            },
          });
        } else {
          this.alertService.addDefaultSuccessMessage();
        }
      });
  }
}
