<app-container>
  <h3 class="mb-4">Ricerca Utenti Asset</h3>
  <app-ricerca-utenti-asset
    [ricercaUtentiList]="ricercaUtentiList"
    [(selectedUtenti)]="selectedUtenti"
    (onSearch)="searchUtenti($event)"
    (onSelect)="selectUtente($event)"
    (onSelectAll)="selectAll($event)"
    (onDownload)="exportUtenti($event)"
    (onDeleteUtente)="onDeleteUtente($event)"
  >
  </app-ricerca-utenti-asset>

  <app-modal-confirm
    #confirmModalDeleteUtente
    title="Eliminazione Utente"
    description="L'operazione di eliminazione imposta a ieri la data fine di tutti gli attributi dell'utente selezionato. Vuoi confermare l'operazione?"
    (onConfirm)="deleteUtente()"
  >
  </app-modal-confirm>
</app-container>
