import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sessione-scaduta',
  templateUrl: './sessione-scaduta.component.html',
  styleUrls: ['./sessione-scaduta.component.css']
})
export class SessioneScadutaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
