import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { AssetService } from "../../service/asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";

@Component({
  selector: "app-inserimento-nuovo-asset",
  templateUrl: "./inserimento-nuovo-asset.component.html",
  styleUrls: ["./inserimento-nuovo-asset.component.css"],
})
export class InserimentoNuovoAssetComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  ruoli: any[] = [];

  constructor(
    private assetService: AssetService,
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private alertService: AlertService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [""],
      nome: ["", [Validators.required, Validators.maxLength(50)]],
      prefisso: [
        "",
        [
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern("^[a-zA-Z0-9]*$"),
        ],
      ],
      descrizione: ["", Validators.maxLength(512)],
      attributi: [],
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      id: this.form.value.id,
      nome: this.form.value.nome,
      prefisso:
        this.form.value.prefisso == ""
          ? this.form.value.nome.toLowerCase()
          : this.form.value.prefisso,
      descrizione: this.form.value.descrizione,
      asset: this.form.value.isAmministratore ? null : [this.form.value.asset],
    };
    if (body.id) {
      this.assetService.update(body.id, body).then(() => {
        this.alertService.addDefaultSuccessMessage();
      });
    } else {
      this.assetService.insert(body).then((result) => {
        this.assetService.getAsset(result.id).then(() => {
          this.form.patchValue({
            id: result.id,
            nome: result.nome,
            descrizione: result.descrizione,
            prefisso: result.prefisso,
            attributi: [],
          });
          this.alertService.addDefaultSuccessMessage();
        });
      });
    }
  }
}
