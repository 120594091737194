import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserService } from "../service/user-service";
import { AssetService } from "../service/asset-service";

@Component({
  selector: "app-user-base",
  template: ``,
  styles: [],
})
export class UserBaseComponent implements OnInit {
  protected userSub: Subscription;
  protected assetSub: Subscription;

  constructor(
    protected assetService: AssetService,
    protected userService: UserService,
    protected router: Router
  ) {
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) {
        this.assetService.currentAsset$.next({});
        return;
      }
      if (
        (this.userService.isAdmin() || this.userService.isAdminOrg()) &&
        !this.assetService.currentAsset$.getValue()?.id
      ) {
        this.router.navigate(["/selezionaAsset"], {
          state: {
            returnRoute: router.url,
          },
        });
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.assetSub && this.assetSub.unsubscribe();
  }
}
