import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { SelectControlOption } from "design-angular-kit/public_api";
import { AssetService } from "src/app/service/asset-service";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import * as _ from "lodash";

@Component({
  selector: "app-seleziona-asset",
  templateUrl: "./seleziona-asset.component.html",
  styleUrls: ["./seleziona-asset.component.css"],
})
export class SelezionaAssetComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  showAsset: boolean = false;

  listaAsset: any[];
  listaOrg: any[];
  assetSub: Subscription;
  userSub: Subscription;
  user: any;

  assetSelectOptions: SelectControlOption[] = [
    // { value: null, text: "Seleziona un asset" },
  ];

  orgSelectOptions: SelectControlOption[] = [
    // { value: null, text: "" }
  ];

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  formTitle: string;

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  constructor(
    private assetService: AssetService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private userService: UserService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      asset: ["", Validators.required],
      org: null,
    });
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.user = usr;

      this.assetService.getListaAsset().then((resultListaAsset) => {
        this.listaAsset = this.userService.isAdmin()
          ? resultListaAsset
          : resultListaAsset.filter((element) =>
              usr.listaAsset?.map((a) => +a.asset).includes(element.id)
            );

        this.listaAsset.forEach((element) => {
          this.assetSelectOptions.push({
            value: element.id,
            text: element.nome,
          });
        });
        this.form.controls.asset.setValue(usr.assetCorrente?.idAsset);

        this.strutturaOrganizzativaService.getAll().then((resultListaOrg) => {
          this.listaOrg = _.sortBy(resultListaOrg, ["descrizione"]);

          if (usr.isAdmin) {
            this.orgSelectOptions.push({
              value: "",
              text: "",
            });
          }
          this.listaOrg
            .filter(
              (element) =>
                this.userService.isAdmin() ||
                (
                  usr.listaAsset.find(
                    (element) => element.asset == usr.assetCorrente.idAsset
                  )?.listaOrg ?? []
                ).includes(element.id.toString())
            )
            .forEach((element) => {
              this.orgSelectOptions.push({
                value: element.id,
                text: element.descrizione,
              });
            });

          this.form.controls.org.setValue(usr.assetCorrente?.idOrg);
        });
      });
    });

    this.assetSub = this.form.controls.asset.valueChanges.subscribe(() => {
      if (this.form.controls.asset.value && !this.userService.isAdmin()) {
        this.orgSelectOptions = [];

        const currentListaOrg = this.listaOrg?.filter((element) =>
          (
            this.user.listaAsset.find(
              (element) => element.asset == this.form.controls.asset.value
            )?.listaOrg ?? []
          ).includes(element.id.toString())
        );

        currentListaOrg?.forEach((element) => {
          this.orgSelectOptions.push({
            value: element.id,
            text: element.descrizione,
          });
        });

        this.form.controls.org.setValue(
          currentListaOrg && currentListaOrg.length > 0
            ? currentListaOrg[0].id
            : null
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.assetSub && this.assetSub.unsubscribe();
    this.userSub && this.userSub.unsubscribe();
  }

  save() {
    this.userService.changeAssetCorrente(
      this.form.value.asset,
      this.form.value.org
    );
    this.assetService.setCurrentAsset(
      this.form.value.asset,
      history.state.returnRoute ?? "/home"
    );
  }

  selezionaOrg(value) {
    this.form.patchValue({ org: value });
  }
}
