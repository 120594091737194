import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { UtenteAssetService } from "../../service/utente-asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subscription } from "rxjs";
import { DateUtils } from "src/app/utility/date-utils";
import { RicercaUtentiAssetService } from "src/app/service/ricerca-utenti-asset-service";

@Component({
  selector: "app-inserimento-nuovi-attributi-utenti-asset",
  templateUrl: "./inserimento-nuovi-attributi-utenti-asset.component.html",
  styleUrls: ["./inserimento-nuovi-attributi-utenti-asset.component.css"],
})
export class InserimentoNuoviAttributiUtentiAssetComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idAsset: any;
  idOrg: any;
  userSubscription: Subscription;

  constructor(
    private utenteAssetService: UtenteAssetService,
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private alertService: AlertService,
    userService: UserService,
    private ricercaUtentiAssetService: RicercaUtentiAssetService
  ) {
    this.formCommons = fc;
    this.manageUser(userService.getUserStored());
  }

  get listaAttributi() {
    return this.form.controls["listaAttributi"] as FormArray;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      listaAttributi: new FormArray([]),
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    if (
      history.state.selectedUtenti &&
      history.state.selectedUtenti.length > 0
    ) {
      this.saveAttributiForSelectedUsers();
    } else {
      this.saveAttributiForRicercaResult();
    }
  }

  saveAttributiForSelectedUsers() {
    const body = {
      ...this.GetBodyRequest(),
      listaUtenti:
        history.state?.selectedUtenti?.map((u) => ({
          codiceFiscale: u,
        })) ?? [],
    };
    this.utenteAssetService
      .associaUtentiValoriAttributo(body)
      .then((result) => {
        this.alertService.addDefaultSuccessMessage();
      });
  }

  saveAttributiForRicercaResult() {
    const criteriRicerca =
      this.ricercaUtentiAssetService.getCriteriRicercaStored();
    this.ricercaUtentiAssetService
      .search(criteriRicerca, true)
      .then((risultatoRicerca) => {
        const body = {
          ...this.GetBodyRequest(),
          listaUtenti: !risultatoRicerca.items
            ? []
            : risultatoRicerca.items.map((u) => ({
                codiceFiscale: u.codiceFiscale,
                // cognome: u.cognome,
                // nome: u.nome,
              })),
        };
        this.utenteAssetService
          .associaUtentiValoriAttributo(body)
          .then((result) => {
            this.alertService.addDefaultSuccessMessage();
          });
      });
  }

  private GetBodyRequest(): any {
    return {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
      listaValoriAttributoDaAssociare: this.form.value.listaAttributi.map(
        (a) => ({
          fkIdAttributo: a.attributo,
          fkIdStrutturaOrganizzativa: !this.idOrg ? null : { id: this.idOrg },
          valore: a.isDate ? DateUtils.toBeDate(a.valore, "/") : a.valore,
          dataInizio: a.dataInizio ? DateUtils.toBeDate(a.dataInizio) : null,
          dataFine: a.dataFine ? DateUtils.toBeDate(a.dataFine) : null,
        })
      ),
    };
  }
}
