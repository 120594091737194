<h3 class="mb-4">{{ formTitle }}</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div
    class="accordion accordion-background-active"
    id="accordionDatiAttributo"
  >
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati attributo
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input
                label="Asset"
                formControlName="nomeAsset"
                readonly="true"
              >
              </it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Nome *" formControlName="chiave" #chiave>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="chiave.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                </ng-container>
              </it-input>
            </div>
            <div class="form-group col-md-6">
              <it-select
                label="Tipo attributo *"
                formControlName="idTipoAttributo"
                [options]="tipoAttributoSelectOptions"
              >
              </it-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-9 col-lg-7">
              <it-checkbox
                label="Attributo obbligatorio"
                toggle="true"
                formControlName="flagObbligatorio"
              ></it-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-9 col-lg-7">
              <it-checkbox
                label="Accetta valori multipli"
                toggle="true"
                formControlName="flagValoreMultiplo"
              ></it-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-9 col-lg-7">
              <it-checkbox
                label="Includi struttura organizzativa"
                toggle="true"
                formControlName="flagIncludiStruttOrg"
              ></it-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Valori Attributo
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <div class="row">
            <div class="form-group">
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <it-checkbox
                    label="Valore libero"
                    toggle="true"
                    formControlName="valoreLibero"
                  ></it-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9 col-lg-7">
                  <it-checkbox
                    label="Elenco valori predefiniti"
                    toggle="true"
                    formControlName="valoreDaLista"
                  ></it-checkbox>
                </div>
              </div>
              <div
                class="row"
                *ngIf="querySelectOptions && querySelectOptions.length > 0"
              >
                <div class="col-md-9 col-lg-7">
                  <it-checkbox
                    label="Valori da query RCT"
                    toggle="true"
                    formControlName="valoreDaQuery"
                  ></it-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item" *ngIf="form.value.valoreLibero">
      <h2 class="accordion-header" id="heading1c">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1c"
          aria-expanded="true"
          aria-controls="collapse1c"
        >
          Validazione
        </button>
      </h2>
      <div
        id="collapse1c"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1c"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0">
              <it-textarea
                label="Espressione regolare"
                formControlName="txtRegex"
                #txtRegex
              ></it-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item" *ngIf="form.value.valoreDaLista">
      <h2 class="accordion-header" id="heading1d">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1d"
          aria-expanded="true"
          aria-controls="collapse1d"
        >
          Valori predefiniti
        </button>
      </h2>
      <div
        id="collapse1d"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1d"
      >
        <div class="accordion-body">
          <ng-container formArrayName="listaValori">
            <div
              class="row attributi-header-row"
              *ngIf="listaValori && listaValori.controls.length > 0"
            >
              <div class="col-md-11">
                <div class="row">
                  <div class="form-group col">
                    <label>Valore *</label>
                  </div>
                </div>
              </div>
            </div>
            <ng-container
              *ngFor="let valoreGroup of listaValori.controls; index as i"
            >
              <ng-container [formGroup]="valoreGroup">
                <div class="row mt-4">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="form-group col-12 riga-valore">
                        <it-input formControlName="valore">
                          <ng-container error>
                            <div
                              *ngIf="
                                valoreGroup.controls.valore.hasError('required')
                              "
                            >
                              Il campo è obbligatorio
                            </div>
                          </ng-container>
                        </it-input>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-1">
                    <it-icon
                      name="delete"
                      (click)="rimuoviValore(i)"
                      itTooltip="Rimuovi valore"
                      tooltipPlacement="bottom"
                      class="link-pointer"
                    ></it-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row">
              <div class="form-group col-md-6">
                <a class="link-pointer" (click)="aggiungiValore()"
                  >Aggiungi valore</a
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      class="accordion-item"
      *ngIf="form.value.valoreDaQuery && querySelectOptions"
    >
      <h2 class="accordion-header" id="heading1c">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1c"
          aria-expanded="true"
          aria-controls="collapse1c"
        >
          Query
        </button>
      </h2>
      <div
        id="collapse1c"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1c"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0">
              <it-select
                label="Query *"
                formControlName="query"
                [options]="querySelectOptions"
              >
              </it-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaDatiAsset()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        Dati Asset
      </button>
    </div>
    <div class="col-auto">
      <button type="submit" class="btn btn-primary">Salva</button>
    </div>
  </div>
</form>
