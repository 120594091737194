<app-container>
  <app-import-file-excel
    [(form)]="form"
    [importType]="'STRUTTURE-ORGANIZZATIVE'"
    [formTitle]="'Importazione Strutture Organizzative'"
    [textPulsanteRicerca]="'Ricerca Strutture'"
    (onBackToRicerca)="tornaRicercaStrutture()"
  >
  </app-import-file-excel>
</app-container>
