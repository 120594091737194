<div class="container-header sezione-border">
  <div class="header">
    <div class="logoRegioneToscana" (click)="goHome()">
      <img
        alt="Regione-Toscana"
        src="assets/img/logoToscana.png"
        style="height: 61px"
      />
      <img
        alt="SST"
        style="height: 61px; margin-left: 3px"
        src="assets/img/Logo_SST.png"
      />
      <h1 class="h1Title">AAM</h1>
    </div>
    <div
      *ngIf="user && user.codiceFiscale"
      class="card header-card"
      style="width: 20rem"
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p><strong>Utente:</strong> {{ user.codiceFiscale }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p><strong>Ruolo:</strong> {{ user.ruolo }}</p>
          </div>
        </div>
        <div
          class="row header-card-seleziona-asset"
          *ngIf="
            user.assetCorrente?.idAsset && listaAsset && listaAsset.length > 0
          "
        >
          <div class="col">
            <p>
              <app-header-seleziona-asset
                [descrizioneAsset]="
                  descrizioneAsset(user.assetCorrente.idAsset)
                "
                [descrizioneOrg]="descrizioneOrg(user.assetCorrente.idOrg)"
                [showOrg]="
                  user.assetCorrente?.idOrg && listaOrg && listaOrg.length > 0
                "
                [showChangeAsset]="showChangeAsset"
              ></app-header-seleziona-asset>
            </p>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              (click)="logout()"
            >
              Logout <i class="fas fa-sign-out-alt"></i>
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div
  class="it-header-navbar-wrapper it-header-sticky theme-light-desk mb-4"
  *ngIf="user && user.codiceFiscale"
>
  <div class="container-header">
    <div class="row">
      <div class="col-12 px-0">
        <!--start nav-->
        <nav
          class="navbar navbar-expand-lg"
          aria-label="Navigazione principale"
        >
          <button
            class="custom-navbar-toggler"
            type="button"
            aria-controls="navC2"
            aria-expanded="false"
            aria-label="Mostra/Nascondi la navigazione"
            data-bs-toggle="navbarcollapsible"
            data-bs-target="#navC2"
          >
            <svg class="icon icon-lg icon-success">
              <use
                href="/bootstrap-italia/dist/svg/sprites.svg#it-burger"
              ></use>
            </svg>
          </button>
          <div class="navbar-collapsable" id="navC2" style="display: none">
            <div class="overlay" style="display: none"></div>
            <div class="close-div">
              <button class="btn close-menu" type="button">
                <span class="visually-hidden">Nascondi la navigazione</span>
                <svg class="icon">
                  <use
                    href="/bootstrap-italia/dist/svg/sprites.svg#it-close-big"
                  ></use>
                </svg>
              </button>
            </div>
            <div class="menu-wrapper">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link link-pointer" routerLink="/home">
                    <it-icon
                      name="pa"
                      size="sm"
                      color="primary"
                      itTooltip="Home"
                      tooltipPlacement="bottom"
                    ></it-icon>
                  </a>
                </li>
                <li class="nav-item dropdown" *ngIf="isAdmin || isAdminOrg">
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="mainNavDropdownC1"
                  >
                    <span>Utenti AAM</span>
                    <svg class="icon icon-xs">
                      <use
                        href="/bootstrap-italia/dist/svg/sprites.svg#it-expand"
                      ></use>
                    </svg>
                  </a>
                  <div
                    class="dropdown-menu"
                    role="region"
                    aria-labelledby="mainNavDropdownC1"
                  >
                    <div class="link-list-wrapper">
                      <!-- <div class="link-list-heading">Sezione</div> -->
                      <ul class="link-list">
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/ricercaUtentiAam"
                            ><span>Ricerca utenti</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/nuovoUtenteAam"
                            ><span>Nuovo utente</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/importUtentiAam"
                            ><span>Importa utenti</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown" *ngIf="isAdmin">
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="mainNavDropdownC2"
                  >
                    <span>Asset</span>
                    <svg class="icon icon-xs">
                      <use
                        href="/bootstrap-italia/dist/svg/sprites.svg#it-expand"
                      ></use>
                    </svg>
                  </a>
                  <div
                    class="dropdown-menu"
                    role="region"
                    aria-labelledby="mainNavDropdownC2"
                  >
                    <div class="link-list-wrapper">
                      <!-- <div class="link-list-heading">Sezione</div> -->
                      <ul class="link-list">
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/gestioneAsset"
                            ><span>Ricerca Asset</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/inserimentoAsset"
                            ><span>Nuovo Asset</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="nav-item dropdown" *ngIf="isAdmin">
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="mainNavDropdownC2"
                  >
                    <span>Strutture Org.</span>
                    <svg class="icon icon-xs">
                      <use
                        href="/bootstrap-italia/dist/svg/sprites.svg#it-expand"
                      ></use>
                    </svg>
                  </a>
                  <div
                    class="dropdown-menu"
                    role="region"
                    aria-labelledby="mainNavDropdownC2"
                  >
                    <div class="link-list-wrapper">
                      <!-- <div class="link-list-heading">Sezione</div> -->
                      <ul class="link-list">
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/struttureOrg"
                            ><span>Ricerca strutture</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/importOrg"
                            ><span>Importa struture</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  class="nav-item dropdown"
                  *ngIf="isUser || isAdmin || isAdminOrg"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id="mainNavDropdownC1"
                  >
                    <span *ngIf="isUser">Gestione Utenti</span>
                    <span *ngIf="isAdmin || isAdminOrg">Utenti Asset</span>
                    <svg class="icon icon-xs">
                      <use
                        href="/bootstrap-italia/dist/svg/sprites.svg#it-expand"
                      ></use>
                    </svg>
                  </a>
                  <div
                    class="dropdown-menu"
                    role="region"
                    aria-labelledby="mainNavDropdownC1"
                  >
                    <div class="link-list-wrapper">
                      <!-- <div class="link-list-heading">Sezione</div> -->
                      <ul class="link-list">
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/ricercaUtentiAsset"
                            ><span>Ricerca utenti</span></a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/ricercaAttributiAsset"
                            ><span>Ricerca attributi</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/nuovoUtenteAsset"
                            ><span>Nuovo utente</span></a
                          >
                        </li>
                        <li><span class="divider"></span></li>
                        <li>
                          <a
                            class="dropdown-item list-item"
                            routerLink="/importUtentiAsset"
                            ><span>Importa utenti</span></a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <ng-container
                    *ngIf="
                      user.assetCorrente?.idAsset &&
                      listaAsset &&
                      listaAsset.length > 0
                    "
                  >
                    <a class="nav-link">
                      <app-header-seleziona-asset
                        [descrizioneAsset]="
                          descrizioneAsset(user.assetCorrente.idAsset)
                        "
                        [descrizioneOrg]="
                          descrizioneOrg(user.assetCorrente.idOrg)
                        "
                        [showOrg]="
                          user.assetCorrente?.idOrg &&
                          listaOrg &&
                          listaOrg.length > 0
                        "
                        [showChangeAsset]="showChangeAsset"
                      ></app-header-seleziona-asset>
                    </a>
                  </ng-container>
                </li>
                <li class="nav-item">
                  <a class="nav-link link-pointer" (click)="logout()"
                    ><span>
                      <it-icon
                        name="logout"
                        size="sm"
                        color="primary"
                        itTooltip="Logout"
                        tooltipPlacement="bottom"
                      ></it-icon></span
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>
