import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import {
  PROFILO_AMMINISTRATORE_REGIONALE,
  PROFILO_AMMINISTRATORE_ASSET,
  PROFILO_AMMINISTRATORE_STRUTTURA,
} from "src/app/tipologiche/profili";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { Subject, Subscription } from "rxjs";
import { NomeValidator } from "src/app/directive/nome.validator";
import { CodiceFiscaleValidator } from "src/app/directive/codice-fiscale.validator";

@Component({
  selector: "app-modifica-utente",
  templateUrl: "./modifica-utente.component.html",
  styleUrls: ["./modifica-utente.component.css"],
})
export class ModificaUtenteComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idUtente: any;
  userSub: Subscription;

  get listaAsset() {
    return this.form.controls["listaAsset"] as FormArray;
  }

  get listaAltriAsset() {
    return this.form.controls["listaAltriAsset"] as FormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    fc: FormCommons,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: null,
      codiceFiscale: ["", [Validators.required, CodiceFiscaleValidator()]],
      cognome: ["", [Validators.required, NomeValidator()]],
      nome: ["", [Validators.required, NomeValidator()]],
      isAmministratoreRegionale: [false],
      isAmministratoreStruttura: [false],
      isAmministratoreAsset: [false],
      listaAsset: new FormArray([]),
      listaAltriAsset: new FormArray([]),
    });

    this.route.params.subscribe((params: Params) => {
      this.idUtente = params["id"];

      this.userSub = this.userService.user.subscribe((usr) => {
        this.userService.get(this.idUtente).then((result) => {
          this.form.patchValue({
            id: this.idUtente,
            codiceFiscale: result?.utenteDto?.codiceFiscale,
            cognome: result?.utenteDto?.cognome,
            nome: result?.utenteDto?.nome,
            isAmministratoreRegionale:
              result?.profilo === PROFILO_AMMINISTRATORE_REGIONALE,
            isAmministratoreStruttura:
              result?.profilo === PROFILO_AMMINISTRATORE_STRUTTURA,
            isAmministratoreAsset:
              result?.profilo === PROFILO_AMMINISTRATORE_ASSET,
          });
          if (usr?.isAdminOrg) {
            this.form.patchValue({
              isAmministratoreAsset: true,
            });
          }
          if (result.asset) {
            for (const asset of result?.asset?.flatMap((a) => {
              const parts = a.split(";");
              if (parts.length == 1) {
                return {
                  asset: parts[0],
                  org: null,
                  listaOrg: [],
                };
              } else if (parts.length > 1) {
                return parts[1].split(",").map((org) => ({
                  asset: parts[0],
                  org: org,
                  listaOrg: [],
                }));
              }
            })) {
              if (
                usr.isAdminOrg &&
                !usr.listaAsset?.some(
                  (a) =>
                    a.asset == asset.asset && a.listaOrg.includes(asset.org)
                )
              ) {
                this.listaAltriAsset.push(this.fb.group(asset));
              } else {
                this.listaAsset.push(this.fb.group(asset));
              }
            }
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.userSub && this.userSub.unsubscribe();
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      utenteDto: {
        codiceFiscale: this.form.value.codiceFiscale,
        cognome: this.form.value.cognome,
        nome: this.form.value.nome,
      },
      profilo: this.form.value.isAmministratoreRegionale
        ? PROFILO_AMMINISTRATORE_REGIONALE
        : this.form.value.isAmministratoreStruttura
        ? PROFILO_AMMINISTRATORE_STRUTTURA
        : this.form.value.isAmministratoreAsset
        ? PROFILO_AMMINISTRATORE_ASSET
        : "",
      asset: this.form.value.isAmministratoreRegionale
        ? null
        : Array.from(
            this.form.value.listaAsset
              .concat(this.form.value.listaAltriAsset)
              .reduce((assetMap, value) => {
                if (assetMap.has(value.asset)) {
                  if (!assetMap.get(value.asset).split(",").includes(value.org))
                    assetMap.set(
                      value.asset,
                      `${assetMap.get(value.asset)},${value.org ?? ""}`
                    );
                } else {
                  assetMap.set(value.asset, value.org ?? "");
                }
                return assetMap;
              }, new Map())
          ).map((a) => `${a[0]};${a[1] ?? ""}`),
    };
    this.userService
      .save(body)
      .then(() => {
        // this.form.reset();
        this.alertService.addDefaultSuccessMessage();
      })
      .catch((e) => {});
  }
}
