import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppInjector } from "src/app/helpers/app-injector";
import { FormCommons } from "src/app/utility/commons";
import {
  SortableHeaderDirective,
  SortEvent,
} from "src/app/directive/sortable-header.directive";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import { Subscription } from "rxjs";
import { UserService } from "src/app/service/user-service";
import { SelectControlOption } from "design-angular-kit/public_api";
import { Router } from "@angular/router";
import { AssetService } from "src/app/service/asset-service";

@Component({
  selector: "app-ricerca-attributi-asset",
  templateUrl: "./ricerca-attributi-asset.component.html",
  styleUrls: ["./ricerca-attributi-asset.component.css"],
})
export class RicercaAttributiAssetComponent
  extends SearchBaseComponent
  implements OnInit
{
  formCommons = AppInjector.get(FormCommons);
  form: UntypedFormGroup;

  pageStatus: string = "SEARCH";
  formTitle: string = "";
  shouldValidate: boolean;

  attributiSelectOptions: Array<SelectControlOption> = [
    { value: "", text: "" },
  ];

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  aziende: any[] = [];
  ricercaAttributiCriteri: any;
  @Input()
  ricercaAttributiList: any[] = undefined;

  userSubscription: Subscription;
  idAsset: number;
  idOrg: number;

  @Output()
  onSearch = new EventEmitter<any>();

  @Output()
  onDownload = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private assetService: AssetService,
    private userService: UserService
  ) {
    super();
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      codiceFiscale: null,
      cognome: null,
      nome: null,
      attributo: [""],
      valore: null,
    });
    this.userSubscription = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);
      this.assetService.getAsset(this.idAsset).then((result) => {
        result?.attributi
          .sort((a, b) => {
            const ca = a.chiave.toUpperCase();
            const cb = b.chiave.toUpperCase();
            if (ca < cb) return -1;
            if (ca > cb) return 1;
            return 0;
          })
          .forEach((element) => {
            this.attributiSelectOptions.push({
              value: element.chiave,
              text: element.chiave,
            });
          });
      });
      if (history.state.searchOnLoad) {
        if (history.state.ricercaAttributiCriteri) {
          this.ricercaAttributiCriteri = history.state.ricercaAttributiCriteri;
          if (
            this.ricercaAttributiCriteri?.chiaveValoreAttributoList?.length > 0
          ) {
            this.form.patchValue({
              attributo:
                this.ricercaAttributiCriteri?.chiaveValoreAttributoList[0]
                  .chiave,
              valore:
                this.ricercaAttributiCriteri?.chiaveValoreAttributoList[0]
                  .valore,
            });
          }

          this.onSearch.emit(this.ricercaAttributiCriteri);
        } else {
          this.submit();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaAttributiCriteri = {
      ...this.ricercaAttributiCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaAttributiCriteri);
  }

  getPage(page: any) {
    this.ricercaAttributiCriteri = {
      ...this.ricercaAttributiCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaAttributiCriteri);
  }

  getCountUtenti(attributo: any) {
    return attributo.countUtenti > 1
      ? `(${attributo.countUtenti} utenti)`
      : attributo.codiceFiscale;
  }

  modificaAttributo(attributo) {
    this.router.navigate(["/modificaAttributoAsset", attributo.idAttributo], {
      state: {
        attributo: attributo,
        ricercaAttributiCriteri: this.ricercaAttributiCriteri,
      },
    });
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaAttributiCriteri = {
      idAsset: this.idAsset,
      idStrutturaOrganizzativa: this.idOrg,
      sortInfo: this.setSortInfo("chiave", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.value.attributo || this.form.value.valore) {
      this.ricercaAttributiCriteri.chiaveValoreAttributoList = [
        {
          chiave: this.form.value.attributo ? this.form.value.attributo : "",
          valore: this.form.value.valore ? this.form.value.valore : "",
        },
      ];
    }
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaAttributiCriteri);
  }
}
