import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { AssetService } from "../../service/asset-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-modifica-struttura-organizzativa",
  templateUrl: "./modifica-struttura-organizzativa.component.html",
  styleUrls: ["./modifica-struttura-organizzativa.component.css"],
})
export class ModificaStrutturaOrganizzativaComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  idOrg: any;
  ruoli: any[] = [];

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    fc: FormCommons,
    private spinner: NgxSpinnerService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [""],
      descrizione: ["", [Validators.required, Validators.maxLength(50)]],
    });

    this.route.params.subscribe((params: Params) => {
      this.idOrg = params["id"];

      this.strutturaOrganizzativaService.getOrg(this.idOrg).then((result) => {
        this.form.patchValue({
          id: result?.id,
          descrizione: result?.descrizione,
        });
      });
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      id: this.form.value.id,
      descrizione: this.form.value.descrizione,
      fkIdTipoStrutturaOrganizzativa: { id: 1 },
    };
    this.strutturaOrganizzativaService
      .update(body.id, body, false)
      .then(() => {
        this.router.navigate(["/struttureOrg"], {
          state: {
            searchOnLoad: true,
          },
        });
      })
      .catch((error) => {
        this.spinner.hide();
        // this.alertService.addDefaultErrorMessage();
      });
  }
}
