import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AppInjector } from "src/app/helpers/app-injector";
import { FormCommons } from "src/app/utility/commons";
import {
  SortableHeaderDirective,
  SortEvent,
} from "src/app/directive/sortable-header.directive";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import { Observable, Subscription } from "rxjs";
import { UserService } from "src/app/service/user-service";
import { SelectControlOption } from "design-angular-kit/public_api";
import { Router } from "@angular/router";
import {
  IdRuolo,
  PROFILO_AMMINISTRATORE_ASSET,
  PROFILO_AMMINISTRATORE_ASSET_DESC,
  PROFILO_AMMINISTRATORE_ASSET_ID,
  PROFILO_AMMINISTRATORE_REGIONALE,
  PROFILO_AMMINISTRATORE_REGIONALE_DESC,
  PROFILO_AMMINISTRATORE_REGIONALE_ID,
  PROFILO_AMMINISTRATORE_STRUTTURA,
  PROFILO_AMMINISTRATORE_STRUTTURA_DESC,
  PROFILO_AMMINISTRATORE_STRUTTURA_ID,
} from "src/app/tipologiche/profili";

@Component({
  selector: "app-ricerca-utenti",
  templateUrl: "./ricerca-utenti.component.html",
  styleUrls: ["./ricerca-utenti.component.css"],
})
export class RicercaUtentiComponent
  extends SearchBaseComponent
  implements OnInit
{
  formCommons = AppInjector.get(FormCommons);
  form: UntypedFormGroup;

  pageStatus: string = "SEARCH";
  formTitle: string = "";
  shouldValidate: boolean;

  ruoliSelectOptions: Array<SelectControlOption> = [
    { value: "", text: "Seleziona un ruolo" },
    {
      value: PROFILO_AMMINISTRATORE_REGIONALE_ID,
      text: PROFILO_AMMINISTRATORE_REGIONALE_DESC,
    },
    {
      value: PROFILO_AMMINISTRATORE_STRUTTURA_ID,
      text: PROFILO_AMMINISTRATORE_STRUTTURA_DESC,
    },
    {
      value: PROFILO_AMMINISTRATORE_ASSET_ID,
      text: PROFILO_AMMINISTRATORE_ASSET_DESC,
    },
  ];

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  idAsset: string;
  idOrg: string;
  isAdmin: boolean;
  isAdminOrg: boolean;
  codiceFiscaleCurrentUser: boolean;
  ricercaUtentiCriteri: any;
  userSub: Subscription;
  searchSub: Subscription;

  @Input()
  ricercaUtentiList: any[] = undefined;

  @Input()
  search: Observable<void>;

  @Output()
  onSearch = new EventEmitter<any>();

  @Output()
  onDownload = new EventEmitter<any>();

  @Output()
  onDelete = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      codiceFiscale: null,
      cognome: null,
      nome: null,
      ruolo: "",
    });
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);

      if (history.state.searchOnLoad) {
        if (history.state.ricercaUtentiCriteri) {
          this.ricercaUtentiCriteri = history.state.ricercaUtentiCriteri;
          this.form.patchValue(this.ricercaUtentiCriteri);
          if (this.ricercaUtentiCriteri.profilo) {
            this.form.patchValue({
              ruolo: IdRuolo(this.ricercaUtentiCriteri.profilo),
            });
          }
          this.onSearch.emit(this.ricercaUtentiCriteri);
        } else {
          this.submit();
        }
      }
      this.searchSub = this.search.subscribe(() => {
        this.submit();
      });
    });
  }

  ngOnDestroy(): void {
    this.userSub && this.userSub.unsubscribe();
    this.searchSub && this.searchSub.unsubscribe();
  }

  manageUser(user: any) {
    this.isAdmin = user.isAdmin;
    this.isAdminOrg = user.isAdminOrg;
    this.codiceFiscaleCurrentUser = user.codiceFiscale;
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  getPage(page: any) {
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  eliminaUtente($event) {
    this.onDelete.emit($event);
  }

  importaUtenti() {
    this.router.navigate(["/importUtentiAam"], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  nuovoUtente() {
    this.router.navigate(["/inserimentoUtente"], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  modificaUtente(utente) {
    this.router.navigate(["/modificaUtente", utente.idUtente], {
      state: {
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  submit() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaUtentiCriteri = {
      sortInfo: this.setSortInfo("codiceFiscale", "ASC"),
      pageInfo: this.initializePageInfo(),
    };
    if (this.form.value.codiceFiscale)
      this.ricercaUtentiCriteri.codiceFiscale = this.form.value.codiceFiscale;
    if (this.form.value.cognome)
      this.ricercaUtentiCriteri.cognome = this.form.value.cognome;
    if (this.form.value.nome)
      this.ricercaUtentiCriteri.nome = this.form.value.nome;
    if (this.form.value.ruolo) {
      this.ricercaUtentiCriteri.profilo =
        this.form.value.ruolo == PROFILO_AMMINISTRATORE_REGIONALE_ID
          ? PROFILO_AMMINISTRATORE_REGIONALE
          : this.form.value.ruolo == PROFILO_AMMINISTRATORE_STRUTTURA_ID
          ? PROFILO_AMMINISTRATORE_STRUTTURA
          : PROFILO_AMMINISTRATORE_ASSET;
    }
    if (this.form.invalid) {
      return;
    }
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  esportaUtenti() {
    this.shouldValidate = true;
    this.form.markAllAsTouched();
    this.ricercaUtentiCriteri = {
      // idAsset: this.idAsset,
      // idStrutturaOrganizzativa: this.idOrg,
    };
    if (this.form.value.codiceFiscale)
      this.ricercaUtentiCriteri.codiceFiscale = this.form.value.codiceFiscale;
    if (this.form.value.cognome)
      this.ricercaUtentiCriteri.cognome = this.form.value.cognome;
    if (this.form.value.nome)
      this.ricercaUtentiCriteri.nome = this.form.value.nome;
    if (this.form.value.ruolo) {
      this.ricercaUtentiCriteri.profilo =
        this.form.value.ruolo == PROFILO_AMMINISTRATORE_REGIONALE_ID
          ? PROFILO_AMMINISTRATORE_REGIONALE
          : this.form.value.ruolo == PROFILO_AMMINISTRATORE_STRUTTURA_ID
          ? PROFILO_AMMINISTRATORE_STRUTTURA
          : PROFILO_AMMINISTRATORE_ASSET;
    }
    if (this.form.invalid) {
      return;
    }
    this.onDownload.emit(this.ricercaUtentiCriteri);
  }
}
