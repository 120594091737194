<div>
  <div class="wrapper">
    <app-header></app-header>
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="medium"
      color="#fff"
      type="ball-spin"
      [fullScreen]="true"
    >
      <!-- <p style="color: white"> Caricamento... </p> -->
      <it-spinner></it-spinner>
    </ngx-spinner>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>

<it-notifications></it-notifications>
