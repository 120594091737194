import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { SelectControlOption } from "design-angular-kit/public_api";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { SearchBaseComponent } from "src/app/page/search-base-component";
import {
  SortEvent,
  SortableHeaderDirective,
} from "src/app/directive/sortable-header.directive";
import { DateUtils } from "src/app/utility/date-utils";
import { AssetService } from "src/app/service/asset-service";
import {
  TIPO_ATTRIBUTO_BOOL_ID,
  TIPO_ATTRIBUTO_DATA_ID,
  TIPO_ATTRIBUTO_NUMERO_ID,
} from "src/app/tipologiche/tipo_attributo";
import { REGEX_NUMERO } from "src/app/tipologiche/validazioni";
import * as _ from "lodash";

@Component({
  selector: "app-dati-attributo-asset",
  templateUrl: "./dati-attributo-asset.component.html",
  styleUrls: ["./dati-attributo-asset.component.css"],
})
export class DatiAttributoAssetComponent
  extends SearchBaseComponent
  implements OnInit, OnDestroy
{
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;
  private _formUtenteValue: UntypedFormGroup;

  showAsset: boolean = false;
  showListaUtenti: boolean = true;

  attributiSelectOptions: SelectControlOption[] = [];

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  get formUtente(): UntypedFormGroup {
    return this._formUtenteValue;
  }

  set formUtente(form: UntypedFormGroup) {
    this._formUtenteValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  ricercaUtentiCriteri: any;
  @Input()
  ricercaUtentiList: any[] = undefined;

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSaveAttributo = new EventEmitter<any[]>();

  @Output()
  onRemoveUtente = new EventEmitter<any>();

  @Output()
  onSaveUtente = new EventEmitter<void>();

  @Output()
  onSearch = new EventEmitter<any>();

  codiceFiscaleSub: Subscription;
  cognomeSub: Subscription;
  nomeSub: Subscription;
  assetSub: Subscription;
  userSubscription: Subscription;
  idAttributo: number;
  idAsset: number;
  idOrg: number;
  attributiAsset: any[];
  ricercaAttributiCriteri: any;

  constructor(
    private assetService: AssetService,
    private userService: UserService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.pageStatus === "NEW") {
    }

    this.userSubscription = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);

      this.assetSub = this.assetService.currentAsset$.subscribe((asset) => {
        this.attributiAsset = asset?.attributi;

        if (history.state.attributo) {
          this.idAttributo = history.state.attributo.idAttributo;

          const listaValori = _.sortBy(
            this.assetService.getValoriAttributo(
              this.idAttributo,
              this.attributiAsset
            ),
            ["value"]
          );
          const tipoAttributo = this.assetService.getTipoAttributo(
            this.idAttributo,
            this.attributiAsset
          );
          const regexp =
            tipoAttributo == TIPO_ATTRIBUTO_NUMERO_ID
              ? REGEX_NUMERO
              : this.assetService.getRegexAttributo(
                  this.idAttributo,
                  this.attributiAsset
                );

          this.form.patchValue({
            chiave: history.state.attributo.chiave,
            valore: history.state.attributo.valore,
            listaValori,
            isDate: tipoAttributo == TIPO_ATTRIBUTO_DATA_ID,
            regexp: regexp,
          });
        }
        if (history.state.ricercaAttributiCriteri) {
          this.ricercaAttributiCriteri = history.state.ricercaAttributiCriteri;
        }

        this.ricercaUtentiCriteri = {
          idAsset: this.idAsset,
          idStrutturaOrganizzativa: this.idOrg,
          soloUtentiValidi: false,
          caricaAttributi: true,
          chiaveValoreAttributoList: [
            {
              chiave: this.form.controls.chiave.value,
              valore: this.form.value.valore,
            },
          ],
          sortInfo: this.setSortInfo("codiceFiscale", "ASC"),
          pageInfo: this.initializePageInfo(),
        };
        this.onSearch.emit(this.ricercaUtentiCriteri);
      });
    });
    let codiceFiscaleControl = this.formUtente.get("codiceFiscale");
    this.codiceFiscaleSub = codiceFiscaleControl.valueChanges.subscribe(() => {
      if (codiceFiscaleControl.value) {
        codiceFiscaleControl.patchValue(
          codiceFiscaleControl.value.toUpperCase(),
          {
            emitEvent: false,
          }
        );
      }
    });
    let cognomeControl = this.formUtente.get("cognome");
    this.cognomeSub = cognomeControl.valueChanges.subscribe(() => {
      if (cognomeControl.value) {
        cognomeControl.patchValue(cognomeControl.value.toUpperCase(), {
          emitEvent: false,
        });
      }
    });
    let nomeControl = this.formUtente.get("nome");
    this.nomeSub = nomeControl.valueChanges.subscribe(() => {
      if (nomeControl.value) {
        nomeControl.patchValue(nomeControl.value.toUpperCase(), {
          emitEvent: false,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.codiceFiscaleSub.unsubscribe();
    this.cognomeSub.unsubscribe();
    this.nomeSub.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  onSort({ column, direction }: SortEvent) {
    // reset header
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      sortInfo: this.setSortInfo(column, direction),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  getPage(page: any) {
    this.ricercaUtentiCriteri = {
      ...this.ricercaUtentiCriteri,
      pageInfo: this.setPageInfo(
        page,
        this.pageSize,
        this.numberOfPages,
        this.numberOfItems
      ),
    };
    this.onSearch.emit(this.ricercaUtentiCriteri);
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  isUtenteAttivo(utente): boolean {
    const attributo = utente.valoreAttributoList.find(
      (v) =>
        v.fkIdAttributo == this.idAttributo &&
        v.valore == this.form.value.valore
    );
    return DateUtils.isBeDateValid(attributo?.dataInizio, attributo?.dataFine);
  }

  nuovoUtente() {
    this.showListaUtenti = false;
  }

  annullaNuovoUtente() {
    this.showListaUtenti = true;
    this.formUtente.patchValue({
      codiceFiscale: "",
      nome: "",
      cognome: "",
    });
  }

  removeUtente(utente) {
    this.onRemoveUtente.emit(utente);
  }

  tornaRicercaAttributi(): void {
    this.router.navigate(["/ricercaAttributiAsset"], {
      state: {
        searchOnLoad: true,
        ricercaAttributiCriteri: this.ricercaAttributiCriteri,
      },
    });
  }

  save() {
    this.onSaveAttributo.emit(this.ricercaUtentiList);
  }

  saveUtente() {
    this.onSaveUtente.emit();
    this.showListaUtenti = true;
  }
}
