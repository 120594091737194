<h3 class="mb-4">{{ formTitle }}</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionDatiUtente">
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati utente
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group form-group-b-0">
              <it-input
                [readonly]="form.value.id ? 'true' : null"
                label="Codice Fiscale *"
                formControlName="codiceFiscale"
                #codiceFiscale
              >
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="codiceFiscale.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="codiceFiscale.hasError('codicefiscale')">
                    Codice Fiscale non valido
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Cognome *" formControlName="cognome" #cognome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="cognome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="cognome.hasError('nome')">
                    Presenti caratteri non validi
                  </div>
                </ng-container>
              </it-input>
            </div>
            <div class="form-group col-md-6 form-group-b-0">
              <it-input label="Nome *" formControlName="nome" #nome>
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="nome.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="nome.hasError('nome')">
                    Presenti caratteri non validi
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1b">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1b"
          aria-expanded="true"
          aria-controls="collapse1b"
        >
          Ruolo
        </button>
      </h2>
      <div
        id="collapse1b"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1b"
      >
        <div class="accordion-body">
          <div class="row">
            <div class="form-group">
              <div class="row" *ngIf="isAdmin">
                <div class="col-12 col-md-8 col-lg-6">
                  <it-checkbox
                    label="Amministratore Regionale"
                    toggle="true"
                    formControlName="isAmministratoreRegionale"
                  ></it-checkbox>
                </div>
              </div>
              <div class="row" *ngIf="isAdmin">
                <div class="col-12 col-md-8 col-lg-6">
                  <it-checkbox
                    label="Amministratore Struttura"
                    toggle="true"
                    formControlName="isAmministratoreStruttura"
                  ></it-checkbox>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-8 col-lg-6">
                  <it-checkbox
                    label="Amministratore Asset"
                    toggle="true"
                    formControlName="isAmministratoreAsset"
                  ></it-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="accordion-item"
      *ngIf="
        form.value.isAmministratoreAsset || form.value.isAmministratoreStruttura
      "
    >
      <h2 class="accordion-header" id="heading1c">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1c"
          aria-expanded="true"
          aria-controls="collapse1c"
        >
          Asset
        </button>
      </h2>
      <div
        id="collapse1c"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1c"
      >
        <div class="accordion-body">
          <ng-container formArrayName="listaAsset">
            <ng-container
              *ngFor="let assetGroup of listaAsset.controls; index as i"
            >
              <ng-container [formGroup]="assetGroup">
                <div class="row mt-4">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <it-select
                          label="Asset"
                          formControlName="asset"
                          [options]="assetSelectOptions"
                        >
                          <ng-container error>
                            <div
                              *ngIf="
                                assetGroup.controls.asset.hasError('required')
                              "
                            >
                              Il campo è obbligatorio
                            </div>
                          </ng-container>
                        </it-select>
                      </div>
                      <div class="form-group col-md-6">
                        <it-select
                          label="Struttura organizzativa"
                          formControlName="org"
                          [options]="assetGroup.value.listaOrg"
                        >
                        </it-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-1">
                    <app-modal-search
                      [itemsList]="assetGroup.value.listaOrg"
                      title="Struttura Organizzativa"
                      (onConfirm)="selezionaOrg($event, assetGroup)"
                    ></app-modal-search>
                  </div>
                  <div class="form-group col-md-1">
                    <it-icon
                      name="delete"
                      (click)="rimuoviAsset(i)"
                      itTooltip="Rimuovi asset"
                      tooltipPlacement="bottom"
                      class="link-pointer"
                    ></it-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row">
              <div class="form-group col-md-6">
                <a class="link-pointer" (click)="aggiungiAsset()"
                  >Aggiungi asset</a
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaRicercaUtenti()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        Ricerca Utenti
      </button>
    </div>
    <div class="col-auto">
      <button
        type="submit"
        [disabled]="
          !form.value.isAmministratoreRegionale &&
          !form.value.isAmministratoreStruttura &&
          !form.value.isAmministratoreAsset
        "
        class="btn btn-primary"
      >
        Salva
      </button>
    </div>
  </div>
</form>
