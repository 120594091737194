import { SortDirection } from 'src/app/directive/sortable-header.directive';
import { environment } from 'src/environments/environment';


export class SearchBaseComponent {

  constructor() { }

  page: number;
  pageSize: number;
  numberOfPages: number;
  numberOfItems: number;
  

  setPaginatorValues (pageInfo) {
    if(pageInfo == undefined || pageInfo == null) {
      return;
    }
    this.page = pageInfo.page;
    this.pageSize = environment.itemsPerPage;
    this.numberOfPages = pageInfo.numberOfPages;
    this.numberOfItems = pageInfo.numberOfItems;
  }

  initializePageInfo() {
    let pageInfo = {
      maxResults: environment.itemsPerPage,
      numberOfPages: 0,
      numberOfItems: 0,
      page: 0,
    }
    return pageInfo;
  }

  setPageInfo(page: number, pageSize: number, numberOfPages: number, numberOfItems: number) {
    const pageInfo = this.initializePageInfo();
    pageInfo.numberOfPages = numberOfPages;
    pageInfo.numberOfItems = numberOfItems;
    pageInfo.maxResults = pageSize;
    pageInfo.page = page;
    return pageInfo;
  }

  setSortInfo(sortProperty: string, sortDirection: SortDirection) {
    return {
      sortProperty,
      sortDirection
    };
  }

  get numeroVoci(): string {
    let voci="";
    if (this.numberOfItems && this.numberOfPages && this.page >= 0) {
      if(((this.page + 1) * this.pageSize) > this.numberOfItems) {
        voci = "" + (this.numberOfItems % this.pageSize);
      } else {
        voci = "" + this.pageSize;
      }
      voci = voci.concat(` di  ${this.numberOfItems}`);
    }
    return voci;
  }


}
