import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { Router } from "@angular/router";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";

@Component({
  selector: "app-dati-struttura-organizzativa",
  templateUrl: "./dati-struttura-organizzativa.component.html",
  styleUrls: ["./dati-struttura-organizzativa.component.css"],
})
export class DatiStrutturaOrganizzativaComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  constructor(
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.pageStatus === "NEW") {
    }
  }

  ngOnDestroy(): void {}

  tornaRicercaOrg(): void {
    this.router.navigate(["/struttureOrg"], {
      state: {
        searchOnLoad: true,
      },
    });
  }

  save() {
    this.onSubmit.emit();
  }
}
