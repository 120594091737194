import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RicercaUtentiAssetService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/utente`);
  }

  storeCriteriRicerca(criteri: any) {
    sessionStorage.setItem("srch-usr-criteria", JSON.stringify(criteri));
  }

  getCriteriRicercaStored() {
    let criteri = sessionStorage.getItem("srch-usr-criteria");
    if (criteri) {
      return JSON.parse(criteri);
    }
    return {};
  }

  search(
    jsonInput: any,
    hideSpinnerOnCompletion: boolean = false
  ): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      ...jsonInput,
      // page: jsonInput.pageInfo.page,
      // size: jsonInput.pageInfo.maxResults,
      pageable: {
        page: jsonInput.pageInfo?.page ?? 0,
        size: jsonInput.pageInfo?.maxResults ?? environment.itemsPerPage,
      },
      pageInfo: null,
    };
    if (jsonInput.sortInfo) {
      jsonParams = {
        ...jsonParams,
        sortable: {
          sortList: [
            {
              sortProperty: jsonInput.sortInfo.sortProperty,
              sortDirection: jsonInput.sortInfo.sortDirection,
            },
          ],
        },
        // sort: jsonInput.sortInfo.sortProperty,
        // sortDirection: jsonInput.sortInfo.sortDirection,
        sortInfo: null,
      };
    }
    this.spinner.show();

    return super
      .post("ricerca", jsonParams)
      .then((result) => {
        if (!hideSpinnerOnCompletion) this.spinner.hide();
        return result;
      })
      .catch((error) => {
        this.spinner.hide();
        throw error;
      });
  }
}
