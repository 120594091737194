<app-container>
  <app-import-file-excel
    [(form)]="form"
    [importType]="'UTENTI'"
    [formTitle]="'Importazione Utenti'"
    [textPulsanteRicerca]="'Ricerca Utenti'"
    [ricercaImportList]="ricercaImportList"
    (onBackToRicerca)="tornaRicercaUtenti()"
    (onSearchImport)="ricercaImport($event)"
  >
  </app-import-file-excel>
</app-container>
