import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class TipoAttributoService extends BaseService {
  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(
      http,
      `${environment.apiPath}/${environment.apiVersion}/tipo-attributo`
    );
  }

  getAll(): Promise<any> {
    this.spinner.show();
    // let tipoAttributi = this.getTipoAttributiStored();
    // if (tipoAttributi) {
    //   return Promise.resolve(tipoAttributi);
    // } else {
      return super.get("").then((result) => {
        this.storeTipoAttributi(result);
        this.spinner.hide();
        return result;
      });
    // }
  }

  storeTipoAttributi(tipoAttributi: any) {
    sessionStorage.setItem("tipoAttributi", JSON.stringify(tipoAttributi));
  }

  getTipoAttributiStored() {
    let tipoattr = sessionStorage.getItem("tipoAttributi");
    if (tipoattr) {
      return JSON.parse(tipoattr);
    }
    return {};
  }
}
