<div class="accordion accordion-background-active" id="accordionRicercaAsset">
  <div class="accordion-item">
    <h2 class="accordion-header" id="heading1a">
      <button
        class="accordion-button py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse1a"
        aria-expanded="true"
        aria-controls="collapse1a"
      >
        Criteri di ricerca
      </button>
    </h2>
    <div
      id="collapse1a"
      class="accordion-collapse collapse show"
      role="region"
      aria-labelledby="heading1a"
    >
      <div class="accordion-body">
        <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">
          <div class="row mt-4">
            <div class="form-group col-md-8">
              <it-input
                label="Struttura Organizzativa"
                formControlName="descrizione"
                autocomplete="descr-org"
              ></it-input>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col">
              <button type="reset" itButton="light">Svuota Campi</button>
              &nbsp;
              <button type="submit" itButton="primary" [disabled]="!form.valid">
                Ricerca
              </button>
            </div>
            <div class="col-auto">
              <button type="button" itButton="secondary" (click)="nuovaOrg()">
                Nuova Struttura
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="ricercaOrgList">
    <h2 class="accordion-header" id="heading1b">
      <button
        class="accordion-button py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapse1b"
        aria-expanded="true"
        aria-controls="collapse1b"
      >
        Risultati ricerca
      </button>
    </h2>
    <div
      id="collapse1b"
      class="accordion-collapse collapse show"
      role="region"
      aria-labelledby="heading1b"
    >
      <div class="accordion-body">
        <div id="risultatiRicercaOrg" class="mt-4" *ngIf="ricercaOrgList">
          <!-- [ngbCollapse]="!ricercaAssetList"> -->
          <div class="table-responsive-md">
            <it-table hover="true">
              <ng-container
                thead
                *ngIf="ricercaOrgList && ricercaOrgList.length > 0"
              >
                <tr>
                  <th scope="col" sortable="id" (sort)="onSort($event)">Id</th>
                  <th
                    scope="col"
                    sortable="descrizione"
                    (sort)="onSort($event)"
                  >
                    Struttura Organizzativa
                  </th>
                  <th scope="col"></th>
                </tr>
              </ng-container>
              <ng-container
                tbody
                *ngIf="ricercaOrgList && ricercaOrgList.length === 0"
              >
                <tr>
                  <td colspan="3">
                    <h6 class="mt-2">
                      Nessuna struttura organizzativa trovata
                    </h6>
                  </td>
                </tr>
              </ng-container>
              <ng-container
                tbody
                *ngIf="ricercaOrgList && ricercaOrgList.length !== 0"
              >
                <tr *ngFor="let org of ricercaOrgList">
                  <td>
                    <ngb-highlight [result]="org.id"></ngb-highlight>
                  </td>
                  <td>
                    <ngb-highlight [result]="org.descrizione"></ngb-highlight>
                  </td>
                  <td style="text-align: right">
                    <button
                      id="modificaOrgButton"
                      itTooltip="Modifica Struttura Organizzativa"
                      tooltipPlacement="bottom"
                      class="btn py-0"
                      type="button"
                      (click)="modificaOrg(org)"
                    >
                      <it-icon name="pencil"></it-icon>
                    </button>
                    <!-- <button
                      id="eliminaOrgButton"
                      title="Elimina Struttura Organizzativa"
                      class="btn py-0"
                      type="button"
                      (click)="eliminaOrg(org)"
                    >
                      <it-icon name="delete"></it-icon>
                    </button> -->
                  </td>
                </tr>
              </ng-container>
            </it-table>
          </div>
          <div
            id="paginazione"
            *ngIf="ricercaOrgList && ricercaOrgList.length !== 0"
          >
            <div class="row">
              <div class="input-group col-md-4">
                <label for="numero-voci">Numero Strutture Organizzative:</label>
                <div class="ml-3">&nbsp;{{ numeroVoci }}</div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <it-pagination
                    [currentPage]="page"
                    [pageNumbers]="numberOfPages"
                    alignment="center"
                    (pageEvent)="getPage($event)"
                  ></it-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-between"
          *ngIf="ricercaOrgList && ricercaOrgList.length !== 0"
        >
          <div class="col">&nbsp;</div>
          <div class="col-auto">
            <button
              class="mx-2"
              type="button"
              itButton="secondary"
              (click)="esportaOrg()"
              [disabled]="!form.valid"
            >
              Esporta Strutture
            </button>
            <button
              class="mx-2"
              type="button"
              itButton="outline-secondary"
              (click)="importaOrg()"
            >
              Importa Strutture
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
