import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import { AlertService } from '../utility/alert-service'
import {AppInjector} from '../helpers/app-injector';
import {UserService} from './user-service';
import { pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  alertService = AppInjector.get(AlertService);
  userService = AppInjector.get(UserService);

  constructor(http: HttpClient) {
    super(http, '');
  }

  login(body: any): Promise<any> {
    return super.post('login',body).then(result => {
      return this.userService.get(body.username);
    }).catch(error=>{
      if(error.status === 401) {
        this.alertService.addErrorMessage('Errore','Credenziali errate');
      }
    });
  }

  logout(): Promise<any>  {
    return new Promise<any>((resolve, reject) => {
      this.userService.deleteUser();
      resolve({});
    });
  }
}
