import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormCommons {

    constructor() { }

    isFieldInvalid(form: UntypedFormGroup, field: string) {
        let formField = form.get(field);
        if (formField?.untouched) {
            return false;
        }
        return formField?.invalid;
    }

    setAllTouched(form: UntypedFormGroup) {
        form.markAllAsTouched();
    }

    compareSelectOption(s1: any, s2: any): boolean {
        return s1 && s2 ? s1.id === s2.id : s1 === s2;
    } 
}