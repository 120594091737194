import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-nuovo-utente-aam",
  templateUrl: "./nuovo-utente-aam.component.html",
  styleUrls: ["./nuovo-utente-aam.component.css"],
})
export class NuovoUtenteAamComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.navigate(["/inserimentoUtente"]);
  }
}
