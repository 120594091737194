<app-container>
  <app-dati-utente-asset
    [(form)]="form"
    [pageStatus]="'NEW'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Nuovo Utente'"
    (onSubmit)="onSave()"
  >
  </app-dati-utente-asset>

  <app-modal-confirm
    #confirmModal
    title="Nuovo Utente"
    description="Vuoi confermare la creazione dell'utente?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
