import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import localeItExtra from "@angular/common/locales/extra/it";
import { Injector, NgModule } from "@angular/core";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FooterComponent } from "./component/footer/footer.component";
import { HeaderComponent } from "./component/header/header.component";
import { HomeComponent } from "./page/home/home.component";
import { InterceptorService } from "./service/token/interceptor.service";
import { UtenteNonAbilitatoComponent } from "./page/utente-non-abilitato/utente-non-abilitato.component";
import { SessioneScadutaComponent } from "./page/sessione-scaduta/sessione-scaduta.component";
import { LoginPageComponent } from "./page/login-page/login-page.component";
import { LogoutPageComponent } from "./page/logout-page/logout-page.component";
import { setAppInjector } from "./helpers/app-injector";
import { ContainerComponent } from "./component/container/container.component";
import { DatiUtenteComponent } from "./component/dati-utente/dati-utente.component";
import { DatiAssetComponent } from "./component/dati-asset/dati-asset.component";
import { DatiAttributoComponent } from "./component/dati-attributo/dati-attributo.component";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "./utility/custom-date-parser-formatter";
import { SortableHeaderDirective } from "./directive/sortable-header.directive";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RicercaUtentiComponent } from "./component/ricerca-utenti/ricerca-utenti.component";
import { RicercaUtentiAamComponent } from "./page/ricerca-utenti-aam/ricerca-utenti-aam.component";
import { InserimentoNuovoUtenteComponent } from "./page/inserimento-nuovo-utente/inserimento-nuovo-utente.component";
import { RicercaAssetComponent } from "./component/ricerca-asset/ricerca-asset.component";
import { GestioneAssetComponent } from "./page/gestione-asset/gestione-asset.component";
import { InserimentoNuovoAssetComponent } from "./page/inserimento-nuovo-asset/inserimento-nuovo-asset.component";
import { DesignAngularKitModule, ItComponentsModule } from "design-angular-kit";
import {
  TranslateModule,
  TranslateLoader,
  TranslateStore,
  MissingTranslationHandler,
} from "@ngx-translate/core";
import { ModificaUtenteComponent } from "./page/modifica-utente/modifica-utente.component";
import { ModificaAssetComponent } from "./page/modifica-asset/modifica-asset.component";
import { InserimentoNuovoAttributoComponent } from "./page/inserimento-nuovo-attributo/inserimento-nuovo-attributo.component";
import { ModificaAttributoComponent } from "./page/modifica-attributo/modifica-attributo.component";
import { RicercaUtentiAssetComponent } from "./component/ricerca-utenti-asset/ricerca-utenti-asset.component";
import { RicercaUtentiAssetPageComponent } from "./page/ricerca-utenti-asset-page/ricerca-utenti-asset-page.component";
import { InserimentoNuovoUtenteAssetComponent } from "./page/inserimento-nuovo-utente-asset/inserimento-nuovo-utente-asset.component";
import { DatiUtenteAssetComponent } from "./component/dati-utente-asset/dati-utente-asset.component";
import { ModificaUtenteAssetComponent } from "./page/modifica-utente-asset/modifica-utente-asset.component";
import { RicercaAttributiAssetComponent } from "./component/ricerca-attributi-asset/ricerca-attributi-asset.component";
import { RicercaAttributiAssetPageComponent } from "./page/ricerca-attributi-asset-page/ricerca-attributi-asset-page.component";
import { ImportFileExcelComponent } from "./component/import-file-excel/import-file-excel.component";
import { ImportUtentiAssetPageComponent } from "./page/import-utenti-asset-page/import-utenti-asset-page.component";
import { ImportUtentiAamPageComponent } from "./page/import-utenti-aam-page/import-utenti-aam-page.component";
import { ModificaAttributoAssetComponent } from "./page/modifica-attributo-asset/modifica-attributo-asset.component";
import { DatiAttributoAssetComponent } from "./component/dati-attributo-asset/dati-attributo-asset.component";
import { InserimentoNuoviAttributiUtentiAssetComponent } from "./page/inserimento-nuovi-attributi-utenti-asset/inserimento-nuovi-attributi-utenti-asset.component";
import { SelezionaAssetPageComponent } from "./page/seleziona-asset-page/seleziona-asset-page.component";
import { SelezionaAssetComponent } from "./component/seleziona-asset/seleziona-asset.component";
import { HeaderSelezionaAssetComponent } from "./component/header-seleziona-asset/header-seleziona-asset.component";
import { ModalConfirmComponent } from "./component/modal-confirm/modal-confirm.component";
import { ModalSearchComponent } from "./component/modal-search/modal-search.component";
import { StruttureOrganizzativeComponent } from "./page/strutture-organizzative/strutture-organizzative.component";
import { RicercaStruttureOrganizzativeComponent } from "./component/ricerca-strutture-organizzative/ricerca-strutture-organizzative.component";
import { InserimentoNuovaStrutturaOrganizzativaComponent } from "./page/inserimento-nuova-struttura-organizzativa/inserimento-nuova-struttura-organizzativa.component";
import { ModificaStrutturaOrganizzativaComponent } from "./page/modifica-struttura-organizzativa/modifica-struttura-organizzativa.component";
import { DatiStrutturaOrganizzativaComponent } from "./component/dati-struttura-organizzativa/dati-struttura-organizzativa.component";
import { ImportStruttureOrganizzativePageComponent } from "./page/import-strutture-organizzative-page/import-strutture-organizzative-page.component";
import { NuovoUtenteAamComponent } from "./page/nuovo-utente-aam/nuovo-utente-aam.component";
import { NuovoUtenteAssetComponent } from "./page/nuovo-utente-asset/nuovo-utente-asset.component";
import { CookieService } from "ng2-cookies";
// registerLocaleData(localeIt, "it", localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    UtenteNonAbilitatoComponent,
    SessioneScadutaComponent,
    LoginPageComponent,
    LogoutPageComponent,
    ContainerComponent,
    InserimentoNuovoUtenteComponent,
    ModificaUtenteComponent,
    DatiUtenteComponent,
    SortableHeaderDirective,
    RicercaUtentiAamComponent,
    RicercaUtentiComponent,
    GestioneAssetComponent,
    RicercaAssetComponent,
    InserimentoNuovoAssetComponent,
    DatiAssetComponent,
    ModificaAssetComponent,
    DatiAttributoComponent,
    InserimentoNuovoAttributoComponent,
    ModificaAttributoComponent,
    RicercaUtentiAssetComponent,
    RicercaUtentiAssetPageComponent,
    InserimentoNuovoUtenteAssetComponent,
    ModificaUtenteAssetComponent,
    DatiUtenteAssetComponent,
    RicercaAttributiAssetComponent,
    RicercaAttributiAssetPageComponent,
    ImportFileExcelComponent,
    ImportUtentiAssetPageComponent,
    ImportUtentiAamPageComponent,
    ModificaAttributoAssetComponent,
    DatiAttributoAssetComponent,
    InserimentoNuoviAttributiUtentiAssetComponent,
    SelezionaAssetComponent,
    SelezionaAssetPageComponent,
    HeaderSelezionaAssetComponent,
    ModalConfirmComponent,
    ModalSearchComponent,
    StruttureOrganizzativeComponent,
    RicercaStruttureOrganizzativeComponent,
    DatiStrutturaOrganizzativaComponent,
    InserimentoNuovaStrutturaOrganizzativaComponent,
    ModificaStrutturaOrganizzativaComponent,
    ImportStruttureOrganizzativePageComponent,
    NuovoUtenteAamComponent,
    NuovoUtenteAssetComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DesignAngularKitModule,
    TranslateModule.forRoot(),
    ItComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: "it" },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    NgbActiveModal,
    TranslateStore,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
