import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { AssetService } from "src/app/service/asset-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dati-asset",
  templateUrl: "./dati-asset.component.html",
  styleUrls: ["./dati-asset.component.css"],
})
export class DatiAssetComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;
  ricercaAssetCriteri: any;
  searchSub: Subscription;

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  @Output()
  onDeleteAttributo = new EventEmitter<any>();

  constructor(private assetService: AssetService, private router: Router) {}

  ngOnInit(): void {
    if (this.pageStatus === "NEW") {
    }
    if (history.state.ricercaAssetCriteri) {
      this.ricercaAssetCriteri = history.state.ricercaAssetCriteri;
    }
  }

  ngOnDestroy(): void {}

  nuovoAttributo(idAsset: any, nomeAsset: any): void {
    this.assetService.storeCurrentAsset({ id: idAsset, nome: nomeAsset });
    this.router.navigate(["/nuovoAttributo", idAsset], {
      state: {
        ricercaAssetCriteri: this.ricercaAssetCriteri,
      },
    });
  }

  modificaAttributo(attributo: any): void {
    this.router.navigate(["/modificaAttributo", attributo.id], {
      state: {
        ricercaAssetCriteri: this.ricercaAssetCriteri,
      },
    });
  }

  eliminaAttributo(attributo: any): void {
    // this.router.navigate(["/modificaAttributo", attributo.id]);
    this.onDeleteAttributo.emit(attributo);
  }

  tornaRicercaAsset(): void {
    this.router.navigate(["/gestioneAsset"], {
      state: {
        searchOnLoad: true,
        ricercaAssetCriteri: this.ricercaAssetCriteri,
      },
    });
  }

  save() {
    this.onSubmit.emit();
  }
}
