<app-container>
  <div class="row">
    <it-carousel
      type="three-cols-arrow-visible"
      lined="false"
      trackClass="ps-lg-3 pe-lg-3"
      *ngIf="isAdmin"
    >
      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Utenti AAM</h5>
          <p class="card-text">
            Inserimento e modifica utenti applicativi AAM (Amministratore
            regionale, struttura ed asset)
          </p>
          <a class="read-more nav-link" routerLink="/ricercaUtentiAam">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>

      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Gestione Asset</h5>
          <p class="card-text">Inserimento Asset e definizione attributi</p>
          <a class="read-more nav-link" routerLink="/gestioneAsset">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>

      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Strutture Organizzative</h5>
          <p class="card-text">Inserimento strutture organizzative</p>
          <a class="read-more nav-link" routerLink="/struttureOrg">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>

      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Utenti Asset</h5>
          <p class="card-text">Censimento utenti e valorizzazione attributi</p>
          <a class="read-more nav-link" routerLink="/ricercaUtentiAsset">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>
    </it-carousel>

    <it-carousel
      type="three-cols-arrow-visible"
      lined="false"
      trackClass="ps-lg-3 pe-lg-3"
      *ngIf="isAdminOrg"
    >
      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Utenti AAM</h5>
          <p class="card-text">
            Inserimento e modifica utenti applicativi AAM (Amministratore asset)
          </p>
          <a class="read-more nav-link" routerLink="/ricercaUtentiAam">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>

      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Utenti Asset</h5>
          <p class="card-text">Censimento utenti e valorizzazione attributi</p>
          <a class="read-more nav-link" routerLink="/ricercaUtentiAsset">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>
    </it-carousel>

    <it-carousel
      type="three-cols-arrow-visible"
      lined="false"
      trackClass="ps-lg-3 pe-lg-3"
      *ngIf="isUser"
    >
      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Utenti</h5>
          <p class="card-text">Censimento utenti e valorizzazione attributi</p>
          <a class="read-more nav-link" routerLink="/ricercaUtentiAsset">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>

      <it-carousel-item>
        <it-card space="true" cardClass="card-bg">
          <h5 class="card-title">Attributi</h5>
          <p class="card-text">Visualizzazione attributi valorizzati</p>
          <a class="read-more nav-link" routerLink="/ricercaAttributiAsset">
            <span class="text">Accedi</span>
            <it-icon name="arrow-right"></it-icon>
          </a>
        </it-card>
      </it-carousel-item>
    </it-carousel>
  </div>
</app-container>
