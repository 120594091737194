import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { AlertService } from "../utility/alert-service";
import { AppInjector } from "../helpers/app-injector";
import { UserService } from "./user-service";
import { Observable, pipe } from "rxjs";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class ImportExcelService extends BaseService {
  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(
      http,
      `${environment.apiPath}/${environment.apiVersion}/import-excel`
    );
  }

  downloadImport(idImport: number, fileName: string): Promise<any> {
    this.spinner.show();
    return super.get(`${idImport}`).then((result) => {
      this.spinner.hide();
      const contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      this.downloadFileBase64(result?.fileExcel, contentType, fileName);
      return result;
    });
  }

  importUtenti(formData: any): Observable<any> {
    return super.uploadFile(`utenti-valori-attributo-async`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  importUtentiAam(formData: any): Observable<any> {
    return super.uploadFile(`utenti-aam`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  importStruttureOrganizzative(formData: any): Observable<any> {
    return super.uploadFile(`strutture-organizzative`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  search(
    jsonInput: any,
    hideSpinnerOnCompletion: boolean = false,
    showSpinner: boolean = true
  ): Promise<any> {
    let jsonParams = {};
    jsonParams = {
      utenteCreazione: jsonInput.utenteCreazione,
      idAsset: jsonInput.idAsset,
      page: jsonInput.pageInfo.page,
      size: jsonInput.pageInfo.maxResults,
    };
    if (jsonInput.idStrutturaOrganizzativa) {
      jsonParams = {
        ...jsonParams,
        idStrutturaOrganizzativa: jsonInput.idStrutturaOrganizzativa,
      };
    }
    if (jsonInput.sortInfo) {
      jsonParams = {
        ...jsonParams,
        sort: jsonInput.sortInfo.sortProperty,
        sortDirection: jsonInput.sortInfo.sortDirection,
      };
    }
    if (showSpinner) this.spinner.show();

    return super
      .get("ricerca", { params: jsonParams })
      .then((result) => {
        if (!hideSpinnerOnCompletion) this.spinner.hide();
        return result;
      })
      .catch((error) => {
        this.spinner.hide();
        throw error;
      });
  }
}
