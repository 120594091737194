import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ItModalComponent } from "design-angular-kit";

@Component({
  selector: "app-modal-confirm",
  templateUrl: "./modal-confirm.component.html",
  styleUrls: ["./modal-confirm.component.css"],
})
export class ModalConfirmComponent implements OnInit {
  @ViewChild("confirmModal")
  confirmModal: ItModalComponent;

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  cancelButtonText: string = "Annulla";

  @Input()
  confirmButtonText: string = "Conferma";

  @Output()
  onConfirm = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  confirm() {
    this.onConfirm.emit();
  }

  show() {
    this.confirmModal.show();
  }
}
