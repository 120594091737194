<app-container>
  <app-dati-utente-asset
    [(form)]="form"
    [pageStatus]="'EDIT'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Modifica Utente'"
    (onSubmit)="onSave()"
    [listaAttributiLoaded]="listaAttributiSubject.asObservable()"
  >
  </app-dati-utente-asset>

  <app-modal-confirm
    #confirmModal
    title="Modifica Utente"
    description="Vuoi confermare l'aggiornamento dell'utente?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
