import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, of } from "rxjs";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
  HttpBackend,
  HttpResponse,
} from "@angular/common/http";
import { catchError, switchMap, tap, delay } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { UtilityService } from "./../../utility/utility.service";
import { Router } from "@angular/router";
import { UserService } from "../user-service";
import { AlertService } from "src/app/utility/alert-service";
import { ERRORI } from "src/app/tipologiche/errori";
import { TokenService } from "./token.service";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    public http: HttpClient,
    private router: Router,
    private utility: UtilityService,
    private userService: UserService,
    private alertService: AlertService,
    private tokenService: TokenService,
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
  }

  addToken(request: HttpRequest<any>, token: String): HttpRequest<any> {
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: "Bearer " + token,
        },
      });
    }
    return request;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.tokenService.isRefreshingToken &&
      !req.url.includes(`/openid-connect`)
    ) {
      return of(true).pipe(
        delay(500),
        switchMap((value) => this.nextHandler(req, next))
      );
    } else {
      if (!req.url.includes(`/openid-connect`)) {
        this.tokenService.restartTokenRefreshCurrentAttempt();
      }
      return this.nextHandler(req, next);
    }
  }

  nextHandler = (req: HttpRequest<any>, next: HttpHandler) =>
    next.handle(this.addToken(req, this.utility.getToken())).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && event.status == 200) {
          if (!this.utility.getToken()) {
            this.utility.addToken(event.headers.get("access_token"));
          }
        }
        return event;
      }),
      catchError((response: HttpErrorResponse) => {
        switch (response.status) {
          case 401:
            if (response.error.errorMessage !== "Bad credentials") {
              this.router.navigateByUrl("/sessionExpired");
            }
            this.userService.deleteUser();
            break;
          case 403:
            this.userService.deleteUser();
            this.router.navigateByUrl("/login");
            break;
          case 400:
          case 500:
            console.log(response, response.error);
            if (
              response.error?.diagnostica &&
              response.error?.diagnostica.length > 0
            ) {
              this.alertService.addErrorMessage(
                "Operazione non riuscita",
                this.getErrori(response.error.diagnostica)
              );
            } else if (response.url.indexOf(environment.contextRct) > -1) {
              // rct service no alert
            } else if (response.error["500"]) {
              this.alertService.addErrorMessage("", response.error["500"]);
            } else {
              this.alertService.addDefaultErrorMessage();
            }
            break;
          default:
            break;
        }
        return throwError({
          status: response?.status,
          statusText: response?.statusText,
          errorMessage: response?.error?.errorMessage,
        });
      })
    );

  getErrori(errori: any[]): string {
    return errori
      .filter((e) => ERRORI.find((errore) => errore.codice == e.codMsg))
      .map((e) =>
        ERRORI.find((errore) => errore.codice == e.codMsg)?.descrizione(
          e.parametri
        )
      )
      .join("; ");
  }
}
