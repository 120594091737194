<h3 class="mb-4">{{ formTitle }}</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionDatiAsset">
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Dati Struttura Organizzativa
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="form-group col-md-8 form-group-b-0">
              <it-input
                label="Descrizione *"
                formControlName="descrizione"
                #descrizione
              >
                <ng-container error>
                  <!-- Custom errors-->
                  <div *ngIf="descrizione.hasError('required')">
                    Il campo è obbligatorio
                  </div>
                  <div *ngIf="descrizione.hasError('maxLength')">
                    La lunghezza massima è di 50 caratteri
                  </div>
                </ng-container>
              </it-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col"></div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">
      <button
        type="button"
        itButton="outline-secondary"
        (click)="tornaRicercaOrg()"
      >
        <it-icon name="arrow-left" class="me-2"></it-icon>
        Ricerca Strutture Organizzative
      </button>
    </div>
  </div>
</form>
