<app-container>
  <app-import-file-excel
    [(form)]="form"
    [importType]="'UTENTI AAM'"
    [formTitle]="'Importazione Utenti AAM'"
    [textPulsanteRicerca]="'Ricerca Utenti AAM'"
    (onBackToRicerca)="tornaRicercaUtenti()"
  >
  </app-import-file-excel>
</app-container>
