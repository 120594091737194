import { Injectable } from "@angular/core";
import {
  NotificationPosition,
  ItNotificationService,
} from "design-angular-kit";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private notificationService: ItNotificationService) {}

  addDefaultSuccessMessage() {
    this.addSuccessMessage("Operazione eseguita correttamente");
  }

  addDefaultErrorMessage() {
    this.addErrorMessage("Operazione non riuscita");
  }

  addSuccessMessage(title: string, message?: string) {
    this.notificationService.success(
      title,
      message,
      false,
      1000,
      NotificationPosition.Top
    );
  }

  addErrorMessage(title: string, message?: string) {
    this.notificationService.error(
      title,
      message,
      true,
      null,
      NotificationPosition.Top
    );
  }
}
