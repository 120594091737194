import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class QueryService extends BaseService {
  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(http, "");
  }

  private rctServiceUrl: string = `${environment.contextRct}${environment.apiPathRct}/${environment.apiVersionRct}`;

  getQueries(): Promise<any> {
    return this.http.get(`${this.rctServiceUrl}/query/lista`).toPromise();
  }

  getQueryValues(query: string): Observable<any> {
    return this.http.get(`${this.rctServiceUrl}/query/valori/${query}`);
  }
}
