import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { AlertService } from "../utility/alert-service";
import { AppInjector } from "../helpers/app-injector";
import { UserService } from "./user-service";
import {
  BehaviorSubject,
  Observable,
  catchError,
  forkJoin,
  pipe,
  throwError,
} from "rxjs";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { TIPO_ATTRIBUTO_BOOL_ID } from "../tipologiche/tipo_attributo";
import { QueryService } from "./query-service";

@Injectable({
  providedIn: "root",
})
export class AssetService extends BaseService {
  alertService = AppInjector.get(AlertService);

  currentAsset$: BehaviorSubject<any>;

  constructor(
    http: HttpClient,
    private queryService: QueryService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/asset`);
    this.currentAsset$ = new BehaviorSubject<any>({});
  }

  setCurrentAsset(idAsset: any, navigateTo?: any) {
    if (!idAsset) {
      this.currentAsset$.next({});
    }
    if (
      idAsset &&
      (!this.currentAsset$.getValue()?.id ||
        this.currentAsset$.getValue().id != idAsset)
    ) {
      this.getAsset(idAsset).then((asset) => {
        const attributiWithQuery = asset?.attributi?.filter(
          (a) => a.query && a.query.length > 0
        );
        if (attributiWithQuery && attributiWithQuery.length > 0) {
          this.valoriQueryAttributi(attributiWithQuery)
            .pipe(
              catchError((response: HttpErrorResponse) => {
                if ([500].includes(response.status)) {
                  this.alertService.addErrorMessage(
                    "Errore nella connessione al DB RCT"
                  );
                }
                return throwError({
                  status: response?.status,
                  statusText: response?.statusText,
                  errorMessage: response?.error?.errorMessage,
                });
              })
            )
            .subscribe((results) => {
              if (results && results.length > 0) {
                for (var i = 0; i < results.length; i++) {
                  asset.attributi.forEach((attributo) => {
                    if (attributo.id === attributiWithQuery[i].id) {
                      attributo.valoriQuery =
                        results[i].valori?.sort().map((v) => v.trim()) ?? [];
                    }
                  });
                }
              }
              this.currentAsset$.next(asset);
              if (navigateTo) {
                this.router.navigate([navigateTo]);
              }
            });
        } else {
          this.currentAsset$.next(asset);
          if (navigateTo) {
            this.router.navigate([navigateTo]);
          }
        }
      });
    } else {
      if (navigateTo) {
        this.router.navigate([navigateTo]);
      }
    }
  }

  private valoriQueryAttributi(attributi: any[]): Observable<any> {
    if (attributi) {
      const queries$ = [];
      attributi.forEach((attributo) => {
        queries$.push({
          attributo,
          values$: this.queryService.getQueryValues(attributo.query),
        });
      });
      return forkJoin([...queries$.map((q) => q.values$)]);
    } else {
      return new Observable<undefined>();
    }
  }

  getAsset(idAsset: number): Promise<any> {
    this.spinner.show();
    return super.get(`${idAsset}`).then((result) => {
      this.spinner.hide();
      return result;
    });
  }

  getListaAsset(): Promise<any> {
    return super.get("elenco");
  }

  insert(body: any): Promise<any> {
    this.spinner.show();
    return super.post("", body).finally(() => {
      this.spinner.hide();
    });
  }

  update(idAsset: number, body: any): Promise<any> {
    this.spinner.show();
    return super.put(`${idAsset}`, body).finally(() => {
      this.spinner.hide();
    });
  }

  storeCurrentAsset(asset: any) {
    sessionStorage.setItem("currentAsset", JSON.stringify(asset));
  }

  getCurrentAssetStored() {
    const asset = sessionStorage.getItem("currentAsset");
    if (asset) {
      return JSON.parse(asset);
    }
    return {};
  }

  getValoriAttributo(idAttributo, attributiAsset): any[] {
    if (!idAttributo) return [];
    const attributo = attributiAsset.find((a) => a.id == idAttributo);
    if (attributo?.fkIdTipoAttributo?.id === TIPO_ATTRIBUTO_BOOL_ID) {
      return [{ value: "true" }, { value: "false" }];
    }
    if (attributo?.valoriQuery && attributo?.valoriQuery.length > 0) {
      return attributo?.valoriQuery.map((v) => ({
        value: v,
      }));
    }
    return attributo?.valorePredefinitoAttributoDtoList.map((v) => ({
      value: v.valore,
    }));
  }

  getTipoAttributo(idAttributo, attributiAsset): number {
    if (!idAttributo) return null;
    return attributiAsset.find((a) => a.id == idAttributo)?.fkIdTipoAttributo
      ?.id;
  }

  getRegexAttributo(idAttributo, attributiAsset): string {
    if (!idAttributo) return null;
    return attributiAsset.find((a) => a.id == idAttributo)?.txtRegex ?? "";
  }
}
