<div class="justify-content-center page-content">
  <div class="home-content">
    <div class="access-button">
      <h3>Attribute Authority Manager</h3>
      <div class="row mb-4">
        <div class="col px-0">
          <p>
            Censimento utenti e configurazione attributi per applicazioni del
            Sistema Informativo Sanitario
          </p>
        </div>
        <div class="row mt-4">
          <div class="col">
            <button (click)="loginArpa()" itButton="primary" class="me-2">
              Accedi
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <p class="text-green center-text"> 
              Accedendo al portale dichiaro di aver letto l'informativa sulla privacy sotto riportata 
            </p>
          </div>
        </div> 
        <div *ngIf="modalCookie" class="modal-cookie" id="modal">
          <span class="modal-cookie-message">
            <div>
              AAM utilizza cookie tecnici per il suo funzionamento, come descritto in dettaglio nella nostra <a href="assets/Cookie%20Policy%20-%20AAM.pdf" target="#blank">Cookie Policy</a>.
              Continuando accetti i termini di servizio e dichiari di avere letto le nostre policy.
            </div>
          </span>
          <button type="button" class="btn btn-primary"  data-dismiss="modal" (click)="continueEnd()"><b>Ho capito</b></button>
        </div> 
      </div>
    </div>
  </div>
</div>
