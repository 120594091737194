import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { FormCommons } from "src/app/utility/commons";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { Router } from "@angular/router";
import { AlertService } from "src/app/utility/alert-service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-inserimento-nuova-struttura-organizzativa",
  templateUrl: "./inserimento-nuova-struttura-organizzativa.component.html",
  styleUrls: ["./inserimento-nuova-struttura-organizzativa.component.css"],
})
export class InserimentoNuovaStrutturaOrganizzativaComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  ruoli: any[] = [];

  constructor(
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private fb: UntypedFormBuilder,
    fc: FormCommons,
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {
    this.formCommons = fc;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: [""],
      descrizione: ["", [Validators.required, Validators.maxLength(50)]],
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      descrizione: this.form.value.descrizione,
      fkIdTipoStrutturaOrganizzativa: { id: 1 },
    };
    this.strutturaOrganizzativaService
      .insert(body)
      .then((result) => {
        this.router.navigate(["/struttureOrg"], {
          state: {
            searchOnLoad: true,
          },
        });
      })
      .catch((error) => {
        this.spinner.hide();
        // this.alertService.addDefaultErrorMessage();
      });
  }
}
