import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-nuovo-utente-asset",
  templateUrl: "./nuovo-utente-asset.component.html",
  styleUrls: ["./nuovo-utente-asset.component.css"],
})
export class NuovoUtenteAssetComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.navigate(["/inserimentoUtenteAsset"]);
  }
}
