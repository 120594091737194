import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { AlertService } from "src/app/utility/alert-service";
import { FormCommons } from "src/app/utility/commons";
import { AttributoService } from "../../service/attributo-service";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { AssetService } from "src/app/service/asset-service";
import { UserService } from "src/app/service/user-service";

@Component({
  selector: "app-inserimento-nuovo-attributo",
  templateUrl: "./inserimento-nuovo-attributo.component.html",
  styleUrls: ["./inserimento-nuovo-attributo.component.css"],
})
export class InserimentoNuovoAttributoComponent implements OnInit {
  form: UntypedFormGroup;
  formCommons: FormCommons;
  firstAttempt: boolean;

  @ViewChild("confirmModal") confirmModal: ModalConfirmComponent;

  ruoli: any[] = [];

  constructor(
    private assetService: AssetService,
    private attributoService: AttributoService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    fc: FormCommons,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.formCommons = fc;
  }

  get listaValori() {
    return this.form.controls["listaValori"] as FormArray;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: null,
      chiave: ["", [Validators.required, Validators.maxLength(50)]],
      txtRegex: ["", Validators.maxLength(1000)],
      idTipoAttributo: [1],
      idAsset: null,
      nomeAsset: [""],
      flagObbligatorio: [false],
      flagValoreMultiplo: [true],
      flagIncludiStruttOrg: [false],
      valoreLibero: [true],
      valoreDaLista: [false],
      valoreDaQuery: [false],
      listaValori: new FormArray([]),
      query: null,
    });
    this.listaValori.push(
      this.fb.group({
        valore: [""],
      })
    );

    this.route.params.subscribe((params: Params) => {
      const asset = this.assetService.getCurrentAssetStored();
      this.form.patchValue({
        idAsset: params["id"],
        nomeAsset: asset.nome,
      });
    });
  }

  onSave() {
    this.firstAttempt = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.confirmModal.show();
  }

  save() {
    const body = {
      chiave: this.form.value.chiave,
      txtRegex: this.form.value.valoreLibero ? this.form.value.txtRegex : null,
      fkIdAsset: {
        id: this.form.value.idAsset,
      },
      fkIdTipoAttributo: {
        id: this.form.value.idTipoAttributo,
      },
      flagObbligatorio: this.form.value.flagObbligatorio,
      flagValoreMultiplo: this.form.value.flagValoreMultiplo,
      flagIncludiStruttOrg: this.form.value.flagIncludiStruttOrg,
      query: this.form.value.query,
      valorePredefinitoAttributoDtoList: !this.form.value.valoreDaLista
        ? []
        : this.form.value.listaValori?.map((v) => ({
            valore: v.valore,
          })),
    };
    this.attributoService.insert(body).then(() => {
      this.alertService.addDefaultSuccessMessage();
      this.assetService.setCurrentAsset(null);
      this.userService.changeAssetCorrente(null, null);
    });
  }
}
