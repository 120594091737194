import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { FormCommons } from "src/app/utility/commons";
import { AppInjector } from "../../helpers/app-injector";
import { SelectControlOption } from "design-angular-kit/public_api";
import {
  TIPO_ATTRIBUTO_DATA_ID,
  TIPO_ATTRIBUTO_NUMERO_ID,
} from "src/app/tipologiche/tipo_attributo";
import { AssetService } from "src/app/service/asset-service";
import { StrutturaOrganizzativaService } from "src/app/service/struttura-organizzativa-service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user-service";
import { DateUtils } from "src/app/utility/date-utils";
import { REGEX_NUMERO } from "src/app/tipologiche/validazioni";
import { RegExpValidator } from "../../directive/regexp.validator";
import * as _ from "lodash";

@Component({
  selector: "app-dati-utente-asset",
  templateUrl: "./dati-utente-asset.component.html",
  styleUrls: ["./dati-utente-asset.component.css"],
})
export class DatiUtenteAssetComponent implements OnInit, OnDestroy {
  formCommons = AppInjector.get(FormCommons);
  private _formValue: UntypedFormGroup;

  showAsset: boolean = false;
  showDatiUtente: boolean = true;

  attributiSelectOptions: SelectControlOption[] = [];
  attributoValoriOptions: SelectControlOption[] = [];
  attributoSelectSubs: any[] = [];

  @Input()
  get form(): UntypedFormGroup {
    return this._formValue;
  }

  set form(form: UntypedFormGroup) {
    this._formValue = form;
  }

  @Input()
  pageStatus: string;

  @Input()
  shouldValidate: boolean;

  @Input()
  formTitle: string = "";

  @Input()
  formDescription: string = "";

  @Input()
  listaAttributiLoaded: Observable<any>;

  @Output()
  formChange = new EventEmitter<UntypedFormGroup>();

  @Output()
  onSubmit = new EventEmitter<void>();

  codiceFiscaleSub: Subscription;
  cognomeSub: Subscription;
  nomeSub: Subscription;
  userSub: Subscription;
  assetSub: Subscription;
  idAsset: number;
  attributiAsset: any[];
  listaAttributiLoadedSub: Subscription;
  selectedUtenti: any[] = [];
  numeroUtentiTotali: any;
  ricercaUtentiCriteri: any;

  get numeroUtenti() {
    return this.selectedUtenti && this.selectedUtenti.length > 0
      ? this.selectedUtenti.length
      : this.numeroUtentiTotali;
  }

  get listaAttributi() {
    return this.form.controls["listaAttributi"] as FormArray;
  }

  constructor(
    private assetService: AssetService,
    private userService: UserService,
    private strutturaOrganizzativaService: StrutturaOrganizzativaService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.userSub = this.userService.user.subscribe((usr) => {
      if (!usr) return;
      this.manageUser(usr);

      this.assetSub = this.assetService.currentAsset$.subscribe((asset) => {
        this.attributiAsset = asset?.attributi;
        asset?.attributi?.forEach((element) => {
          this.attributiSelectOptions.push({
            value: element.id,
            text: element.chiave,
          });
        });
        if (this.pageStatus === "NEW") {
          this.listaAttributi.controls.forEach(
            (attributoGroup: FormGroup, index) => {
              this.popolaValoriAttributo(attributoGroup, index);
              // reset the field value to trigger the valuechanges and populate the values list
              attributoGroup.controls.attributo.setValue(
                attributoGroup.controls.attributo.value
              );
            }
          );
        } else if (this.pageStatus === "EDIT") {
          this.listaAttributiLoadedSub = this.listaAttributiLoaded.subscribe(
            (listaAttributi) => {
              listaAttributi.controls.forEach((attributoGroup, index) => {
                this.popolaValoriAttributo(attributoGroup, index);
                // reset the field value to trigger the valuechanges and populate the values list
                attributoGroup.controls.attributo.setValue(
                  attributoGroup.controls.attributo.value
                );
              });
            }
          );
        } else if (this.pageStatus === "MULTIPLE-INSERT") {
          this.aggiungiAttributo();
        }
      });
    });
    if (history.state.selectedUtenti || history.state.numeroUtentiTotali) {
      this.selectedUtenti = history.state.selectedUtenti;
      this.numeroUtentiTotali = history.state.numeroUtentiTotali;
    }
    if (history.state.ricercaUtentiCriteri) {
      this.ricercaUtentiCriteri = history.state.ricercaUtentiCriteri;
    }
    if (this.pageStatus === "MULTIPLE-INSERT") {
      this.showDatiUtente = false;
    } else {
      let codiceFiscaleControl = this.form.get("codiceFiscale");
      this.codiceFiscaleSub = codiceFiscaleControl.valueChanges.subscribe(
        () => {
          if (codiceFiscaleControl.value) {
            codiceFiscaleControl.patchValue(
              codiceFiscaleControl.value.toUpperCase(),
              {
                emitEvent: false,
              }
            );
          }
        }
      );
      let cognomeControl = this.form.get("cognome");
      this.cognomeSub = cognomeControl.valueChanges.subscribe(() => {
        if (cognomeControl.value) {
          cognomeControl.patchValue(cognomeControl.value.toUpperCase(), {
            emitEvent: false,
          });
        }
      });
      let nomeControl = this.form.get("nome");
      this.nomeSub = nomeControl.valueChanges.subscribe(() => {
        if (nomeControl.value) {
          nomeControl.patchValue(nomeControl.value.toUpperCase(), {
            emitEvent: false,
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.codiceFiscaleSub && this.codiceFiscaleSub.unsubscribe();
    this.cognomeSub && this.cognomeSub.unsubscribe();
    this.nomeSub && this.nomeSub.unsubscribe();
    this.userSub && this.userSub.unsubscribe();
    this.assetSub && this.assetSub.unsubscribe();
    this.listaAttributiLoadedSub && this.listaAttributiLoadedSub.unsubscribe();
    this.attributoSelectSubs?.forEach((a) => {
      a.sub && a.sub.unsubscribe();
    });
  }

  aggiungiAttributo() {
    const nuovoAttributoGroup = this.fb.group({
      attributo: ["", Validators.required],
      valore: ["", [Validators.required, RegExpValidator()]],
      dataInizio: null,
      dataFine: null,
      canBeDeleted: true,
      toBeDeleted: false,
      isDate: false,
      listaValori: [],
      regexp: null,
    });
    this.listaAttributi.push(nuovoAttributoGroup);
    this.popolaValoriAttributo(nuovoAttributoGroup);
  }

  popolaValoriAttributo(nuovoAttributoGroup: any, index?: number) {
    const attributoSelectControl = nuovoAttributoGroup.get("attributo");
    const attributoSelectSub = attributoSelectControl.valueChanges.subscribe(
      () => {
        const listaValori = _.sortBy(
          this.assetService.getValoriAttributo(
            attributoSelectControl.value,
            this.attributiAsset
          ),
          ["value"]
        );
        const tipoAttributo = this.assetService.getTipoAttributo(
          attributoSelectControl.value,
          this.attributiAsset
        );
        const regexp =
          tipoAttributo == TIPO_ATTRIBUTO_NUMERO_ID
            ? REGEX_NUMERO
            : this.assetService.getRegexAttributo(
                attributoSelectControl.value,
                this.attributiAsset
              );

        nuovoAttributoGroup.patchValue({
          listaValori,
          isDate: tipoAttributo == TIPO_ATTRIBUTO_DATA_ID,
          regexp: regexp,
        });
      }
    );
    this.attributoSelectSubs.push({
      index: index ?? this.listaAttributi.length - 1,
      sub: attributoSelectSub,
    });
  }

  rimuoviAttributo(attributoIndex: number) {
    if (this.listaAttributi.at(attributoIndex).get("id")) {
      this.listaAttributi.at(attributoIndex).get("toBeDeleted").setValue(true);
      this.listaAttributi
        .at(attributoIndex)
        .get("dataFine")
        .setValue(DateUtils.yesterdayFeDate());
    } else {
      this.attributoSelectSubs
        .find((a) => a.index == attributoIndex)
        .sub.unsubscribe();
      this.listaAttributi.removeAt(attributoIndex);
    }
  }

  annullaRimuoviAttributo(attributoIndex: number) {
    this.listaAttributi.at(attributoIndex).get("toBeDeleted").setValue(false);
    this.listaAttributi.at(attributoIndex).get("dataFine").setValue(null);
  }

  isAttributoDeleted(attributoGroup): boolean {
    const dataInizio = attributoGroup.value.dataInizio;
    const dataFine = attributoGroup.value.dataFine;
    if (!dataFine) return false;
    return new Date(dataFine) < new Date(new Date().toDateString());
  }

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
  }

  tornaRicercaUtenti(): void {
    this.router.navigate(["/ricercaUtentiAsset"], {
      state: {
        searchOnLoad: true,
        selectedUtenti: this.selectedUtenti,
        ricercaUtentiCriteri: this.ricercaUtentiCriteri,
      },
    });
  }

  save() {
    this.onSubmit.emit();
  }
}
