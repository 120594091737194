<app-container>
  <app-dati-attributo
    [(form)]="form"
    [pageStatus]="'NEW'"
    [shouldValidate]="firstAttempt"
    [formTitle]="'Nuovo Attributo'"
    (onSubmit)="onSave()"
  >
  </app-dati-attributo>

  <app-modal-confirm
    #confirmModal
    title="Inserimento Attributo"
    description="Vuoi confermare la creazione dell'attributo?"
    (onConfirm)="save()"
  >
  </app-modal-confirm>
</app-container>
