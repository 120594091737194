import { Component, OnInit, ViewChild } from "@angular/core";

import { RicercaUtentiAssetComponent } from "src/app/component/ricerca-utenti-asset/ricerca-utenti-asset.component";
import { RicercaUtentiAssetService } from "src/app/service/ricerca-utenti-asset-service";
import { RicercaAttributiAssetService } from "src/app/service/ricerca-attributi-asset-service";
import * as moment from "moment";
import { UtenteAssetService } from "src/app/service/utente-asset-service";
import { DateUtils } from "src/app/utility/date-utils";
import { ModalConfirmComponent } from "src/app/component/modal-confirm/modal-confirm.component";
import { UserService } from "src/app/service/user-service";
import { UserBaseComponent } from "../user-base-component";
import { Router } from "@angular/router";
import { AssetService } from "src/app/service/asset-service";

@Component({
  selector: "app-ricerca-utenti-asset-page",
  templateUrl: "./ricerca-utenti-asset-page.component.html",
  styleUrls: ["./ricerca-utenti-asset-page.component.css"],
})
export class RicercaUtentiAssetPageComponent
  extends UserBaseComponent
  implements OnInit
{
  ricercaUtentiList: any[] | undefined = undefined;
  selectedUtenti: any[] | undefined = [];
  utenteToBeDeleted: any;
  idAsset: any;
  idOrg: any;

  @ViewChild(RicercaUtentiAssetComponent) ruc!: RicercaUtentiAssetComponent;

  @ViewChild("confirmModalDeleteUtente")
  confirmModalDeleteUtente: ModalConfirmComponent;

  constructor(
    private ricercaUtentiAssetService: RicercaUtentiAssetService,
    private ricercaAttributiAssetService: RicercaAttributiAssetService,
    private utenteAssetService: UtenteAssetService,
    assetService: AssetService,
    userService: UserService,
    router: Router
  ) {
    super(assetService, userService, router);
    this.manageUser(userService.getUserStored());
  }

  ngOnInit(): void {}

  manageUser(user: any) {
    this.idAsset = user?.assetCorrente?.idAsset;
    this.idOrg = user?.assetCorrente?.idOrg;
  }

  ieri(): string {
    return moment().subtract(1, "days").format("DD/MM/YYYY");
  }

  searchUtenti(criteri: any) {
    this.ricercaUtentiAssetService.storeCriteriRicerca(criteri);
    this.ricercaUtentiAssetService
      .search(criteri)
      .then((result) => {
        this.selectedUtenti = criteri.selectedUtenti ?? [];
        this.ricercaUtentiList = result?.items.map((item) => ({
          ...item,
          attributi: item.valoreAttributoList
            // .split("||,")
            .map((a) => ({
              ...a,
              inizio: a.dataInizio
                ? DateUtils.fromBeDateToShortDate(a.dataInizio)
                : null,
              fine: a.dataFine
                ? DateUtils.fromBeDateToShortDate(a.dataFine)
                : null,
              dataValida: DateUtils.isBeDateValid(a.dataInizio, a.dataFine),
            }))
            .map(
              (a) =>
                `<font class="${
                  !a.dataValida ? "attribute-list-item-not-valid" : ""
                }">${a.chiave}: ${a.valore} ${
                  a.dataInizio && a.dataFine
                    ? `(dal ${a.inizio} al ${a.fine})`
                    : a.dataInizio && !a.dataFine
                    ? `(dal ${a.inizio})`
                    : !a.dataInizio && a.dataFine
                    ? `(fino al ${a.fine})`
                    : ""
                }</font>`
            )
            .join("\n"),
          selected: this.selectedUtenti?.includes(item.codiceFiscale) ?? false,
        }));
        this.ruc.setPaginatorValues({
          page: criteri.pageInfo.page,
          numberOfPages: result?.pageResult?.totalPages,
          numberOfItems: result?.pageResult?.totalItems,
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          this.ricercaUtentiList = [];
        }
      });
  }

  selectUtente($event: any) {
    if ($event.checked) {
      if (!this.selectedUtenti.includes($event.value)) {
        this.selectedUtenti = [...this.selectedUtenti, $event.value];
      }
    } else {
      this.selectedUtenti = this.selectedUtenti.filter(
        (s) => s != $event.value
      );
    }
    this.ricercaUtentiList = this.ricercaUtentiList.map((u) => ({
      ...u,
      selected: this.selectedUtenti.includes(u.codiceFiscale),
    }));
  }

  selectAll($event: any) {
    if ($event.checked) {
      this.ricercaUtentiList.forEach((u) => {
        if (!this.selectedUtenti.includes(u.codiceFiscale)) {
          this.selectedUtenti = [...this.selectedUtenti, u.codiceFiscale];
        }
      });
    } else {
      this.selectedUtenti = this.selectedUtenti.filter(
        (s) => !this.ricercaUtentiList.map((u) => u.codiceFiscale).includes(s)
      );
    }
    this.ricercaUtentiList = this.ricercaUtentiList.map((u) => ({
      ...u,
      selected: $event.checked,
    }));
  }

  exportUtenti(criteri: any) {
    this.ricercaAttributiAssetService.export(criteri);
  }

  onDeleteUtente(utente: any) {
    this.utenteToBeDeleted = utente;
    this.confirmModalDeleteUtente.show();
  }

  deleteUtente() {
    if (!this.utenteToBeDeleted?.idUtente) return;
    this.utenteAssetService
      .dettaglio(
        this.utenteToBeDeleted.idUtente,
        this.utenteToBeDeleted.idAsset,
        this.utenteToBeDeleted.idOrg,
        true
      )
      .then((u) => {
        const body = {
          idAsset: this.idAsset,
          idStrutturaOrganizzativa: this.idOrg,
          listaUtenti: [
            {
              codiceFiscale: u.codiceFiscale,
              cognome: u.cognome,
              nome: u.nome,
            },
          ],
          valoriAttributoModifica: u.valoriAttributoList
            .filter((v) => {
              return (
                !v.dataFine ||
                new Date(DateUtils.fromBeDate(v.dataFine)) >
                  DateUtils.yesterday()
              );
            })
            .map((v) => ({
              ...v,
              dataFine: DateUtils.yesterdayBeDate(),
            })),
        };
        this.utenteAssetService
          .associaUtentiValoriAttributo(body, true)
          .then(() => {
            const ricercaUtentiCriteri =
              this.ricercaUtentiAssetService.getCriteriRicercaStored();
            this.searchUtenti(ricercaUtentiCriteri);
          });
      });
  }
}
