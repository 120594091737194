<h3 class="mb-4">Seleziona Asset Corrente</h3>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="save()">
  <div class="accordion accordion-background-active" id="accordionDatiUtente">
    <div class="accordion-item">
      <h2 class="accordion-header" id="heading1a">
        <button
          class="accordion-button py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse1a"
          aria-expanded="true"
          aria-controls="collapse1a"
        >
          Asset
        </button>
      </h2>
      <div
        id="collapse1a"
        class="accordion-collapse collapse show"
        role="region"
        aria-labelledby="heading1a"
      >
        <div class="accordion-body">
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row">
                <div class="form-group col-md-6">
                  <it-select
                    label="Asset"
                    formControlName="asset"
                    [options]="assetSelectOptions"
                    #asset
                  >
                    <ng-container error>
                      <!-- Custom errors-->
                      <div *ngIf="asset.hasError('required')">
                        Il campo è obbligatorio
                      </div>
                    </ng-container>
                  </it-select>
                </div>
                <div class="form-group col-md-6">
                  <ng-container *ngIf="user.isAdmin">
                    <div class="row">
                      <div class="col-11">
                        <it-select
                          label="Struttura organizzativa"
                          formControlName="org"
                          [options]="orgSelectOptions"
                        >
                        </it-select>
                      </div>
                      <div class="form-group col-md-1">
                        <app-modal-search
                          [itemsList]="orgSelectOptions"
                          title="Struttura Organizzativa"
                          (onConfirm)="selezionaOrg($event)"
                        ></app-modal-search>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!user.isAdmin">
                    <it-select
                      label="Struttura organizzativa"
                      formControlName="org"
                      [options]="orgSelectOptions"
                    >
                    </it-select>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-evenly mt-4">
    <div class="col">&nbsp;</div>
    <div class="col-auto">
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
        Conferma
      </button>
    </div>
  </div>
</form>
