import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base-service";
import { Injectable } from "@angular/core";
import { AlertService } from "../utility/alert-service";
import { AppInjector } from "../helpers/app-injector";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class UtenteAssetService extends BaseService {
  alertService = AppInjector.get(AlertService);

  constructor(http: HttpClient, private spinner: NgxSpinnerService) {
    super(http, `${environment.apiPath}/${environment.apiVersion}/utente`);
  }

  associaUtentiValoriAttributo(
    body: any,
    hideSpinnerOnCompletion: boolean = false
  ): Promise<any> {
    this.spinner.show();
    return super.post("associaUtentiValoriAttributo", body).then(() => {
      if (!hideSpinnerOnCompletion) this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      throw error;
    });
  }

  aggiornaUtente(
    idUtente: any,
    body: any,
    hideSpinnerOnCompletion: boolean = false
  ): Promise<any> {
    this.spinner.show();
    return super.put(idUtente, body).finally(() => {
      if (!hideSpinnerOnCompletion) this.spinner.hide();
    });
  }

  dettaglio(
    idUtente: any,
    idAsset: any,
    idOrg: any,
    hideSpinnerOnCompletion: boolean = false
  ): Promise<any> {
    this.spinner.show();
    return super
      .post(`dettaglio`, {
        idUtente,
        idAsset,
        idStrutturaOrganizzativa: !idOrg ? null : idOrg,
      })
      .then((result) => {
        if (!hideSpinnerOnCompletion) this.spinner.hide();
        return result;
      });
  }
}
