import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function NomeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const regexp = `^[^<>%$£#!()\^\\[\\]{}?|+*;:,.=§]*$`;

    if (!value) {
      return null;
    }
    return !new RegExp(regexp).test(value) ? { nome: true } : null;
  };
}
